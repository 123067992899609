<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <a
        target="_self"
        href="/admin"
        >/admin</a
      >
      <a
        target="_self"
        href="/all"
        >/all</a
      >
      <a
        target="_self"
        href="/background"
        >/bg</a
      >
      <a
        target="_self"
        href="/foreground"
        >/fg</a
      >
      <a
        target="_self"
        href="/screenshare"
        >/ss</a
      >
      <a
        target="_self"
        href="/simulation"
        >/simulation</a
      >
      <a
        class="mb-4"
        target="_self"
        href="/"
        >/user</a
      >

      <a
        target="_blank"
        href="https://www.twitch.tv/themitchinghour/dashboard/live"
        >Twitch Stream Manager</a
      >

      <a
        href="/popout/chat"
        onclick="window.open(this.href,'targetWindow',
					                                   `toolbar=no,
					                                    location=no,
					                                    status=no,
					                                    menubar=no,
					                                    scrollbars=yes,
					                                    resizable=yes,
					                                    width=400,
					                                    height=800`);
					 return false;"
        >Popout Admin Chat</a
      >

      <a
        href="https://www.twitch.tv/popout/moderator/themitchinghour/chat"
        onclick="window.open(this.href,'targetWindow',
					                                   `toolbar=no,
					                                    location=no,
					                                    status=no,
					                                    menubar=no,
					                                    scrollbars=yes,
					                                    resizable=yes,
					                                    width=400,
					                                    height=800`); return false;"
        >Popout Twitch Chat</a
      >

      <button
        class="mt-4 mb-2"
        title="Get or Post tests"
        @click="fetchTest"
      >
        Test GET/POST to TMS
      </button>

      <button
        class="d-block"
        @click="logout"
      >
        Logout
      </button>

      <button
        title="Clear local storage - also may need to reboot browser if bugs."
        @click="clearLocalStorage"
      >
        Clear Local Storage
      </button>

      <button
        title="Test delayStart (static then logo) 8000ms"
        @click="handleTestDelayStart"
      >
        Test Delay Start
      </button>

      <button
        title="Copy Playlist to clipboard"
        @click="handleCopyPlaylist"
      >
        Copy Playlist
      </button>

      <br /><br />

      <a href="http://localhost:3000/test">TMS API (dev only)</a>
      <small>Paste this new AppToken into the tms env variable and reboot server.</small>
      <a href="http://localhost:3000/api/v1/twitch/get-app-token"
        >Get App Token (see TMS console logging)</a
      >
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        getPlaylist: "youtube/getPlaylist",
      }),
    },
    methods: {
      // https://www.sipios.com/blog-tech/webhook-example-how-to-build-a-chatbot-from-scratch
      // https://stackoverflow.com/questions/29775797/fetch-post-json-data
      // https://stackoverflow.com/questions/10005939/how-do-i-consume-the-json-post-data-in-an-express-application
      fetchTest(uri) {
        // const url = uri || 'http://10.0.1.29:3000/api/v1/twitch/follows?id=403955140';
        // const url = 'http://localhost:3000/api/v1/twitch/get-users?q=eethan';
        const url = "http://localhost:3000/api/v1/twitch/get-subscriptions";

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => console.log("POSTED TO, RES BACK: ✅✅ ", data));
      },

      logout() {
        // move this to vuex
        this.$store.dispatch("user/logout");
      },

      clearLocalStorage() {
        localStorage.removeItem("TheMitchingHour");
        // this.$store.state.followTitles = [];
        window.location.reload();
      },

      handleTestDelayStart() {
        // Throw static then logo.
        this.$store.dispatch("tasks/runTasks", [
          // { uuid: 'c406a414-ba01-44f6-804a-2f7f3a8ff565', delayStart: 4000 } //, delayStart: 500
          // { uuid: 'ae6e7c29-9fd6-41a8-8425-c0d483348d3e', delayStart: 2000 }
          { uuid: "c406a414-ba01-44f6-804a-2f7f3a8ff565", delayStart: 8000 },
        ]);
      },

      handleCopyPlaylist() {
        let copyText = "!rq ";

        this.getPlaylist.forEach((item) => {
          copyText += item.playerVars.id + ",";
        });

        function copyNow(text) {
          const input = document.createElement("textarea");
          input.innerHTML = text;
          document.body.appendChild(input);
          input.select();
          const result = document.execCommand("copy");
          document.body.removeChild(input);
          return result;
        }

        console.log(copyText);

        copyNow(copyText);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20);
  }

  .has-scrollbars a {
    display: block;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  textarea::placeholder {
    color: #0df293;
  }
</style>
