var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"youtube-playlist-item",class:{
    active: _vm.active,
    'has-errors': _vm.dataObject.error,
    'is-current': _vm.current,
    'is-flagged': _vm.flag,
    'is-previous': _vm.previous,
  }},[_c('div',{staticClass:"bg-fill"}),_c('div',{staticClass:"index"},[(!_vm.flagError && !_vm.previous && (!_vm.active || !_vm.playing))?_c('span',[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]):_vm._e(),(_vm.active && _vm.playing && _vm.current)?_c('i',{staticClass:"play-marker",class:_vm.paused ? 'fa fa-pause' : 'fa fa-play'}):_vm._e(),(!_vm.current && (_vm.previous || _vm.flagError))?_c('i',{staticClass:"play-marker",class:_vm.flagError ? 'fa fa-exclamation-triangle' : 'fa fa-check',attrs:{"title":_vm.flagMeta}}):_vm._e()]),_c('div',{staticClass:"thumbnail"},[_c('div',{staticClass:"duration-time",class:{ 'is-live': _vm.duration === 'LIVE' }},[_vm._v(" "+_vm._s(_vm.duration)+" ")]),_c('a',{staticClass:"btn-tile",on:{"click":function($event){$event.preventDefault();return _vm.playVideo.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("../assets/img/thumb-sizer--320x180.svg"),"alt":"Thumbnail"}}),(_vm.youTubePlayer && _vm.showYouTubePlayer)?_c('YouTubePlayer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.paused),expression:"!paused"}],staticClass:"youtube-player animated fadeIn delay-5s",attrs:{"options":{
          isMuted: true,
        }},on:{"player-progress":function($event){return _vm.onPlayerProgress($event)}}}):_vm._e(),_c('span',{staticClass:"btn-tile-bg animated fadeIn faster",style:({ 'background-image': 'url(' + _vm.dataObject.thumbnail + ')' })}),_c('div',{staticClass:"duration-bar"},[_c('div',{staticClass:"duration-bar-fill",style:({ transform: 'scaleX(' + _vm.progressXScale.toFixed(2) + ')' })})])],1)]),_c('div',{staticClass:"heading"},[_c('a',{attrs:{"href":'https://youtube.com/watch?v=' + _vm.dataObject.playerVars.id,"title":_vm.dataObject.title},on:{"click":function($event){$event.preventDefault();return _vm.playVideo.apply(null, arguments)}}},[_c('h4',{staticClass:"title mb-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"subtitle text-nowrap"},[(_vm.dataObject.userData.profileImageURL)?_c('img',{staticClass:"avatar",attrs:{"width":"13","src":_vm.dataObject.userData.profileImageURL,"alt":"Avatar"}}):_vm._e(),_c('span',{staticClass:"author text-primary"},[_vm._v(" "+_vm._s(_vm.dataObject.author)+" ")])])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.current),expression:"!current"}],staticClass:"btn btn-primary btn-remove",on:{"click":_vm.removeFromPlaylist}},[_vm._v(" × ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.active),expression:"!active"}],staticClass:"btn btn-primary btn-play-next",attrs:{"title":"Play Next"},on:{"click":_vm.playVideoNext}},[_c('i',{staticClass:"fa fa-sort-asc"})]),_c('button',{staticClass:"btn btn-primary btn-flag",attrs:{"disabled":_vm.flagError,"title":_vm.flagMeta},on:{"click":_vm.flagVideo}},[_c('i',{staticClass:"fa fa-flag"})])])}
var staticRenderFns = []

export { render, staticRenderFns }