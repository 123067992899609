<template>
  <div class="admin-toolbar-parent">
    <button
      v-for="item in items"
      :key="item.name"
      class="button"
      :class="{ 'is-active': item.name === selectedItem, [item.classes]: true }"
      :title="item.name"
      :disabled="item.isDisabled"
      @click="onSelectItem(item)"
    >
      <i :class="item.icon"></i>
      <span class="text">{{ item.name }}</span>
    </button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedItem: "Dash",

        items: [
          {
            name: "Stream",
            icon: "fa fa-bullseye",
            classes: "",
            isDisabled: false,
            isToggled: false,
          },
          // {
          //   name: "Chat",
          //   icon: "fa fa-comments-o",
          //   classes: "",
          //   isDisabled: false,
          //   isToggled: false,
          // },
          {
            name: "Game",
            icon: "fa fa-gamepad",
            classes: "",
            isDisabled: false,
            isToggled: false,
          },
          // {
          //   name: "Movie",
          //   icon: "fa fa-film",
          //   classes: "",
          //   isDisabled: false,
          //   isToggled: false,
          // },
          // {
          //   name: "Code",
          //   icon: "fa fa-github-alt",
          //   classes: "",
          //   isDisabled: false,
          //   isToggled: false,
          // },
          // {
          //   name: "Raid",
          //   icon: "fa fa-flash",
          //   classes: "",
          //   isDisabled: false,
          //   isToggled: false,
          // },
          // {
          //   name: "Outro",
          //   icon: "fa fa-hourglass-end",
          //   classes: "mb-5",
          //   isDisabled: false,
          //   isToggled: false,
          // },
          // {
          //   name: "Content",
          //   icon: "fa fa-edit",
          //   classes: "mt-auto",
          //   isDisabled: true,
          //   isToggled: false,
          // },
          // {
          //   name: "Assets",
          //   icon: "fa fa-image",
          //   classes: "",
          //   isDisabled: true,
          //   isToggled: false,
          // },
          // {
          //   name: "Data",
          //   icon: "fa fa-database",
          //   classes: "",
          //   isDisabled: true,
          //   isToggled: false,
          // },
          // {
          //   name: "Settings",
          //   icon: "fa fa-cog",
          //   classes: "",
          //   isDisabled: true,
          //   isToggled: false,
          // },
          // {
          //   name: "Help",
          //   icon: "fa fa-info-circle",
          //   classes: "mt-auto",
          //   isDisabled: true,
          //   isToggled: false,
          // },
        ],
      };
    },
    created() {
      this.onSelectItem(this.items[0]);
    },
    methods: {
      onSelectItem(target = {}) {
        const isNewSelection = target.name !== this.selectedItem;

        this.selectedItem = target.name;

        target.isToggled = isNewSelection || !target.isToggled;

        this.$emit("on-selected", {
          name: target.name,
          isToggled: target.isToggled,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-toolbar-parent {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // width: rem-calc(60);
    // min-width: rem-calc(60);
    width: 100%;
    height: 100%;
    background: #28282f;

    border-right: 1px solid rgba(black, 0.5);
    // box-shadow: -1px 0 1px yellow;
    // border-right: 2px solid #222;

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 0;
      font-size: rem-calc(24);
      background: none;
      height: rem-calc(65);
      color: #4a4a59;
      // color: #636366
      z-index: 1;

      &.is-active {
        color: rgba(white, 0.6);

        &:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 0px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          // border-radius: 3px;
          // border-left: 3px solid rgba($primary, 0.8);
          // background-color: rgba(black, 0.3);
          z-index: -1;
        }
      }

      &:hover:not(.is-active) {
        color: rgba(white, 0.2);
      }
    }

    .button .fa {
      display: block;
      text-shadow: inset 1px 1px 3px rgba(black, 0.2);
      // margin-bottom: rem-calc(4);
    }

    .button .text {
      position: relative;
      top: 4px;
      // display: none;
      font-size: rem-calc(10);
      font-weight: 600;
      // color: #777777;
    }
  }
</style>
