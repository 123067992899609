<script>
  /**
   * NOTES:
   *
   * 1. https://github.com/obs-websocket-community-projects/obs-websocket-js
   * 2. https://github.com/obsproject/obs-websocket/blob/master/docs/generated/protocol.md#getscenelist
   * 3. https://github.com/obsproject/obs-websocket/issues/1122
   */
  import { mapGetters } from "vuex";
  import obs, { getAllInputs, getActiveSceneAndAllSources, sendCommand } from "./obs";

  export default {
    props: {
      willReconnectOnRefresh: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getInputs: "obs/getInputs",
        getIsWebSocketConnected: "obs/getIsWebSocketConnected",
        getSources: "obs/getSources",
      }),
      serverIP: {
        get() {
          return this.$store.state.obs.serverIP;
        },
        set(value) {
          this.$store.commit("obs/SET_STATE", { serverIP: value });
        },
      },
      serverPassword: {
        get() {
          return this.$store.state.obs.serverPassword;
        },
        set(value) {
          this.$store.commit("obs/SET_STATE", { serverPassword: value });
        },
      },
      serverPort: {
        get() {
          return this.$store.state.obs.serverPort;
        },
        set(value) {
          this.$store.commit("obs/SET_STATE", { serverPort: value });
        },
      },
    },
    created() {
      if (this.getIsWebSocketConnected && this.willReconnectOnRefresh) {
        this.handleConnect();
      }
    },
    beforeDestroy() {
      this.destroy();
    },
    methods: {
      /**
       * HANDLE CONNECT
       */
      async handleConnect() {
        try {
          const { obsWebSocketVersion, negotiatedRpcVersion } = await obs.connect(
            `${this.serverIP}:${this.serverPort}`,
            this.serverPassword,
            {
              rpcVersion: 1,
            }
          );

          console.log(
            `%c ✅ OBS websocket server established (obsWebSocketVersion:${obsWebSocketVersion}) (using RPC ${negotiatedRpcVersion})`,
            "color: lime"
          );

          this.$store.commit("obs/SET_STATE", { isWebSocketConnected: true });

          // MUST BE FIRST
          this.syncObsStore();

          // ADD EVENT LISTENERS
          obs.on("SceneItemEnableStateChanged", this.onSourceChange);
          obs.on("InputMuteStateChanged", this.onInputMuteChange);
          obs.on("ExitStarted", this.destroy);
          obs.on("SourceFilterEnableStateChanged", this.onSourceFilterChange);

          //
        } catch (error) {
          this.$store.commit("obs/SET_STATE", { isWebSocketConnected: false });
          const text = `Warning: OBS failed to connect (${error.code}) ${error.message}`;
          alert(text);
          console.error(text, { error });
        }
      },

      /**
       * SYNC OBS STORE
       * Updates OBS data, scenes, sources etc
       */
      async syncObsStore() {
        const { activeSceneName, sources } = await getActiveSceneAndAllSources();
        this.$store.commit("obs/SET_STATE", { activeSceneName, sources });

        const { inputs } = await getAllInputs();
        this.$store.commit("obs/SET_STATE", { inputs });
      },

      /**
       * ON SOURCE CHANGE
       *
       * Avoids second trip for sceneItemName which isn't included in event.
       * We already have it though, from the initial `syncObsStore`.
       */
      async onSourceChange(event) {
        const { sceneItemId, sceneItemEnabled, sceneName } = event;
        const sourcesCopy = this.getSources.slice(); // mutation = reactive

        sourcesCopy.find(
          (item) => item.id === sceneItemId && item.parentSceneName === sceneName
        ).isActive = sceneItemEnabled;

        this.$store.commit("obs/SET_STATE", {
          sources: sourcesCopy,
        });
      },

      /**
       * ON SOURCE FILTER CHANGE
       *
       * Experimental: it works in overly elaborate way but slow due to the way
       * OBS plugin handles the filter transitions. (See AdminPanelOBSControls - isBlurActive)
       */
      async onSourceFilterChange(event) {
        const { filterEnabled, filterName, sourceName } = event;
        const sourcesCopy = this.getSources.slice(); // mutation = reactive

        const theSource = sourcesCopy.find((item) => item.name === sourceName);
        const theFilter = theSource.filters.find((item) => item.filterName === filterName);

        // Removes "Leave" and "Enter" so only the primary filter remains.
        // Remember: these words are a convention for use with OBS filter transitions.
        const primaryFilterName = filterName.replace(/\b(Leave|Enter)\b/g, "").trim();
        const primaryFilter = theSource.filters.find((f) => f.filterName === primaryFilterName);

        // Only way to get settings...
        const { filterSettings } = await sendCommand("GetSourceFilter", {
          sourceName,
          filterName: primaryFilterName, // "Composite Blur" not "Composite Blur Leave" etc,
        });

        theFilter.filterEnabled = filterEnabled;
        theFilter.filterSettings = filterSettings;

        primaryFilter.filterSettings = filterSettings;

        this.$store.commit("obs/SET_STATE", {
          sources: sourcesCopy,
        });
      },

      /**
       * ON INPUT MUTE CHANGE
       */
      async onInputMuteChange(event) {
        const { inputName = "", inputMuted = null } = event;
        const inputsCopy = this.getInputs.slice();

        inputsCopy.find((item) => item.name === inputName).isMuted = inputMuted;

        this.$store.commit("obs/SET_STATE", {
          inputs: inputsCopy,
        });
      },

      /**
       * DESTROY
       */
      async destroy() {
        await obs.disconnect();
        this.$store.commit("obs/SET_STATE", { isWebSocketConnected: false });
        console.log("%c OBS has disconnected.", "color: red");
      },
    },
  };
</script>

<template>
  <div
    v-if="!getIsWebSocketConnected"
    class="obs-connect-parent"
  >
    <div>
      <form class="mb-2">
        <input
          v-model="serverIP"
          type="text"
          :disabled="getIsWebSocketConnected"
        />

        <br />

        <input
          v-model="serverPort"
          type="text"
          :disabled="getIsWebSocketConnected"
        />

        <br />

        <input
          v-model="serverPassword"
          type="password"
          :disabled="getIsWebSocketConnected"
        />
      </form>

      <!-- CONNECT BUTTON -->
      <button
        class="btn btn-small btn-primary btn-connect mr-2"
        @click="handleConnect"
      >
        Connect
      </button>

      <!-- STATUS -->
      <small
        v-if="getIsWebSocketConnected"
        class="text-success"
      >
        Connected.
      </small>
      <small
        v-else
        class="text-danger"
      >
        Disconnected.
      </small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .obs-connect-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 1px solid red; // rgba(white, 0.2);
    // padding: rem-calc(15);
  }

  input {
    min-width: rem-calc(280);
    margin-bottom: 3px;
    padding: rem-calc(0 5);
    color: grey;
    border: 1px solid rgba(black, 0.8);
    background-color: #1c1c20;
  }
</style>
