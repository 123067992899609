<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <div class="d-flex p-2 pb-0">
        <small class="text-muted mr-3">{{ tasksReversed.length }} cooldowns</small>
        <!-- <span class="text-muted mr-2 mb-0 text-white"><i class="fa fa-filter"></i> Filter / canceled</span> -->
        <!-- <span class="text-muted mr-2 mb-0 text-white"><i class="fa fa-refresh"></i> Restart</span> -->
        <!-- <span class="text-muted mr-2 mb-0 text-white"><i class="fa fa-wrench"></i> Programs</span> -->
        <!-- <span class="text-muted mr-2 mb-0 text-white"><i class="fa fa-list"></i> List View</span> -->
        <!-- <span class="text-muted ml-auto mb-0 text-white"><i class="fa fa-search"></i> Search</span> -->

        <!--
          <input
          type="text"
          placeholder="Search"
        /> -->
      </div>

      <table class="tabler">
        <thead>
          <tr class="task is-thead">
            <th>Group</th>
            <th>Action</th>
            <th>Value</th>
            <th>Expires At</th>
            <th>User</th>
            <!-- <th style="width: 0; max-width: 0"></th> -->
          </tr>
        </thead>
        <transition-group
          tag="tbody"
          name="table-row"
          enter-active-class="animated flipInX faster"
          leave-active-class="flipOutX"
          appear
        >
          <tr
            v-for="cooldown in tasksReversed"
            class="task"
            :key="cooldown.id"
            @dblclick="handleClick(cooldown.id)"
          >
            <td>{{ cooldown.group }}</td>
            <td>{{ cooldown.data.value }}</td>
            <td class="text-muted">{{ cooldown.data.arg || "-" }}</td>
            <!-- :class="{ 'text-danger': formatExpiry(cooldown.expiresAt.toDate()) === 'Expired' }" -->
            <td>
              <div class="expiry">
                <!-- {{
                  formatExpiry(cooldown.expiresAt.toDate()) !== "Expired"
                    ? formatExpiry(cooldown.expiresAt.toDate())
                    : "expired"
                }}
                <span
                  class="expiry-bg-fill"
                  :style="{
                    'animation-duration':
                      getExpiryInMillis(cooldown.expiresAt.toDate()) > 0
                        ? getExpiryInMillis(cooldown.expiresAt.toDate()) + 'ms'
                        : '0ms',
                  }"
                ></span> -->
              </div>
            </td>
            <td>
              <img
                v-if="cooldown.profileImageURL"
                width="13"
                style="border-radius: 30px"
                :src="cooldown.profileImageURL"
                alt="Profile"
              />
              <span style="color: #dfd458">
                {{ cooldown.author }}
              </span>
            </td>
            <!-- <td class="base"></td> -->
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";

  dayjs.extend(relativeTime);

  export default {
    computed: {
      ...mapGetters({
        getAllTasks: "tasks/getAllTasks",
        tasksLibrary: "tasks/getTasksLibrary",
      }),
      activeModeName() {
        return this.modes[this.activeMode].name;
      },
      tasksReversed() {
        return this.getAllTasks.slice().reverse();
      },
    },
    methods: {
      handleClick(docId = "") {
        this.$store.dispatch("cooldowns/deleteById", docId);
      },
      formatExpiry(date) {
        // return dayjs().format("h:mm:ss A");
        const isExpired = date < new Date();
        return isExpired ? "Expired" : dayjs(date).from(Date.now());
      },

      getExpiryInMillis(date) {
        return dayjs(date).diff(Date.now());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
    border-bottom: 1px solid rgba(white, 0.1);
    background-color: #18181b;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // padding: rem-calc(0 10 10 10);
    // padding-top: rem-calc(10);
    overflow-x: hidden;
  }

  .has-scrollbars a {
    display: block;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  textarea::placeholder {
    color: #0df293;
  }

  table tbody tr:nth-child(even) .base {
    background: rgba(white, 0.03);
  }

  thead {
    position: sticky;
    top: 0;
    left: 0;
    background: #18181b;
    // background: purple;
    // background: darken(#212126, 2%);
    // border-bottom: 1px solid red; //rgba(white, 0.1);
    box-shadow: 0 1px 1px 0 rgba(white, 0.1);
    border-top: 1px solid rgba(white, 0.1);
    height: rem-calc(30);
    z-index: 10;

    th {
      border-right: 1px solid rgba(white, 0.1);
    }
  }

  .task {
    position: relative;
    // display: flex;
    // min-width: 100%;
    // margin-bottom: rem-calc(5);
    // border: 1px solid rgba(white, 0.1);
    // padding: rem-calc(3 10);
    z-index: 1;

    td,
    th {
      padding: rem-calc(4 15);
      // color: #eee;
      color: #d4d4d5;
      font-size: rem-calc(13);
      font-weight: 600;
    }

    th {
      // color: #6633cc;
      // color: #405b87;
      // text-transform: uppercase;
    }

    .base {
      position: absolute;
      left: 5px;
      top: 0;
      width: calc(100% - 10px);
      height: 100%;
      // background: rgba(black, 0.3);
      // background: rgba(white, 0.03);
      // background: #212126;
      border-radius: 5px;
      z-index: -1;
    }
  }

  .tabler {
    // border-collapse: separate;
    // border-spacing: 0 3px;
    table-layout: fixed;
    width: 100%;
  }

  td {
    // width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .expiry {
    position: relative;
    border: 1px solid rgba(white, 0.2);
    padding: rem-calc(0 5);
    border-radius: 3px;
  }

  .expiry-bg-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.1);
    transform-origin: left;
    z-index: -1;
    animation: canceling 4s ease-out 0ms forwards;
    // transition: 4s ease-out 0s;

    @keyframes canceling {
      0% {
        background-color: rgba(lime, 0.1);
        transform: scaleX(0);
      }
      30% {
        background-color: rgba(lime, 0.1);
      }
      50% {
        background-color: rgba(orange, 0.5);
      }
      100% {
        transform: scaleX(1);
        background-color: rgba(red, 0.3);
      }
    }
  }
</style>
