<template>
  <div
    v-show="debugActive || isAdminScreen"
    class="debug-menu-icons"
    :class="{ 'is-admin-screen': isAdminScreen }"
  >
    <!-- OBS CONTROL BTN (PHASED OUT) -->
    <!-- <button
      title="OBS Control"
      class="debug-btn debug-window-launch-btn"
      :class="{ active: obsControlActive }"
      @mousedown="onObsControl"
    >
      <i class="fa fa-gg-circle"></i>
    </button> -->

    <!-- DEBUG BTN -->
    <button
      v-if="!isAdminScreen"
      title="Debug"
      class="debug-btn debug-window-launch-btn"
      :class="{ active: debugActive }"
      @mousedown="onDebug"
    >
      <i class="fa fa-bug"></i>
    </button>

    <!-- ALERTS -->
    <button
      v-if="!isAdminScreen"
      title="Alerts"
      class="debug-btn alerts-btn"
      :class="{ active: alertsActive }"
      @mousedown="onAlerts"
    >
      <i class="fa fa-exclamation-triangle"></i>
    </button>

    <!-- MEDIA POSTER -->
    <button
      v-if="!isAdminScreen"
      title="Media Poster"
      class="debug-btn media-poster-btn"
      :class="{ active: mediaPosterActive }"
      @mousedown="onMediaPoster"
    >
      <i class="fa fa-star"></i>
    </button>

    <!-- ANIMATIONS OFF -->
    <button
      title="Performance Mode"
      class="debug-btn performance-mode-btn"
      :class="{ active: performanceModeActive }"
      @mousedown="onPerformanceMode"
    >
      <i class="fa fa-low-vision"></i>
    </button>

    <!-- YOUTUBE -->
    <button
      v-if="!isAdminScreen"
      title="YouTube Player"
      class="debug-btn youtube-player-btn"
      :class="{ active: youTubePlayerActive }"
      @mousedown="onYouTubePlayer"
    >
      <i class="fa fa-youtube-play"></i>
    </button>

    <!-- SCREENSHARE -->
    <button
      v-if="!isAdminScreen"
      title="ScreenShare Player"
      class="debug-btn youtube-player-btn"
      :class="{ active: screenSharePlayerActive }"
      @mousedown="onScreenSharePlayer"
    >
      <i class="fa fa-desktop"></i>
    </button>

    <!-- CHAT -->
    <button
      v-if="!isAdminScreen"
      title="Chat"
      class="debug-btn chat-btn"
      :class="{ active: chatActive }"
      @mousedown="onChat"
    >
      <i class="fa fa-comment"></i>
    </button>

    <!-- TWITCH PLAYER -->
    <button
      v-if="!isAdminScreen"
      title="Twitch Player"
      class="debug-btn twitch-player-btn"
      :class="{ active: twitchPlayerActive }"
      @mousedown="onTwitchPlayer"
    >
      <i class="fa fa-twitch"></i>
    </button>

    <!-- WINDOW RESIZE BUTTON -->
    <button
      v-if="!isAdminScreen"
      title="Relaunch in 1080p"
      class="debug-btn debug-window-resize-btn"
      @mousedown="onWindowResize"
    >
      <i class="fa fa-window-maximize"></i>
    </button>

    <!-- RESET (DIRTY) -->
    <button
      title="RESET ALL (Danger)"
      class="debug-btn debug-window-reset-btn"
      :class="tasksDeleting ? 'active' : ''"
      @mousedown="onReset"
    >
      <i
        class="fa fa-refresh"
        :class="tasksDeleting ? 'fa-spin' : ''"
      ></i>
    </button>

    <!-- BROADCASTING -->
    <button
      v-if="!isAdminScreen"
      title="Broadcasting"
      class="debug-btn broadcasting-btn ml-4"
      :class="{ active: broadcastingActive }"
      @mousedown="onBroadcasting"
    >
      <i
        class="fa fa-circle-o"
        :class="broadcastingActive ? 'fa-circle' : ''"
      >
      </i>
    </button>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        isAdminScreen: window.location.pathname.includes("/admin"),
        tasksDeleting: false,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
      }),
      alertsActive() {
        return !!this.getActiveTask("alerts", "Run");
      },
      broadcastingActive() {
        return this.getActiveTask("system", "Broadcasting");
      },
      chatActive() {
        return !!this.getActiveTask("chat", "Run");
      },
      debugActive() {
        return !!this.getActiveTask("debug", "Run");
      },
      mediaPosterActive() {
        return !!this.getActiveTask("mediaPoster", "Run");
      },
      obsControlActive() {
        return !!this.getActiveTask("obsControl", "Run");
      },
      performanceModeActive() {
        return !!this.getActiveTask("system", "Performance Mode");
      },
      streamInit() {
        // Watch the task, not the T/F value and evaluate
        return this.getActiveTask("scene", "Stream Init");
      },
      twitchPlayerActive() {
        return !!this.getActiveTask("twitchPlayer", "Run");
      },
      youTubePlayerActive() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      screenSharePlayerActive() {
        return !!this.getActiveTask("screenSharePlayer", "Run");
      },
    },
    watch: {
      streamInit(newVal, oldVal) {
        if (!!this.streamInit && newVal !== oldVal) {
          this.tasksDeleting = true;
          setTimeout(() => (this.tasksDeleting = false), 1000);
        }
      },
      broadcastingActive: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // Send date of broadcast or remove explicitly with blank.
            const val = !!newVal ? new Date(newVal.data.arg) : "";
            this.$store.dispatch("global/setBroadcasting", val);
          }
        },
      },
    },
    methods: {
      onAlerts() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "76e6f1e7-2d7b-4cc9-adfc-b73690c5e985" },
        ]);
      },
      onBroadcasting() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: "4775d291-5db1-4bae-acc9-4cba81822f0f",
            canceled: !!this.broadcastingActive,
            data: {
              arg: String(new Date()),
            },
          },
        ]);
      },
      onChat() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "07e3f0db-e430-4bd9-a988-926f47e45a33" },
        ]);
      },
      onDebug() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "fff1cc2c-7f98-46e6-b88e-a53697b7d518" },
        ]);
      },
      onMediaPoster() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "559c502f-6dc2-47b4-b086-e27d7d44c68d" },
        ]);
      },
      onObsControl() {
        this.$store.dispatch("tasks/runTasksRemotely", [{ uuid: "obsControl:Run" }]);
      },
      onPerformanceMode() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "8c368523-1822-4f9a-9f89-49d7cf32255a" },
        ]);
      },
      onReset() {
        this.tasksDeleting = true;
        this.$store
          .dispatch("tasks/runTasksRemotely", [{ uuid: "e451fc8a-e86e-4ed6-b520-53cfcff52b7c" }])
          .then(() => {
            setTimeout(() => {
              this.tasksDeleting = false;
            }, 1000);
          });
      },
      onScreenSharePlayer() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "86b54c6e-f757-4296-8743-40a06207bb71001" },
        ]);
      },
      onTwitchPlayer() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "86b54c6e-f757-4296-8743-40a06207bb71" },
        ]);
      },
      onYouTubePlayer() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "a050ee28-c5a9-4e8c-a5c0-f1245449f7a3" },
        ]);
      },
      onWindowResize() {
        window.open("/all/", "mywindow", "menubar=0,resizable=0,width=1920,height=1080");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .is-dev .debug-menu-icons {
    // display: inline-flex !important;
    // font-size: rem-calc(19);
  }

  .debug-menu-icons {
    display: inline-flex;
    // position: relative;
    // z-index: 10;
    // margin-left: rem-calc(10);

    // border: 1px solid red;

    background: rgba(black, 0.2);
    // padding: rem-calc(5 13);
    border-radius: 0 0 4px 0;
  }

  .is-admin-screen.debug-menu-icons {
    background: none;
    margin-right: 110px;
  }

  //--------------------------------------------------------
  // LAUNCH TRIGGERS
  // placed onto the OS menubar superficially
  //--------------------------------------------------------

  .debug-btn {
    width: rem-calc(44);
    height: rem-calc(36);
    border: 0;
    box-shadow: none !important;
    background: none;
    // background: aqua;
    color: rgba(white, 0.8);
    // opacity: 0.5;
    cursor: default;
    outline: none;
    // transition: background 100ms ease;
    z-index: 99999;

    .fa-twitch,
    .fa-desktop {
      position: relative;
      top: 2px;
    }

    &:hover {
      background: rgba(black, 0.3);
    }

    &.active {
      // color: white;
      color: $primary; //#b0ff38; // $primary
      text-shadow: 0px 0px 5px rgba(#b0ff38, 0.9);
      opacity: 1;
    }
  }

  .broadcasting-btn.active {
    color: #ff0000;
    text-shadow: 0px 0px 5px rgba(#ff0000, 0.9);
  }
</style>
