<template>
  <button
    class="button"
    :class="{ 'is-broadcasting': !!broadcasting }"
    :disabled="isLoading"
    :title="broadcasting ? 'Stop broadcast' : 'Start broadcast'"
    @click="onBroadcast"
  >
    <span class="timer">{{ dateTime }}</span>
    <span class="d-block">Broadcast</span>
  </button>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import countdown from "countdown/countdown";
  import herokuConfig from "@/config/heroku";

  export default {
    data() {
      return {
        animationFrame: null,
        dateTime: Date.now(),
        isLoading: null,
      };
    },
    created() {
      this.updateDateTime();
    },
    beforeDestroy() {
      cancelAnimationFrame(this.animationFrame);
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
      }),
      broadcasting() {
        return this.getActiveTask("system", "Broadcasting");
      },
    },
    methods: {
      async onBroadcast() {
        this.isLoading = true;

        try {
          await axios({
            url: herokuConfig.HEROKU_API.setBroadcast,
            method: "POST",
            withCredentials: true,
            headers: {
              "content-type": "application/json",
            },
            data: {
              isBroadcasting: !this.broadcasting,
            },
          });
        } catch (err) {
          console.error("Oops! Failed to setBroadcast endpoint:", err);
        }

        this.isLoading = false;
      },
      updateDateTime() {
        const createdAt = this.broadcasting?.createdAt?.toDate();
        this.dateTime = this.getTimeElapsed(createdAt);
        this.animationFrame = requestAnimationFrame(this.updateDateTime);
      },
      getTimeElapsed(dateTime) {
        const start = dateTime;
        const end = Date.now();
        const cd = countdown(start, end, countdown.DEFAULTS);

        const d = `0${cd.days}`.slice(-2);
        const h = `0${cd.hours}`.slice(-2);
        const m = `0${cd.minutes}`.slice(-2);
        const s = `0${cd.seconds}`.slice(-2);

        let message = "";

        if (d !== "00") message += `${d}:`;
        if (h !== 0) message += `${h}:`;
        if (m !== 0) message += `${m}:`;
        if (s !== 0) message += s;
        return message;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0e0e10;
    background: transparent;
    border: 0;
    padding: rem-calc(8 15);
    margin: rem-calc(0 5);
    border-radius: 5px;
    line-height: 1;
    font-weight: 600;

    margin-bottom: 0;

    &:hover {
      background: black;
    }

    .timer {
      color: #555;
    }

    .d-block {
      color: #777;
      font-size: rem-calc(12);
    }
  }

  .button.is-broadcasting .timer {
    text-shadow: 0px 0px 3px rgba(#06f393, 0.3);
    color: #06f393; //#ace100;
  }
</style>
