<template>
  <div
    class="chat-messages-item-body-parent chat-message-body"
    :class="classes"
  >
    <!-- TEXT EL -->
    <div
      v-show="!isTimedOut || hovered"
      ref="text-el"
    ></div>

    <!-- MESSAGE TIMED OUT EL -->
    <div v-show="isTimedOut && !hovered">
      {{ timedOutMessageText }}
    </div>
  </div>
</template>

<script>
  import parseExtras from "./parseExtras";

  /**
   * https://github.com/twitter/twemoji
   * <script src="https://twemoji.maxcdn.com/v/latest/twemoji.min.js" crossorigin="anonymous">
   */
  const twemojiScript = document.createElement("script");
  // twemojiScript.setAttribute("src", "//twemoji.maxcdn.com/v/latest/twemoji.min.js");
  // Temporary fix - Fuck Twitter Elon
  // https://github.com/twitter/twemoji/issues/636
  // https://github.com/twitter/twemoji/issues/580
  twemojiScript.setAttribute("src", "//unpkg.com/twemoji@latest/dist/twemoji.min.js");
  twemojiScript.setAttribute("crossorigin", "anonymous");
  document.head.appendChild(twemojiScript);

  export default {
    props: {
      dataObject: {
        type: Object,
        required: true,
      },
      hovered: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return this.dataObject.action && "animated pulse";
      },
      emotes() {
        return this.dataObject.userstate.emotes;
      },
      isTimedOut() {
        return this.dataObject.isTimedOut;
      },
      timedOutMessageText() {
        return this.dataObject.message.replace(/[^\s]+/g, "kaw");
      },
    },
    mounted() {
      const messageEle = document.createElement("span");
      const finalMessage = this.parseEmotes(
        this.dataObject.channel,
        this.emotes || {},
        this.dataObject.message
      );
      this.addEmoteDOM(messageEle, finalMessage);
      this.$refs["text-el"].appendChild(messageEle);
    },
    methods: {
      /**
       * PARSE EMOTES
       * https://codepen.io/liquidvisual/pen/JjRvwqY?editors=1012
       */
      parseEmotes(channel, emotes, message) {
        const twitchEmoteKeys = Object.keys(emotes);

        let allEmotes = twitchEmoteKeys.reduce((p, id) => {
          const emoteData = emotes[id].map((n) => {
            const [a, b] = n.split("-");
            const start = +a;
            const end = +b + 1;
            return {
              start,
              end,
              id,
              code: message.slice(start, end),
              type: ["twitch", "emote"],
            };
          });
          return p.concat(emoteData);
        }, []);

        const seen = [];

        allEmotes = allEmotes
          .sort((a, b) => a.start - b.start)
          .filter(({ start, end }) => {
            if (seen.length && !seen.every((n) => start > n.end)) {
              return false;
            }
            seen.push({ start, end });
            return true;
          });

        if (allEmotes.length) {
          let finalMessage = [message.slice(0, allEmotes[0].start)];

          allEmotes.forEach((n, i) => {
            const p = { ...n, ...i }; //Object.assign({}, n, { i });
            const { end } = p;
            finalMessage.push(p);
            if (i === allEmotes.length - 1) {
              finalMessage.push(message.slice(end));
            } else {
              finalMessage.push(message.slice(end, allEmotes[i + 1].start));
            }
            finalMessage = finalMessage.filter((z) => z);
          });
          return finalMessage;
        }

        return [message];
      },

      /**
       * ASSIGN EMOTES
       *
       * https://discuss.dev.twitch.tv/t/how-to-get-emotes-badges-object/18916
       * Example of animated Ghost emote from StreamElements chat module (22.10.21):
       * <img src="https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_7927b5c4f1db49a7a353ebae1d9752ce/default/dark/1.0" srcset="https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_7927b5c4f1db49a7a353ebae1d9752ce/default/dark/1.0 1x, https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_7927b5c4f1db49a7a353ebae1d9752ce/default/dark/2.0 2x, https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_7927b5c4f1db49a7a353ebae1d9752ce/default/dark/3.0 4x" title="gitmDab" class="emote">
       */
      addEmoteDOM(ele, data) {
        /**
         * (example) data: [
         *   0: "❤️  "
         *   1: {start: 4, end: 16, id: '301493777', code: 'themit10Coin', type: Array(2)}
         *   2: "  abc"
         * ]
         */
        data.forEach((n) => {
          let out = null;
          if (typeof n === "string") {
            // out = document.createTextNode(n);
            out = parseExtras(n, this.dataObject.prunedUserData);
          } else {
            const {
              type: [type, subtype],
              code,
            } = n;

            if (type === "twitch") {
              if (subtype === "emote") {
                out = document.createElement("img");
                out.classList.add("twitch-emote");
                out.setAttribute(
                  "src",
                  `https://static-cdn.jtvnw.net/emoticons/v2/${n.id}/default/dark/2.0`
                  // Previous static URL: `https://static-cdn.jtvnw.net/emoticons/v1/${n.id}/2.0`);
                );
                out.setAttribute("alt", code);
              }
            }
          }
          if (out) {
            ele.appendChild(out);
          }
        });

        /**
         * Before:
         *   <span class="message">❤️ 🕷</span>
         * After:
         *   <span class="message">
         *     <img draggable="false" class="emoji" alt="❤️" src="https://twemoji.maxcdn.com/v/13.1.0/72x72/2764.png">
         *     <img draggable="false" class="emoji" alt="🕷" src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f577.png">
         *   </span>
         */
        window.twemoji.parse(ele); // width is CSS controlled.
      },

      //--------------------------------------------------------
      // parseURLs
      // https://stackoverflow.com/questions/5982824/what-does-1-2-etc-mean-in-regular-expressions
      // return this.text.replace(/@\w+/gi, '<span class="smallcaps fill-2">$1</span>');
      // https://stackoverflow.com/questions/35163365/regex-for-mentions
      // H̸̡̪̯ͨ͊̽̅̾̎Ȩ̬̩̾͛ͪ̈́̀́͘ ̶̧̨̱̹̭̯ͧ̾ͬC̷̙̲̝͖ͭ̏ͥͮ͟Oͮ͏̮̪̝͍M̲̖͊̒ͪͩͬ̚̚͜Ȇ̴̟̟͙̞ͩ͌͝S̨̥̫͎̭ͯ̿̔̀ͅ
      //--------------------------------------------------------

      // // parseURL(str) {

      // /*
      // // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
      // const expression = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})";

      // const regex = new RegExp(expression);

      // return target.replace(
      // /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
      // (match, space, url) => {
      // var hyperlink = url;
      // if (!hyperlink.match('^https?:\/\/')) {
      // hyperlink = 'http://' + hyperlink;
      // }
      // return `${space} <a target="_blank" title="${hyperlink}" href="${hyperlink}">Link</a>`;
      // }
      // );
      // */
      // // }
    },
  };
</script>

<style lang="scss" scoped>
  .chat-message-body {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(19);
    font-weight: 600;
    line-height: 1.2;
    color: darken(white, 20%);
    word-break: break-word;
    // word-wrap: break-word;
    // overflow-wrap: break-word;
  }

  .chat-message-body ::v-deep img.twitch-emote {
    width: rem-calc(30);
  }

  .chat-message-body ::v-deep img.emoji {
    width: rem-calc(20);
  }

  //--------------------------------------------------------
  // ACTIONS
  //--------------------------------------------------------

  .is-action .chat-message-body,
  .has-mentions .chat-message-body {
    // color: red; //darken(white, 50%);
    font-style: italic;
    // color: lighten(#f02263, 35%);
  }

  // shake it
  .is-action .chat-message-body {
    animation-iteration-count: 10;
  }

  .is-action.is-role-sub .chat-message-body {
    color: #e67e23;
  }
  .is-action.is-role-mod .chat-message-body,
  .is-action.is-eethanX .chat-message-body {
    color: #9b59b6;
  }
  .is-action.is-role-vip .chat-message-body {
    color: #ea7bc0;
  }
  .is-action.is-role-broadcaster .chat-message-body {
    color: #e0d55a;
  }
  .is-action.is-foreign .chat-message-body {
    color: #28bee3;
  }

  .has-mentions:not(.has-user-mention) .chat-message-body {
    // filter: grayscale(100%);
    color: grey;
  }

  .has-mentions:not(.has-user-mention) .chat-message-body ::v-deep img {
    filter: grayscale(100%);
  }

  //--------------------------------------------------------
  // MODIFIERS
  //--------------------------------------------------------

  // .has-user-mention .chat-message-body {
  // 	color: rgba($primary, 0.8);
  // }

  .is-foreign .chat-message-body {
    color: rgba(lighten(#28bee3, 35%), 0.7);
  }

  .is-timed-out .chat-message-body {
    font-style: italic;
    color: $danger;
  }

  .is-foreign .chat-message-body ::v-deep img {
    filter: grayscale(100%);
  }

  // .is-ban-hovered .chat-message-body {
  // 	color: $danger;
  // }

  //--------------------------------------------------------
  // MESSAGE BODY FORMATED
  //--------------------------------------------------------

  .chat-message-body ::v-deep a,
  .chat-message-body ::v-deep .mention,
  .chat-message-body ::v-deep .command {
    display: inline-block;
    padding: rem-calc(2 4);
    border-radius: 3px;
    // background-color: rgba(#f02263, 0.5);
    // background-color: rgba(#f02263, 0.7);
    // background-color: rgba(white, 0.2);
    font-style: normal;
    font-family: "Avenir Next Condensed";
    font-weight: 400;
    color: #7288da;
    // text-shadow: 0px 3px 2px rgba(black, 0.5);
    margin-bottom: 5px;
    background-color: rgba(114, 137, 218, 0.1);

    &:hover {
      background-color: #7288da !important;
      color: white;
    }
  }

  .is-role-broadcaster .chat-message-body ::v-deep a,
  .is-role-broadcaster .chat-message-body ::v-deep .mention {
    background-color: transparent;
  }

  .chat-message-body ::v-deep a {
    color: #34d2c5;
  }

  .has-user-mention .chat-message-body ::v-deep .mention {
    // background-color: rgba($primary, 0.7);
    // background: $primary;
    // color: rgba(black, 0.8);
    // color: rgba(white, 0.8);
    // text-shadow: none;
  }

  .chat-message-body ::v-deep .command {
    // color: #57f271;
    // background-color: rgba(#57f271, 0.1);
    color: #f34748;
    background-color: rgba(#f34748, 0.1);
    animation: slow-fade 5s linear 2s alternate 1 forwards;

    &:hover {
      // background-color: rgba(#57f271, 0.6) !important;
      background-color: rgba(#f34748, 0.7) !important;
    }
  }

  @keyframes slow-fade {
    100% {
      filter: grayscale(100%);
    }
  }
</style>
