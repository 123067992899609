<template>
  <div class="admin-panel-chat-status-parent" :class="isChatbotConnected && 'is-chatbot-connected'">
    <!-- STATUS BUTTON -->
    <button
      class="btn-pill btn-status"
      :disabled="isChatbotConnected === null || isChatbotConnecting"
      :style="isChatbotConnected === null || (isChatbotConnecting && { cursor: 'progress' })"
      :title="pingInformation"
      @click="toggleChatbot()"
    >
      <span class="dot-indicator" :class="indicatorClasses"> </span>
      <span class="text">{{ statusText }}</span>
    </button>

    <!-- CHAT CHANNELS -->
    <button
      v-for="channel in connectedChannels"
      :key="channel"
      class="btn-pill btn-channel animated zoomIn faster"
    >
      <i class="fa fa-close"></i>
      #{{ channel }}
    </button>

    <!-- <button
      v-for="(item, i) in 20"
      :key="i"
      class="btn-pill btn-channel animated zoomIn faster"
    >
      <i class="fa fa-close"></i>
      #testetsts
    </button> -->
  </div>
</template>

<script>
  // https://jsfiddle.net/m7e2xcf0/1/
  import axios from "axios";
  import { mapGetters } from "vuex";
  import moment from "moment";
  import heroku from "@/config/heroku";
  import tmiConfig from "@/config/tmi";

  const isDev = process.env.NODE_ENV !== "production";

  export default {
    data() {
      return {
        connectedChannels: [],
        chatbotConnectedAt: null,
        chatbotLastPingedAt: null,
        chatbotPingTimeout: null,
        isChatbotConnected: null, // Remember: null is starting value.
        isChatbotConnecting: null,
        pollingRetries: 0,
        serverPingInterval: null,
      };
    },
    computed: {
      ...mapGetters({
        userData: "user/getUserData",
      }),
      pingInformation() {
        const dateTime = moment(this.chatbotLastPingedAt).format("ddd hh:mm:ssa");
        const uptime = this.chatbotConnectedAt;
        // prettier-ignore
        return `Uptime: ${uptime} | Last ping: ${dateTime}`;
      },
      indicatorClasses() {
        return {
          "is-online": this.isChatbotConnected && !this.isChatbotConnecting,
          "is-pending": this.isChatbotConnected === null || this.isChatbotConnecting,
        };
      },
      statusText() {
        if (this.isChatbotConnected === null) {
          return "One moment...";
        }

        if (this.isChatbotConnecting === true) {
          return "Connecting...";
        }

        const botType = isDev ? "Local " : "";
        return this.isChatbotConnected ? `${botType}Bot Online` : `${botType}Bot Offline`;
      },
    },
    watch: {
      isChatbotConnected(newVal) {
        this.$emit("on-chatbot-connected", newVal);
      },
    },
    created() {
      // Will display offline if no ping is received...
      this.setChatbotPingTimeout();

      // Keep Heroku alive - lazy code.
      this.setServerPing(true);
    },
    beforeDestroy() {
      clearTimeout(this.chatbotPingTimeout);
    },
    sockets: {
      chatbotConnected(res) {
        if (res) {
          // Interrupt and start new ping countdown.
          this.setChatbotPingTimeout();
          this.chatbotLastPingedAt = new Date();
          this.chatbotConnectedAt = res.connectedAt;
          this.connectedChannels = res.channels;
          this.isChatbotConnected = true;
          this.isChatbotConnecting = false;
        } else {
          /**
           * Kill ping countdown until reconnection.
           * Remember: this is triggered explicitly (`!dc`), NOT by the server dropping connection.
           */
          clearTimeout(this.chatbotPingTimeout);
          this.connectedChannels = [];
          this.isChatbotConnected = false;
          this.isChatbotConnecting = false;
        }
      },

      /**
       * Resend request when server encounters 401.
       */
      async chatUsersPollingError(error) {
        const willRetry = this.isChatbotConnected && error && this.pollingRetries < 100;

        if (willRetry) {
          this.pollingRetries += 1;

          // Slow back off - acts as throttle in case things go wrong.
          setTimeout(() => {
            this.pollingRetries -= 2;
          }, 20 * 60 * 1000);

          try {
            await axios({
              url: tmiConfig.endpoints.pollChatters,
              method: "GET",
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
              // data: {
              // userId: this.dataObject.userstate.userId || this.dataObject.userstate["user-id"],
              // },
            });

            this.$log({
              message: `chatUsers.vue: Retry successful (Retries: ${this.pollingRetries}).`,
            });
          } catch (fetchErr) {
            this.$log({
              message: `Oops! ChatUsers.vue pollChatters error. retries: ${this.pollingRetries}`,
              data: fetchErr,
            });
          }
        }
      },
    },
    methods: {
      setChatbotPingTimeout() {
        const SIX_SECONDS = 6 * 1000;

        clearTimeout(this.chatbotPingTimeout);

        this.chatbotPingTimeout = setTimeout(() => {
          const isPingRecent = new Date() - (this.chatbotLastPingedAt || 0) < SIX_SECONDS;

          if (!isPingRecent) {
            this.isChatbotConnecting = false;
            this.isChatbotConnected = false;
          }
        }, SIX_SECONDS);
      },

      toggleChatbot() {
        const isToggled = !this.isChatbotConnected;
        const path = `${
          heroku.HEROKU_API.runChatbot
        }?q=${isToggled}&channels=${this.connectedChannels.join(",")}`;

        fetch(path, {
          credentials: "include",
        }).catch((err) => this.$log("Oops! Error:", err));

        if (isToggled) {
          this.isChatbotConnecting = true;

          // Keep Heroku alive - lazy code.
          this.setServerPing(true);
        } else {
          this.connectedChannels = [];
          this.isChatbotConnected = false;
          this.isChatbotConnecting = false;
        }
      },

      setServerPing(isToggled = null) {
        clearTimeout(this.serverPingInterval);

        if (isToggled) {
          const TWENTY_NINE_MINUTES = 29 * 60 * 1000;

          this.serverPingInterval = setInterval(() => {
            axios({
              url: heroku.HEROKU_API.ping,
              method: "GET",
            })
              .then(({ data }) => {
                console.log(":: Ping server - keep Heroku alive:", data);
              })
              .catch((err) => {
                console.log(":: Ping Heroku server failed:", err);
              });
          }, TWENTY_NINE_MINUTES);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-chat-status-parent {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    margin: rem-calc(10);
    // background: green;
  }

  //---------------------------------------------------------
  // PILL BUTTONS
  //---------------------------------------------------------

  $btn-color: rgba(white, 0.3);

  .btn-pill {
    display: flex;
    align-items: center;
    padding: rem-calc(2 5);
    border: 1px solid $btn-color;
    border-radius: 3px;
    // border-radius: 20px;
    background: transparent;
    font-size: rem-calc(11);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2;
    color: $btn-color;

    &:hover {
      background: rgba(black, 0.2);
    }
  }

  .is-chatbot-connected .btn-status {
    background: transparent;
    border-color: transparent;
    color: #06f393;
  }

  .is-chatbot-connected .btn-status:hover {
    color: $btn-color;
  }

  .is-chatbot-connected .btn-pill:hover {
    background: rgba($danger, 0.1);
    border-color: rgba($danger, 0.4);
  }

  //---------------------------------------------------------
  // CHANNEL BUTTON
  //---------------------------------------------------------

  .btn-channel {
    border-color: transparent;
    // border-radius: 20px;
    background: rgba(black, 0.2);
    text-transform: lowercase;
    color: rgba(white, 0.3);
  }

  .btn-channel .fa-close {
    display: none;
    margin-right: 2px;
  }

  .btn-channel:hover {
    background: rgba(black, 0.4);
    color: rgba(white, 0.5);
  }

  //---------------------------------------------------------
  // DOT INDICATOR
  //---------------------------------------------------------

  .dot-indicator {
    position: relative;
    top: -1px;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    border-radius: 100%;
    background-color: $danger;

    &.is-pending {
      background-color: grey;
    }

    &.is-online {
      background-color: #06f393;
      box-shadow: 0px 0px 5px rgba(#06f393, 0.9);
    }
  }
</style>
