<template>
  <div class="youtube-playlist-queue-controls-parent">
    <!-- DURATION BAR -->
    <div class="duration-bar">
      <!-- VUE SLIDER WRAPPER -->
      <div class="vue-slider-wrapper">
        <!-- VUE SLIDER -->
        <VueSlider
          class="vue-slider"
          v-model="playerProgressFloat"
          :contained="true"
          :dot-size="12"
          :height="3"
          :lazy="true"
          :tooltip="'active'"
          :tooltip-formatter="tooltipFormatter"
          :rail-style="{ background: 'black', 'border-radius': 0 }"
          :process-style="{ background: '#dfd45a', 'border-radius': 0 }"
          @change="onPlayerSeek(playerProgressFloat)"
        />
      </div>
    </div>

    <!-- WRAPPER -->
    <div class="d-flex align-items-center">
      <!-- LEFT -->
      <div class="btns">
        <!-- PLAYLIST SHUFFLE -->
        <button
          disabled
          class="btn btn-shuffle"
          :title="playlistShuffle ? 'Shuffle off' : 'Shuffle playlist'"
          :class="{ active: playlistShuffle }"
          @click="onPlaylistShuffle"
        >
          <i class="fa fa-random"></i>
        </button>

        <!-- PLAYLIST REPEAT -->
        <button
          class="btn btn-loop"
          :title="playlistRepeat ? 'Repeat playlist off' : 'Repeat playlist'"
          :class="{ active: playlistRepeat }"
          @click="onPlaylistRepeat"
        >
          <i class="fa fa-retweet"></i>
        </button>
      </div>

      <!-- MIDDLE -->
      <div class="btns mx-auto">
        <!-- PLAYER PREV -->
        <button
          class="btn btn-prev"
          title="Previous track"
          :class="{ active: playlistPrev }"
          :disabled="playlistPrev"
          :style="playlistPrev && { cursor: 'progress' }"
          @click="onPlaylistPrev"
        >
          <i class="fa fa-step-backward"></i>
        </button>

        <!-- PLAYER PLAY -->
        <button
          class="btn btn-play"
          :class="{ 'is-playing': youTubePlayer, 'is-paused': playerPause }"
          :title="!youTubePlayer || playerPause ? 'Play' : 'Pause'"
          @click="onPlayerPlay"
        >
          <i v-if="!youTubePlayer || playerPause" class="fa fa-play-circle"></i>
          <i v-if="youTubePlayer && !playerPause" class="fa fa-pause-circle"></i>
        </button>

        <!-- PLAYER NEXT -->
        <button
          class="btn btn-next"
          title="Next track"
          :class="{ active: playlistNext }"
          :disabled="playlistNext"
          :style="playlistNext && { cursor: 'progress' }"
          @click="onPlaylistNext"
        >
          <i class="fa fa-step-forward"></i>
        </button>
      </div>

      <!-- RIGHT -->
      <div class="btns">
        <!-- PLAYER LOCK -->
        <button
          class="btn btn-lock"
          :title="
            playerLock
              ? 'Unlock player to receive commands.'
              : 'Lock player from receiving commands.'
          "
          :class="{ active: playerLock }"
          @click="onPlayerLock"
        >
          <i class="fa fa-lock"></i>
        </button>

        <!-- VOLUME CONTROLS -->
        <div class="volume-controls" :class="{ active: playerVolumeActive }">
          <!-- PLAYER VOLUME -->
          <button class="btn btn-volume">
            <i v-if="localVolume > 0" class="fa fa-volume-up"></i>
            <i v-if="localVolume === 0" class="fa fa-volume-off">&nbsp;&nbsp;</i>
          </button>

          <!-- VOLUME SLIDER -->
          <div class="volume-slider">
            <!-- VOLUME OFF -->
            <div class="btn btn-volume-off" title="Mute" @click="onPlayerMute(true)">
              <i class="fa fa-volume-off"></i>
            </div>

            <!-- VUE SLIDER -->
            <VueSlider
              class="vue-slider"
              v-model="localVolume"
              :data="[0, 15, 30, 50, 80, 100]"
              :dot-size="12"
              :width="76"
              :height="3"
              :lazy="true"
              :tooltip="'none'"
              :rail-style="{ background: 'grey', 'border-radius': 0 }"
              :process-style="{ background: '#ded35a', 'border-radius': 0 }"
              @change="onUpdateVolume(localVolume)"
            />

            <!-- VOLUME UP -->
            <div class="btn btn-volume-up" title="Toggle Volume" @click="onPlayerMute">
              <i class="fa fa-volume-up"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import momentDurationFormatSetup from "moment-duration-format";
  import { mapGetters } from "vuex";
  import VueSlider from "vue-slider-component";
  import "vue-slider-component/theme/default.css";

  export default {
    components: {
      VueSlider,
    },
    props: {
      playerProgress: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        DEFAULT_VOLUME: 15,
        localVolume: this.DEFAULT_VOLUME,
        playerProgressFloat: this.playerProgress,
        playerVolumeActive: null,
        playerVolumeActiveTimeoutInstance: null,
        // tooltipFormatter: (v) => moment.duration(v).format("d:hh:mm:ss"),
        volumeIncrements: [0, 15, 30, 50, 80, 100], // issue with using as data property in VueSlider??
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getActiveVideo: "youtube/getActiveVideo",
        activeVideoIndex: "youtube/getActiveVideoIndex",
      }),
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      playerLock() {
        return !!this.getActiveTask("lockTaskGroup", "youTubePlaylist");
      },
      playerMute() {
        return !!this.getActiveTask("youTubePlayer", "Mute");
      },
      playerPause() {
        return !!this.getActiveTask("youTubePlayer", "Pause");
      },
      playerSeek() {
        return this.getActiveTask("youTubePlayer", "Seek");
      },
      playerVolume() {
        return this.getActiveTask("youTubePlayer", "Volume");
      },
      playlistPlay() {
        return this.getActiveTask("youTubePlaylist", "Play");
      },
      playlistNext() {
        return !!this.getActiveTask("youTubePlaylist", "Next");
      },
      playlistPrev() {
        return !!this.getActiveTask("youTubePlaylist", "Prev");
      },
      playlistRemove() {
        return this.getActiveTask("youTubePlaylist", "Remove");
      },
      playlistRepeat() {
        return !!this.getActiveTask("youTubePlaylist", "Repeat");
      },
      playlistShuffle() {
        return !!this.getActiveTask("youTubePlaylist", "Shuffle");
      },
    },

    //------------------------------------------------------------
    // WATCH
    //------------------------------------------------------------

    watch: {
      //------------------------------------------------------------
      // WATCH: PLAYER PROGRESS
      //------------------------------------------------------------

      playerProgress(newVal) {
        this.playerProgressFloat = newVal * 100; //parseInt(newVal * 100);
      },

      //------------------------------------------------------------
      // WATCH: PLAYER VOLUME
      //------------------------------------------------------------

      playerVolume: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            if (newVal.data.arg !== -1) {
              const volume = parseInt(newVal.data.arg);

              // Set local volume to nearest accepted increment.
              this.localVolume = this.volumeIncrements.sort(
                (a, b) => Math.abs(volume - a) - Math.abs(volume - b)
              )[0];
            } else {
              // Eg. handles typing `!ytvol <noarg>` into console.
              this.localVolume = 0;
            }

            // Show volume controls briefly.
            this.flashPlayerVolume();
          }
        },
      },

      //------------------------------------------------------------
      // WATCH: PLAYER MUTE
      //------------------------------------------------------------

      playerMute: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            const existingVolume =
              (this.playerVolume && this.playerVolume.data && this.playerVolume.data.arg) ||
              this.DEFAULT_VOLUME;

            // If mute true, set zero, else back to player volume (if existing).
            this.localVolume = !!newVal ? 0 : existingVolume;

            // Show volume controls briefly.
            this.flashPlayerVolume();
          }
        },
      },
    },

    //------------------------------------------------------------
    // METHODS
    //------------------------------------------------------------

    methods: {
      tooltipFormatter(perc) {
        const total = this.getActiveVideo?.durationMillis || 0;
        const ms = ((total * perc) / 1000) * 10;
        const mo = moment
          .duration(ms)
          .format("d:hh:mm:ss") // this likely works for mm:ss but is incorrectly configured on the server for dd:hh:mm:ss
          .padStart(4, "0:0");
        // console.log({ total, ms, mo });
        return mo;
      },
      //------------------------------------------------------------
      // FLASH PLAYER VOLUME
      // Syncs across screens to show volume intention.
      //------------------------------------------------------------

      flashPlayerVolume() {
        clearTimeout(this.playerVolumeActiveTimeoutInstance);
        this.playerVolumeActive = true;
        this.playerVolumeActiveTimeoutInstance = setTimeout(
          () => (this.playerVolumeActive = false),
          1100
        );
      },

      //------------------------------------------------------------
      // ON PLAYER LOCK
      //------------------------------------------------------------

      onPlayerLock() {
        // this.$store.dispatch('tasks/runTasksRemotely', [{ uuid: '5c5d94d1-8597-4b0c-83aa-faa58044bc81' }]);

        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: "08398459-01c9-4a87-b87e-82ae180350e6",
            data: {
              value: "youTubePlaylist",
            },
          },
        ]);
      },

      //------------------------------------------------------------
      // ON PLAYER MUTE
      // Quick mute-toggle by clicking the volume buttons.
      //------------------------------------------------------------

      onPlayerMute() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "be5681a7-aff1-4df5-a855-30237ecf62ee" },
        ]);
      },

      //------------------------------------------------------------
      // ON PLAYER PREV / NEXT
      //------------------------------------------------------------

      onPlaylistPrev() {
        this.playerProgressFloat = 0;
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "a36929c6-fdf5-430c-9580-c34279a370ea", canceled: false },
        ]);
      },

      onPlaylistNext() {
        this.playerProgressFloat = 0;
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "8d86f70a-21c1-4431-96f5-d2593ac5b07d", canceled: false },
        ]);
      },

      //------------------------------------------------------------
      // ON PLAYER PLAY
      //------------------------------------------------------------

      onPlayerPlay() {
        // If there's no player...
        if (!this.youTubePlayer) {
          // and not paused, run player.
          if (!this.playerPause) {
            this.$store.dispatch("tasks/runTasksRemotely", [
              { uuid: "a050ee28-c5a9-4e8c-a5c0-f1245449f7a3", canceled: false },
            ]); // force run
          }

          // Write active video index.
          this.$store.dispatch("tasks/runTasksRemotely", [
            {
              uuid: "d378db75-f708-4684-9a7d-7b5fc065bd38", // play
              data: {
                arg: this.activeVideoIndex,
              },
            },
          ]);
        } else {
          // Toggle pause.
          this.$store.dispatch("tasks/runTasksRemotely", [
            { uuid: "eb814b30-8e89-4cf0-9dc1-18bb26ab975d" },
          ]); // pause
        }
      },

      //------------------------------------------------------------
      // ON PLAYER SEEK
      //------------------------------------------------------------

      onPlayerSeek(perc) {
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: "8a35a699-aa30-4247-bbc3-a8d9873e48a7",
            data: {
              arg: perc,
            },
          },
        ]); // seek
      },

      //------------------------------------------------------------
      // ON PLAYER REPEAT
      //------------------------------------------------------------

      onPlaylistRepeat() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "4bbdb995-32cc-49ae-b53b-83b58b932be4" },
        ]);
      },

      //------------------------------------------------------------
      // ON PLAYER SHUFFLE
      //------------------------------------------------------------

      onPlaylistShuffle() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "e2736e43-e470-43f9-b135-d7adb13773a3" },
        ]);
      },

      //------------------------------------------------------------
      // ON UPDATE VOLUME (FINAL SYNC COMMAND)
      // The volume slider triggers this.
      // ISSUE: writing multiple doesn't work
      // https://trello.com/c/PhU9WEwT/394-issue-with-writing-multiple-tasks-with-writetask-nothing-happens
      //------------------------------------------------------------

      onUpdateVolume(event) {
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            // Mute can't exist when interacting with volume.
            uuid: "be5681a7-aff1-4df5-a855-30237ecf62ee",
            canceled: true,
          },
          {
            // Set volume.
            uuid: "85d7e282-f9a6-442e-9fe7-5d935499ea5e",
            canceled: false, // explicit
            data: {
              arg: event,
            },
          },
        ]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-playlist-queue-controls-parent {
    position: relative;
    // padding: rem-calc(10 4);
    box-shadow: 0 0 5px 3px rgba(black, 0.2);
    background: darken(#303239, 10%);
    z-index: 1; // for shadow
  }

  // Shadow
  .youtube-playlist-queue-controls-parent:after {
    content: "";
    position: absolute;
    top: rem-calc(-55);
    left: 0;
    width: calc(100% - 8px);
    height: rem-calc(55);
    background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, 1) 90%);
    z-index: -1;
    pointer-events: none;
  }

  .is-admin .youtube-playlist-queue-controls-parent:after {
    background: linear-gradient(to bottom, rgba(#363840, 0) 0%, rgba(#363840, 1) 90%);
  }

  //--------------------------------------------------------
  // BTN
  //--------------------------------------------------------

  .btn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: rem-calc(50);
    height: rem-calc(55);
    border-radius: 0;
    color: darken(white, 25%);
    // box-shadow: 0 0 1px 1px grey;
    transition: none;

    // Icons
    .fa {
      font-size: rem-calc(20);
      // background: red;
    }

    &:hover:not(.active) {
      // background: rgba(white, 0.04);
      background: rgba(black, 0.2);
      .fa {
        color: white;
      }
    }

    &.active {
      // color: $primary; //#b0ff38; // $primary
      color: lighten($primary, 5%);
      // text-shadow: 0px 0px 3px rgba(#b0ff38, 0.7);
    }
  }

  //--------------------------------------------------------
  // BTN PLAY
  //--------------------------------------------------------

  .btn-play {
    transform: scale(1.2);

    .fa {
      font-size: rem-calc(40);
    }

    &.is-playing {
      &:hover .fa {
        color: lighten($primary, 10%);
      }

      .fa {
        color: lighten($primary, 5%);
      }
    }

    &:hover {
      background: none !important;
      .fa {
        color: lighten($primary, 15%);
      }
    }
  }

  //--------------------------------------------------------
  // VOLUME CONTROLS
  //--------------------------------------------------------

  // Wrapper
  .volume-controls {
    display: inline-block;
  }

  .volume-controls:hover .volume-slider,
  .volume-controls.active .volume-slider {
    right: 0;
  }

  .volume-controls .volume-slider {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: -100vw;
    // right: 0;
    height: 100%;
    background: darken(#303239, 10%);
  }

  .volume-controls .volume-slider > .btn {
    background: none !important;
  }

  .volume-controls .volume-slider .btn-volume-off,
  .volume-controls .volume-slider .btn-volume-up {
    cursor: pointer;
  }

  //--------------------------------------------------------
  // DURATION BAR
  //--------------------------------------------------------

  .duration-bar {
    position: absolute;
    top: -3px;
    width: 100%;
    height: 3px;
    background-color: black;
    z-index: 1;
    // opacity: 0;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 200ms ease;

    // Flush hack
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 3px;
      width: 6px;
      background: $primary;
      z-index: 1;
    }

    .vue-slider-wrapper {
      position: absolute;
      width: 100%;
      left: 0;
      top: -6px;
    }

    ::v-deep .vue-slider-process[style] {
      background: #dfd45a;
    }

    ::v-deep .vue-slider-dot-handle {
      transform: scale(0);
      transition: transform 200ms ease;
    }

    &:hover {
      transform: scaleY(1) !important;

      ::v-deep .vue-slider-dot-handle {
        transform: scale(1);
      }
    }
  }

  .youtube-playlist-queue-controls-parent:hover .duration-bar {
    // transform: scaleY(0.5);
    transform: scaleY(1);
  }
</style>
