<template>
  <div
    class="mitch-os"
    :class="osClasses"
    v-shortkey="globalHotkeysFiltered"
    @shortkey.prevent="onGlobalHotkey"
  >
    <slot />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { firebase } from "../../config/firebase";
  import getTaskLibrary from "../../services/getTaskLibrary";

  export default {
    props: {
      defaultTasks: {
        type: Array,
        required: false,
      },
      taskGroupBindings: {
        type: Array,
        required: false,
      },
    },
    data() {
      return {
        localTasks: [], // Kept as reference to check new ones against.
        routines: [],
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getGlobalHotkeys: "tasks/getGlobalHotkeys",
        getRemoteTasks: "tasks/getRemoteTasks",
        getTaskById: "tasks/getTaskById",
        userData: "user/getUserData",
      }),
      // Scope hotkeys if specific task groups are requested.
      globalHotkeysFiltered() {
        return this.getGlobalHotkeys(this.taskGroupBindings);
      },
      osClasses() {
        return {
          "is-720p": !!this.getActiveTask("system", "720p Mode"),
          "is-black-and-white": !!this.getActiveTask("system", "Black and White"),
          "is-performance-mode": !!this.getActiveTask("system", "Performance Mode"),
          "is-tv-zoomed": !!this.getActiveTask("dashboardTV", "Zoom"),
        };
      },
    },

    /**
     * CREATED
     */
    async created() {
      const [success, clientError] = await this.setupClient();

      if (clientError) {
        console.log("Error in `setupClient` in MitchOS - logging out.");
        this.$store.dispatch("user/logout");
        return;
      }

      // Remember: this is the first API call. If it fails, it's due to JWT expiring. Logout.
      const [data, error] = await getTaskLibrary();

      if (error) {
        alert("Warning JWT from tms expired (90 days) - check MitchOS.vue.");
        this.$store.dispatch("user/logout");
        return;
      }

      this.$store.commit("tasks/SET_TASKS_LIBRARY", data);

      this.setInitTasks();
      this.bindTasks();
      this.bindCooldowns();

      // Global routines
      this.routines[0] = setInterval(() => {
        this.$store.dispatch("tasks/removeAllCanceledTasks");
        // TODO: this.$store.dispatch("tasks/performTimingDuties");
      }, 1000);

      this.$emit("ready", true);
    },

    /**
     * BEFORE DESTROY
     */
    beforeDestroy() {
      this.routines.forEach((item) => clearInterval(item));
    },

    /**
     * METHODS
     */
    methods: {
      /**
       * SETUP CLIENT
       */
      async setupClient() {
        try {
          const success = await Promise.all([
            this.$store.dispatch("user/setUserPresence", this.userData),
            this.$store.dispatch("user/bindClients"),
            this.$store.dispatch("user/bindClientDevices", this.userData.uid),
          ]);

          return [success, null];
        } catch (err) {
          return [null, err];
        }
      },
      /**
       * NOTE: only admin / broadcast pages should launch remote tasks.
       * User pages should be local.
       */
      onGlobalHotkey(event) {
        this.$store.dispatch("tasks/runTasksRemotely", [{ uuid: event.srcKey }]);
      },

      /**
       * SET INIT TASKS
       */
      setInitTasks() {
        if (this.defaultTasks?.length) {
          this.$store.commit("tasks/SET_DEFAULT_TASKS", this.defaultTasks);
        }

        // Seed first task manually and LOCALLY (scene: 'Stream Init').
        this.$store.dispatch("tasks/runTasks", [
          {
            uuid: "e451fc8a-e86e-4ed6-b520-53cfcff52b7c",
            id: "666",
            createdAt: firebase.firestore.Timestamp.now(),
          },
        ]);
      },

      /**
       * BIND TASKS
       */
      async bindTasks() {
        await this.$store.dispatch("tasks/bindTasks", this.taskGroupBindings);

        this.$store.watch(
          (state, getters) => this.getRemoteTasks,
          (newVal, oldVal) => {
            // console.log(newVal?.length + " task(s) received");

            if (newVal.length) {
              // Only accept new tasks not already existing in local.
              const newTasks = newVal.reduce((acc, doc) => {
                if (!this.getTaskById(doc.id)) {
                  acc.push(doc);
                }
                return acc;
              }, []);

              this.$store.dispatch("tasks/runTasks", newTasks);
            }
          },
          // Trigger existing server tasks IMMEDIATELY after REFRESH.
          { immediate: true }
        );
      },

      /**
       * BIND COOLDOWNS
       */
      bindCooldowns() {
        this.$store.dispatch("cooldowns/bindCooldowns");
      },
    },
  };
</script>

<style lang="scss" scoped>
  //-----------------------------------------------------------------
  // MITCH OS
  //-----------------------------------------------------------------

  .mitch-os {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    // background: #373941; //#444;
    // background: url('~@/assets/img/blurred-bg-2@2x.jpg') no-repeat top left;
  }

  //-----------------------------------------------------------------
  // IS 720p
  //-----------------------------------------------------------------

  .is-720p {
    box-sizing: content-box;
    width: 1920px; // Pixels
    height: 1080px;
    transform: scale(0.667);
    transform-origin: top left;
    overflow: hidden;
    border-right: 100vw solid black;
    border-bottom: 100vh solid black;
  }

  //-----------------------------------------------------------------
  // IS BLACK AND WHITE (FILTER)
  //-----------------------------------------------------------------

  .is-black-and-white {
    filter: grayscale(100%);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: url('~@/assets/img/grain@2x.png');
      background-size: 100px;
      // animation: grain-filter 0.25s steps(2) infinite;
      opacity: 0.7;
      pointer-events: none;
      z-index: 1;
    }

    @keyframes grain-filter {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }
  }

  //-----------------------------------------------------------------
  // IS TV ZOOMED
  //-----------------------------------------------------------------

  .is-tv-zoomed {
    transform: scale(4.5);
    transform-origin: bottom right;
  }
</style>
