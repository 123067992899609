<template>
  <div
    class="admin-panels-quick-tasks-parent"
    @mouseover="focusTextarea()"
  >
    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <AdminPanelHeaderTabs
        :tabs="tabs"
        :is-darker="true"
        @onSelect="onSelect($event)"
      />
    </AdminPanelHeader>

    <!-- PANEL BODY -->
    <div class="admin-panel-body">
      <AdminPanelBackdrops v-if="selectedTabName === 'Backdrops'" />
      <AdminPanelEffects v-if="selectedTabName === 'Effects'" />
      <AdminPanelQuickTasks v-if="selectedTabName === 'Quick Tasks'" />
      <AdminPanelPrograms v-if="selectedTabName === 'Programs'" />
      <AdminPanelScenes v-if="selectedTabName === 'Scenes'" />
      <AdminPanelSounds v-if="selectedTabName === 'Sounds'" />
    </div>
  </div>
</template>

<script>
  import ClickOutside from "vue-click-outside";
  import AdminPanelBackdrops from "./Default/AdminPanelBackdrops/AdminPanelBackdrops.vue";
  import AdminPanelEffects from "./Default/AdminPanelEffects/AdminPanelEffects.vue";
  import AdminPanelHeader from "../AdminUI/AdminPanelHeader.vue";
  import AdminPanelHeaderTabs from "../AdminUI/AdminPanelHeaderTabs.vue";
  import AdminPanelPrograms from "./Default/AdminPanelPrograms/AdminPanelPrograms.vue";
  import AdminPanelQuickTasks from "./Default/AdminPanelQuickTasks/AdminPanelQuickTasks.vue";
  import AdminPanelScenes from "./Default/AdminPanelScenes/AdminPanelScenes.vue";
  import AdminPanelSounds from "./Default/AdminPanelSounds/AdminPanelSounds.vue";

  export default {
    components: {
      AdminPanelBackdrops,
      AdminPanelEffects,
      AdminPanelHeader,
      AdminPanelHeaderTabs,
      AdminPanelPrograms,
      AdminPanelQuickTasks,
      AdminPanelScenes,
      AdminPanelSounds,
    },
    directives: {
      ClickOutside,
    },
    data() {
      return {
        isTextareaFocused: true,
        selectedTabName: "Quick Tasks",
        tabs: [
          {
            name: "Quick Tasks",
            icon: "",
            isActive: true,
            classes: "",
          },
          {
            name: "Backdrops",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Effects",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Programs",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Scenes",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Sounds",
            icon: "",
            isActive: false,
            classes: "has-no-right-border",
          },
        ],
      };
    },
    methods: {
      focusTextarea(bool) {
        this.isTextareaFocused = bool;
      },
      onSelect(tab) {
        this.tabs.forEach((item) => (item.isActive = false));
        tab.isActive = true;
        this.selectedTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panels-quick-tasks-parent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    // border: 1px solid yellow;
    pointer-events: none;
  }

  .admin-panel-body {
    position: relative;
    height: 100%;
    pointer-events: auto;
  }

  .admin-panel-footer {
    pointer-events: auto;
  }
</style>
