<template>
  <div
    v-show="!playerHide && !isBuffering"
    id="admin-twitch-player"
    class="twitch-player-parent"
    ref="player-instance"
  >
  </div>
</template>

<script>
  // THIS PLAYER IS AD HOC ONLY - REMOVE WHEN PLAYERS ARE REWRITTEN
  import { mapGetters } from "vuex";

  const embedScript = document.createElement("script");
  embedScript.setAttribute("src", "//player.twitch.tv/js/embed/v1.js");
  document.head.appendChild(embedScript);

  export default {
    props: {
      // Rewrite this to an outer shell component later.
      isShowing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isBuffering: null,
        isPlaying: null,
        playerInstance: null,
        volume: 0.3,
        volumeIntervalInstance: null,
        // runningTimeout: null
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
      }),
      playerFull() {
        // NOT IDEAL - SHARED WITH YOUTUBE
        return !!this.getActiveTask("youTubePlayer", "Full");
      },
      playerHide() {
        return !!this.getActiveTask("twitchPlayer", "Hide") && !this.isShowing;
      },
      playerMute() {
        return !!this.getActiveTask("twitchPlayer", "Mute");
      },
      playerSource() {
        return (
          !!this.getActiveTask("twitchPlayer", "Request Media") &&
          this.getActiveTask("twitchPlayer", "Request Media").data &&
          this.getActiveTask("twitchPlayer", "Request Media").data.arg
        );
      },
      playerVolume() {
        return this.getActiveTask("twitchPlayer", "Volume");
      },
    },
    watch: {
      //-----------------------------------------------------------------
      // WATCH: PLAYER MUTE
      //-----------------------------------------------------------------

      playerMute(newVal, oldVal) {
        if (newVal !== oldVal && this.playerInstance) {
          this.playerInstance.setMuted(!!newVal);
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYER PAUSE
      //------------------------------------------------------------

      // playerPause(newVal, oldVal) {
      //     (!!newVal && newVal !== oldVal) ? this.pause() : this.play();
      // },

      //-----------------------------------------------------------------
      // WATCH: PLAYER HIDE
      //-----------------------------------------------------------------

      playerHide(newVal, oldVal) {
        if (newVal !== oldVal && this.playerInstance) {
          this.playerInstance.setMuted(!!newVal || !!this.playerMute);
        }
      },

      //-----------------------------------------------------------------
      // WATCH: PLAYER SOURCE <source>
      //-----------------------------------------------------------------

      playerSource: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // If new source, init the player.
            if (!!newVal) {
              this.isBuffering = true;
              this.isPlaying = false;
              this.$nextTick(() => this.initPlayer());
            } else if (!!this.playerInstance) {
              this.destroyPlayer();
            }
          }
        },
      },

      //------------------------------------------------------------
      // WATCH: PLAYER VOLUME <arg>
      // Watered down from youTubePlayer (no fader)
      //------------------------------------------------------------

      playerVolume: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            if (this.playerInstance) {
              // const oldVolume = this.playerInstance.getVolume();
              const newVolume = this.playerVolume.data.arg / 100;

              if (newVolume >= 0 && newVolume <= 100) {
                this.playerInstance.setVolume(newVolume);
              }
            }
          }
        },
      },
    },

    //-----------------------------------------------------------------
    // BEFORE DESTROY
    //-----------------------------------------------------------------

    beforeDestroy() {
      this.destroyPlayer();
    },

    //-----------------------------------------------------------------
    // METHODS
    //-----------------------------------------------------------------

    methods: {
      //-----------------------------------------------------------------
      // INIT PLAYER
      //-----------------------------------------------------------------

      initPlayer() {
        this.destroyPlayer();

        const options = {
          allowfullscreen: false,
          autoplay: true,
          channel: this.playerSource,
          muted: true,
          width: "100%",
          height: "100%",
          theme: "dark",
          // video: "<video ID>",
          // collection: "<collection ID>",
        };

        this.playerInstance = new Twitch.Player("admin-twitch-player", options);
        this.playerInstance.setVolume(this.volume);

        this.playerInstance.addEventListener(Twitch.Player.ENDED, () => {
          console.log("ENDED");
        });

        this.playerInstance.addEventListener(Twitch.Player.PLAYBACK_BLOCKED, () => {
          console.log("PLAYBACK_BLOCKED");
        });

        this.playerInstance.addEventListener(Twitch.Player.PLAYING, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("PLAYING");
        });

        this.playerInstance.addEventListener(Twitch.Player.OFFLINE, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("OFFLINE");
        });

        this.playerInstance.addEventListener(Twitch.Player.READY, () => {
          console.log("READY");
        });
      },

      //-----------------------------------------------------------------
      // DESTROY PLAYER
      //-----------------------------------------------------------------

      destroyPlayer() {
        try {
          this.$refs["player-instance"].querySelectorAll("iframe").forEach((elem) => {
            elem.parentNode.removeChild(elem);
          });

          this.playerInstance = null;
        } catch (error) {
          // fail silently
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .twitch-player-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
</style>
