import { render, staticRenderFns } from "./DebugMenuIcons.vue?vue&type=template&id=77e4c960&scoped=true&"
import script from "./DebugMenuIcons.vue?vue&type=script&lang=js&"
export * from "./DebugMenuIcons.vue?vue&type=script&lang=js&"
import style0 from "./DebugMenuIcons.vue?vue&type=style&index=0&id=77e4c960&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e4c960",
  null
  
)

export default component.exports