<template>
  <div class="youtube-playlist-parent">
    <!-- CHOOSE A STATION -->
    <header class="playlist-header mb-2" :class="{ 'is-paused': youTubePlayerPause }">
      <!-- TITLE -->
      <span class="text animated fadeIn" :key="'pl-title-' + youTubePlayer">
        <i v-show="!youTubePlayer" class="fa fa-spinner fa-spin fa-fw force-gpu"></i>
        <i v-show="youTubePlayer" class="fa fa-circle force-gpu"></i>
        {{ title }}

        <!-- PLAYLIST COUNT -->
        <span v-if="getPlaylist.length" class="text-muted ml-1">
          {{ activeVideoIndex + 1 }}/{{ getPlaylist.length }}
        </span>
      </span>

      <!-- AUTOPLAY -->
      <div v-if="!youTubePlaylist" class="show-for-debug ml-auto">
        <div class="custom-control custom-switch">
          <input
            v-model="autoplay"
            id="autoplay"
            class="custom-control-input"
            name="Autoplay"
            type="checkbox"
            @change="onPlaylistAutoplay"
          />
          <label class="custom-control-label" for="autoplay" title="Run playlist on selection">
            Autoplay
          </label>
        </div>
      </div>

      <!-- BACK @click="onDisplayAllPlaylists" -->
      <div v-if="getPlaylistSelected" class="ml-auto">
        <button
          v-if="youTubePlaylist"
          class="btn btn-back"
          @click="onDisplayAllPlaylists"
          v-show="showForAdmin"
        >
          <i class="fa fa-th-list"></i>&nbsp;Channels
        </button>

        <!-- CLEAR ALL -->
        <!-- <button
					v-if="youTubePlaylist"
					class="btn btn-clear"
					@click="onPlaylistClear"
					v-show="showForAdmin"
				>
					Clear
				</button> -->
      </div>
    </header>

    <!-- DIVIDER -->
    <hr class="divider" v-if="!youTubePlaylist" />

    <!-- PLAYLIST BODY -->
    <!-- <transition
			enter-active-class="animated fadeIn faster"
			leave-active-class="animated fadeOut faster"
			mode="out-in"
		> -->

    <YouTubePlaylistQueue
      v-if="getPlaylistSelected"
      key="queue"
      :showing="showing"
      @player-progress="playerProgress = $event"
    />
    <YouTubePlaylistPresets v-else key="presets" />
    <!-- </transition> -->

    <!-- TIME DISPLAY -->
    <YouTubePlaylistTimeDisplay v-if="isAdminScreen" :player-progress="playerProgress" />

    <!-- PLAYLIST CONTROLS -->
    <YouTubePlaylistControls
      v-if="getPlaylistSelected && youTubePlaylist"
      v-show="showForAdmin"
      :player-progress="playerProgress"
    />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import YouTubePlaylistControls from "./YouTubePlaylistControls.vue";
  import YouTubePlaylistPresets from "./YouTubePlaylistPresets.vue";
  import YouTubePlaylistQueue from "./YouTubePlaylistQueue.vue";
  import YouTubePlaylistTimeDisplay from "./YouTubePlaylistTimeDisplay.vue";

  export default {
    components: {
      YouTubePlaylistControls,
      YouTubePlaylistQueue,
      YouTubePlaylistPresets,
      YouTubePlaylistTimeDisplay,
    },
    props: {
      options: {
        type: Object,
        required: false,
      },
      showing: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        autoplay: false,
        isAdminScreen: window.location.pathname.includes("/admin"),
        playerProgress: 0,
        selectedPlaylist: null, // *new*
      };
    },
    computed: {
      ...mapGetters({
        activeVideoIndex: "youtube/getActiveVideoIndex",
        // getAllTasks: 'tasks/getAllTasks',
        getActiveTask: "tasks/getActiveTask",
        getPlaylist: "youtube/getPlaylist",
        getPlaylistSelected: "youtube/getPlaylistSelected",
      }),
      showForAdmin() {
        if (window.location.pathname.includes("/admin") || this.tempDebug) {
          return true;
        }
      },
      tempDebug() {
        return !!this.getActiveTask("debug", "Run");
      },
      title() {
        let title = "";
        if (this.youTubePlayer) title = "Now playing";
        if (this.youTubePlayer && this.youTubePlayerPause) title = "Now paused";
        if (this.youTubePlaylist && !this.youTubePlayer) title = "Waiting to play";
        if (!this.youTubePlaylist) title = "Choose a channel";
        return title;
      },
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      youTubePlayerLock() {
        return !!this.getActiveTask("youTubePlayer", "Lock"); // TODO: make this universal
      },
      youTubePlayerPause() {
        return !!this.getActiveTask("youTubePlayer", "Pause");
      },
      youTubePlaylist() {
        return !!this.getPlaylist.length;
      },
      youTubePlaylistAutoplay() {
        return !!this.getActiveTask("youTubePlaylistSettings", "Autoplay");
      },
    },
    watch: {
      // Handle sound for chat/playlist switch.
      showing(newVal, oldVal) {
        this.$playSound("GUI_Transition 18", { volume: 0.3, interrupt: true });
      },

      // Plays 'whoosh' sfx when playlist fills and depletes.
      youTubePlaylist(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$playSound("GUI_Transition 18", { volume: 0.3, interrupt: true }); // could change sfx

          if (!newVal) {
            this.$store.dispatch("tasks/runTasks", [
              { uuid: "a050ee28-c5a9-4e8c-a5c0-f1245449f7a3", canceled: true },
            ]);
          }
        }
      },

      youTubePlaylistAutoplay: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.autoplay = newVal;
          }
        },
      },
    },
    created() {
      // BIND YOUTUBE PLAYLISTS FROM STORE
      this.$store.dispatch("youtube/bindPlaylists");
    },
    methods: {
      onDisplayAllPlaylists() {
        this.$store.commit("youtube/SET_PLAYLIST_SELECTED", null);
      },
      onPlaylistAutoplay() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "4e1f2d67-24d7-481f-a235-44d0aa990b2e", canceled: !this.autoplay },
        ]);
      },
      onPlaylistClear() {
        this.$store.dispatch("youtube/clearPlaylist");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-playlist-parent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    // border: 1px dashed rgba(orange, 0.5);
  }

  //-----------------------------------------------------------------
  // HEADER
  //-----------------------------------------------------------------

  .playlist-header {
    display: flex;
    align-items: center;
    padding: rem-calc(15 15 0 13); // Line up with zero index.
    font-weight: 700;
    color: darken(white, 20%);
    // border: 1px solid grey;
  }

  .playlist-header .fa {
    color: darken(white, 40%);
  }

  .playlist-header .fa-circle {
    margin-right: rem-calc(3);
    color: #ff0000;
    opacity: 0.5;
    animation: blinker 2.8s linear infinite;

    @keyframes blinker {
      25% {
        opacity: 0;
      }
      50% {
        opacity: 0.5;
      }
      75% {
        opacity: 0;
      }
    }
  }

  .playlist-header.is-paused .fa-circle {
    color: rgba(orange, 0.9);
    animation: none;
  }

  .is-performance-mode .fa-spin {
    animation: none;
  }

  .playlist-header .btn-clear,
  .playlist-header .btn-back {
    padding: 0;
    color: #93414c;
    font-weight: 700;
    transition: none;

    i {
      color: #93414c;
    }

    &:hover {
      color: lighten(#93414c, 10%);
    }
  }

  //-----------------------------------------------------------------
  // DIVIDER
  //-----------------------------------------------------------------

  .divider {
    margin: rem-calc(10 15 0 15);
    background-color: rgba(white, 0.2);
  }
</style>
