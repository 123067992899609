<template>
  <div class="admin-panel-monitor-parent">
    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <button class="btn-panel-header mx-auto" title="Monitor (M)" @click="handleOpen">
        <i
          v-if="isAnyMediaTransmitting && isScreenSharePlayerShowing"
          class="btn-panel-header-icon fa fa-eye"
        >
        </i>
        <i v-if="isAnyMediaTransmitting" class="btn-panel-header-icon fa fa-wifi"> </i>
        Monitor <i :class="isOverlayPanelOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
      </button>
    </AdminPanelHeader>

    <!-- PANEL BODY -->
    <div class="admin-panel-body">
      <!-- OVERLAY PANEL -->
      <div
        v-if="isOverlayPanelOpen"
        class="ui-overlay-panel-container"
        :class="{
          'has-tint': isOverlayPanelOpen && activeMediaInstances && activeMediaInstances.length,
        }"
      >
        <!-- CLOSE HIT AREA -->
        <div class="ui-overlay-panel-close" @click="isOverlayPanelOpen = false"></div>

        <!-- OVERLAY PANEL -->
        <div
          class="ui-overlay-panel"
          :style="setOverlayPanelMaxWidth(mediaInstances.length)"
          @click="!isAddingScreenshare && handleOpen()"
        >
          <ul>
            <li
              v-for="device in mediaInstances"
              :key="device.id"
              :style="panelListItemStyle(mediaInstances.length)"
              :class="{
                'is-active': device && device.isActive,
                'is-transmitting': device && device.isTransmitting,
              }"
            >
              <button class="btn-remove-media-device" @click.stop="handleRemove(device)">
                &times;
              </button>

              <!-- MEDIA DEVICE THUMBNAIL -->
              <div class="media-device-thumbnail" @click.stop="handleSelect(device)">
                <!-- TRANSMIT BUTTON -->
                <button
                  v-if="device.isActive"
                  class="btn-transmit-media"
                  :class="{ 'is-active': device.isTransmitting }"
                  @click.stop="handleTransmit(device)"
                >
                  <i class="fa fa-wifi"></i>
                </button>

                <!-- MEDIA DEVICE TEXT -->
                <div class="text-wrapper">
                  <span class="text">
                    <i class="fa fa-video-camera mr-1"></i>
                    {{ device.label.split("(")[0].trim() }}
                  </span>
                </div>

                <!-- THUMBNAIL VIDEO -->
                <video
                  muted
                  width="100%"
                  x-webkit-airplay="allow"
                  :class="device.isActive && 'is-active'"
                  :ref="'thumbnail-' + device.id"
                ></video>

                <img src="~@/assets/img/video-sizer-1920x1080.svg" width="100%" alt="Thumbnail" />
              </div>
            </li>

            <!-- TWITCH PLAYER -->
            <li class="is-twitch-player" :style="panelListItemStyle(mediaInstances.length)">
              <!-- MEDIA DEVICE THUMBNAIL -->
              <div
                class="media-device-thumbnail media-device-add-screenshare"
                @click="handleAddScreenshare"
              >
                <i class="fa fa-twitch"></i>
                <TwitchPlayer :is-showing="true" />
                <img src="~@/assets/img/video-sizer-1920x1080.svg" width="100%" alt="Thumbnail" />
              </div>
            </li>

            <!-- ADD SCREENSHARE -->
            <li class="is-add-screenshare" :style="panelListItemStyle(mediaInstances.length)">
              <!-- MEDIA DEVICE THUMBNAIL -->
              <div
                class="media-device-thumbnail media-device-add-screenshare"
                @click="handleAddScreenshare"
              >
                <i class="fa fa-plus"></i>
                <img src="~@/assets/img/video-sizer-1920x1080.svg" width="100%" alt="Thumbnail" />
              </div>
            </li>

            <!-- <li>experiment<canvas id="bigVideo"></canvas></li> -->
          </ul>

          <!-- ARROW -->
          <div class="ui-overlay-panel-arrow">
            <div class="ui-overlay-panel-arrow-fill"></div>
          </div>

          <!-- REFRESH BUTTON -->
          <button class="btn-refresh-monitors" @click="getUserMedia">
            <i class="fa fa-refresh"></i>
          </button>

          <!-- EJECT BUTTON -->
          <button class="btn-eject-monitors" @click.stop="handleEjectUserMedia">
            <i class="fa fa-eject"></i>
          </button>
        </div>
      </div>

      <!-- MEDIA DEVICE MONITORS (STAGE) -->
      <div
        v-if="activeMediaInstances.length"
        class="media-device-monitors"
        :class="{ 'has-secondary-media': activeSecondaryMediaInstances.length }"
      >
        <!-- PRIMARY -->
        <div class="media-device-monitors-primary">
          <!-- SECONDARY -->
          <div
            v-show="activeSecondaryMediaInstances.length"
            class="media-device-monitors-secondary"
          >
            <!-- SECONDARY VIDEO -->
            <transition-group name="list-complete" tag="div" class="secondary-transition-group">
              <video
                v-for="media in activeSecondaryMediaInstances"
                class="video-secondary list-complete-item is-active"
                :class="{
                  'is-transmitting': media && media.isTransmitting,
                }"
                :key="'secondary-' + media.id"
                :ref="'video-' + media.id"
                @click="handleSetPrimary(media)"
              ></video>
            </transition-group>
          </div>

          <!-- PRIMARY VIDEO (BASE) -->
          <video
            id="experiment-video"
            class="video-primary is-active is-primary"
            :class="{
              'is-transmitting':
                activePrimaryMediaInstance && activePrimaryMediaInstance.isTransmitting,
            }"
            ref="primaryVideoEl"
            x-webkit-airplay="allow"
          ></video>
        </div>
      </div>

      <!-- "SELECT MONITOR TEXT" -->
      <div v-if="!activeMediaInstances.length" class="default-prompt">
        <div class="default-prompt-text" @click="handleOpen">
          <i class="fa fa-exclamation-circle"></i>
          <h4>Select Monitor</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * NOTES:
   *
   * webcam light: https://stackoverflow.com/questions/28140147/turn-off-webcam-camera-after-using-getusermedia
   * multi-user: https://github.com/peers/peerjs/issues/179
   * https://w3c.github.io/mediacapture-main/getusermedia.html#media-track-constraints
   * https://css-tricks.com/an-introduction-to-the-picture-in-picture-web-api/
   * videoEl.addEventListener("click", (event) => videoEl.requestPictureInPicture())
   */
  import { mapGetters } from "vuex";
  import Peer from "peerjs";
  import AdminPanelHeader from "../../../AdminUI/AdminPanelHeader.vue";
  // import TwitchPlayer from "../../../../TwitchPlayer/TwitchPlayer.vue";
  import TwitchPlayer from "./TwitchPlayer.vue";

  export default {
    components: {
      AdminPanelHeader,
      TwitchPlayer,
    },
    data() {
      return {
        mediaInstances: [],
        peers: [],
        peerInstance: null,
        isAddingScreenshare: false,
        isOverlayPanelOpen: false,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getDisplayName: "user/getDisplayName",
        getClientDeviceId: "user/getClientDeviceId",
        getClientsOnline: "user/getClientsOnline",
      }),
      isScreenSharePlayerShowing() {
        return !!this.getActiveTask("screenSharePlayer", "Show");
      },
      activeMediaInstances() {
        return this.mediaInstances.filter((device) => device.isActive);
      },
      activePrimaryMediaInstance() {
        return this.mediaInstances.find((device) => device.isActive && device.isPrimary);
      },
      activeSecondaryMediaInstances() {
        return this.mediaInstances.filter((device) => device.isActive && !device.isPrimary);
      },
      isAnyMediaActive() {
        return !!this.activeMediaInstances?.length;
      },
      isAnyMediaTransmitting() {
        return !!this.mediaInstances.find((item) => item.isTransmitting);
      },
    },
    mounted() {
      /**
       * NOTES:
       *
       * https://github.com/peers/peerjs/issues/683
       *
       * If you DON'T specify 'host' and 'key' options, you will automatically
       * connect to PeerServer Cloud service.
       * Please be aware that you will be sharing it with other people
       * and IDs may collide if you set them manually.
       */
      const id = "appthemitchinghourcomadmin";
      this.peerInstance = new Peer({
        // host,
        // path: "/peerjs",
        // path: "/",
        // port: 443,
        secure: true,
      });

      this.getUserMedia();

      // document.addEventListener("keypress", (event) => {
      //   console.log(event);
      //   if (event.code === "KeyM") {
      //     this.handleOpen();
      //   }
      // });
    },
    methods: {
      //----------------------------------------------------
      // GET USER MEDIA
      //----------------------------------------------------

      async getUserMedia() {
        this.mediaInstances = [];

        try {
          const userMedia = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          });

          const devices = await navigator.mediaDevices.enumerateDevices().then((device) => {
            userMedia.getTracks().forEach((track) => track.stop());
            return [...device];
          });

          this.mediaInstances = devices.reduce((acc, inputDeviceInfo) => {
            if (inputDeviceInfo.kind === "videoinput") {
              const mediaInstance = {
                id: inputDeviceInfo.deviceId,
                label: inputDeviceInfo.label,
                mediaStream: null,
                isActive: false,
                isPrimary: false,
                isTransmitting: false,
              };
              acc.push(mediaInstance);
            }
            return acc;
          }, []);
        } catch (err) {
          this.$log("Oops! Error with getUserMedia:", err, true);
        }
      },

      //----------------------------------------------------
      // HANDLE ADD SCREENSHARE
      //----------------------------------------------------

      async handleAddScreenshare() {
        // Handles edge case where panel bg click-through interfers with process.
        this.isAddingScreenshare = true;

        try {
          const displayMedia = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: false,
          });

          const screenshareCount = this.mediaInstances.filter((media) =>
            media.label.includes("Screen Share")
          ).length;

          const mediaInstance = {
            id: displayMedia.id,
            label: `Screen Share #${screenshareCount + 1}`, // No labels are provided via api.
            mediaStream: displayMedia,
            isActive: true,
            isPrimary: !this.isAnyMediaActive,
            isTransmitting: false,
          };

          this.mediaInstances.push(mediaInstance);

          /**
           * 1. Play thumbnail screenshare in the open panel.
           * 2. Play main screenshare immediately after selection.
           */
          this.$nextTick(() => {
            this.playMedia({ mediaInstance, isThumbnail: true });
            this.playMedia({ mediaInstance, isThumbnail: false });
            this.isAddingScreenshare = false;
          });
        } catch (err) {
          this.$log("Oops! Error with handleAddScreenshare:", err);
          this.isAddingScreenshare = false;
        }
      },

      //----------------------------------------------------
      // HANDLE OPEN
      //----------------------------------------------------

      async handleOpen() {
        this.isOverlayPanelOpen = !this.isOverlayPanelOpen;

        // Play all thumbnails in the open panel.
        this.$nextTick(() => {
          if (this.mediaInstances.length) {
            this.experiment();
            if (this.isOverlayPanelOpen) {
              this.mediaInstances.forEach((mediaInstance) => {
                this.playMedia({ mediaInstance, isThumbnail: true });
              });
            }
          }
        });
      },

      //----------------------------------------------------
      // HANDLE EJECT
      //----------------------------------------------------

      handleEjectUserMedia() {
        // Turn off webcam light when panel closed with no active sources.
        // https://dev.to/morinoko/stopping-a-webcam-with-javascript-4297
        this.mediaInstances.forEach((mediaInstance) => {
          const tracks = mediaInstance.mediaStream?.getTracks();
          if (tracks) {
            tracks.forEach((track) => track.stop());
            this.mediaInstances = [];
          }
        });

        this.isOverlayPanelOpen = false;
      },

      //----------------------------------------------------
      // HANDLE REMOVE
      //----------------------------------------------------

      handleRemove(mediaInstance = {}) {
        if (mediaInstance.isTransmitting) {
          this.disconnectAllPeers();
        }

        this.mediaInstances = this.mediaInstances.filter((item) => item !== mediaInstance);
      },

      //----------------------------------------------------
      // HANDLE SET PRIMARY
      //----------------------------------------------------

      handleSetPrimary(mediaInstance = {}) {
        const oldPrimaryInstance = this.activePrimaryMediaInstance;
        oldPrimaryInstance.isPrimary = false;
        mediaInstance.isPrimary = true;

        this.$nextTick(() => {
          this.playMedia({ mediaInstance, isThumbnail: false });
          this.playMedia({ mediaInstance: oldPrimaryInstance, isThumbnail: false });
        });
      },

      //----------------------------------------------------
      // HANDLE SELECT
      //----------------------------------------------------

      async handleSelect(mediaInstance = {}) {
        /**
         * De-selecting an active primary video assigns a new primary.
         */
        const willAssignNewPrimary =
          mediaInstance.isPrimary &&
          mediaInstance.isActive &&
          this.activeSecondaryMediaInstances.length;

        if (willAssignNewPrimary) {
          mediaInstance.isPrimary = false;
          mediaInstance.isActive = false;

          const newPrimaryInstance = this.activeMediaInstances[0];
          newPrimaryInstance.isPrimary = true;
          newPrimaryInstance.isActive = true;

          this.$nextTick(() => {
            this.playMedia({ mediaInstance: newPrimaryInstance, isThumbnail: false });
          });
          return;
        }

        /**
         * Toggling active non-primaries, also assigning first primary.
         */
        mediaInstance.isPrimary = !this.isAnyMediaActive && !mediaInstance.isActive;
        mediaInstance.isActive = !mediaInstance.isActive;

        this.$nextTick(() => {
          // Selecting plays the video.
          if (mediaInstance.isActive) {
            this.playMedia({ mediaInstance, isThumbnail: false });
          }

          if (mediaInstance.isTransmitting) {
            mediaInstance.isTransmitting = false;
            this.disconnectAllPeers();
          }
        });
      },

      //----------------------------------------------------
      // PLAY MEDIA
      // Will play a mediaStream if exists, otherwise will
      // create a new one based on the existing id.
      //----------------------------------------------------

      async playMedia({ mediaInstance = {}, isThumbnail = false }) {
        if (!mediaInstance.mediaStream) {
          const constraints = {
            audio: {
              sampleSize: 16,
              channelCount: 2,
            },
            video: {
              aspectRatio: 1.777777778,
              frameRate: { min: 25, ideal: 60, max: 120 },
              deviceId: {
                ideal: mediaInstance.id,
              },
              quality: { min: 5, ideal: 10 },
              // width: { min: 640, ideal: 1280, max: 1920 }, // 4096
              // height: { min: 400, ideal: 720, max: 1080 }, // 2160

              width: { min: 640, ideal: 2560, max: 4096 }, // 4096
              height: { min: 400, ideal: 1440, max: 2160 }, // 2160
            },
          };

          const freshMediaStream = await navigator.mediaDevices.getUserMedia(constraints);
          mediaInstance.mediaStream = freshMediaStream;
        }

        let videoEl = {};

        if (isThumbnail) {
          videoEl = this.$refs[`thumbnail-${mediaInstance.id}`][0] || {};
        } else if (mediaInstance.isPrimary) {
          videoEl = this.$refs.primaryVideoEl;
        } else {
          videoEl = this.$refs[`video-${mediaInstance.id}`][0] || {};
        }

        if (videoEl) {
          videoEl.srcObject = mediaInstance.mediaStream;
          videoEl.play();
        }
      },

      //----------------------------------------------------
      // HANDLE TRANSMIT
      //----------------------------------------------------

      handleTransmit(mediaInstance = {}) {
        const { isTransmitting } = mediaInstance;

        // Set all transmitting false.
        this.mediaInstances = this.mediaInstances.map((item) => {
          item.isTransmitting = false;
          return item;
        });

        // Set transmitting for selected.
        mediaInstance.isTransmitting = !isTransmitting;

        // Close toggle && this.peerConnection
        if (isTransmitting) {
          this.disconnectAllPeers();
          return;
        }

        const peerIds = this.getClientsOnline.reduce((acc, client) => {
          const devicesExist =
            client.devices &&
            Object.keys(client.devices).length !== 0 &&
            Object.getPrototypeOf(client.devices) === Object.prototype;

          if (devicesExist) {
            // const keys = Object.keys(client.devices);
            const filteredIds = Object.keys(client.devices).reduce((acc2, key) => {
              if (client.devices[key].lastActiveAt === false) {
                // Remove dashed and underscores, incompatible with Peer.js.
                const keyFormatted = key.replace(/[_-]/g, "");
                acc2.push(keyFormatted);
              }
              return acc2;
            }, []);

            // const ids = filteredKeys.map((key) => key.replace(/[_-]/g, ""));
            acc.push(...filteredIds);
          }
          return acc;
        }, []);

        // CONNECT AND CALL PEERS
        peerIds.forEach((id) => {
          const newPeer = {
            connection: this.peerInstance.connect(id),
            call: this.peerInstance.call(id, mediaInstance.mediaStream),
          };
          this.peers.push(newPeer);
        });
        this.$log("Peer calling:", peerIds);
      },

      //----------------------------------------------------
      // DISCONNECT ALL PEERS
      //----------------------------------------------------

      disconnectAllPeers() {
        this.peers.forEach((peer) => {
          peer.connection.close();
        });
        this.peers = [];
        this.$log("All peers are disconnected.");
      },

      //----------------------------------------------------
      // EXPERIMENT
      // End goal being to transmit music videos from YouTube videos.
      // YouTube would be need to use html5 video over iframe.
      //
      // https://developers.google.com/web/updates/2016/10/capture-stream
      // https://github.com/thelevicole/youtube-to-html5-loader (June 2021)
      // https://stackoverflow.com/questions/24496605/how-can-i-show-the-same-html-5-video-twice-on-a-website-without-loading-it-twice
      // https://stackoverflow.com/questions/5157377/show-youtube-video-source-into-html5-video-tag
      // https://www.peachpit.com/articles/article.aspx?p=1760497&seqNum=4 (make copy of playing video)
      //----------------------------------------------------

      experiment() {
        // function updateBigVideo(v, c, w, h) {
        //   if (v.paused || v.ended) return false;
        //   c.drawImage(v, 0, 0, w, h);
        //   setTimeout(updateBigVideo, 20, v, c, w, h);
        // }
        // const v = document.getElementById("experiment-video");
        // const canvas = document.getElementById("bigVideo");
        // const context = canvas.getContext("2d");
        // const cw = 176;
        // const ch = 102;
        // canvas.width = cw;
        // canvas.height = ch;
        // // context.drawImage(v, 0, 0, cw, ch);
        // updateBigVideo(v, context, cw, ch);
      },

      //----------------------------------------------------
      // HELPERS
      //----------------------------------------------------

      setOverlayPanelMaxWidth(deviceCount) {
        let val = 200;

        if (deviceCount === 1) val = 400;
        if (deviceCount >= 2) val = 600;

        return {
          maxWidth: `${val}px`,
        };
      },

      panelListItemStyle(deviceCount) {
        let val = "100%";

        if (deviceCount === 1) val = "50%";
        if (deviceCount >= 2) val = "33.33%";

        return {
          flexBasis: val,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-monitor-parent {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    width: 100%;
    height: 100%;

    // position: relative;
    // width: 100%;
    // height: 100%;
    // border: 2px dashed aqua;
    // background: red;

    // overflow: hidden;
  }

  .btn-panel-header {
    position: relative;
    border: 0;
    color: rgba(white, 0.9);
    font-size: rem-calc(12);
    height: rem-calc(34);
    font-weight: 600;
    text-transform: uppercase;
    background: none;
  }

  .btn-panel-header-icon.fa-wifi {
    color: $danger;
    position: absolute;
    top: 11px;
    left: -11px;
  }

  .btn-panel-header-icon.fa-eye {
    color: $danger;
    position: absolute;
    top: 10px;
    left: -27px;
  }

  .admin-panel-body {
    position: relative;
    height: 100%;
    // pointer-events: auto;
    // border: 1px dashed yellow;
    // overflow: hidden; // hides the ui-panel-overlay
  }

  .default-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba(white, 0.1);
    text-align: center;

    .fa {
      margin-bottom: rem-calc(5);
      font-size: rem-calc(35);
      color: rgba(white, 0.1);
    }
  }

  //--------------------------------------------------------
  // UI PANEL
  //--------------------------------------------------------

  .ui-overlay-panel-container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    // border: 1px dashed pink;
  }

  .ui-overlay-panel-container.has-tint {
    background-color: rgba(black, 0.6);
    backdrop-filter: blur(10px);
  }

  .ui-overlay-panel-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .ui-overlay-panel {
    position: absolute;
    top: rem-calc(5);
    max-width: rem-calc(600);
    padding: rem-calc(10);
    background: #202025;
    border: 1px solid rgba(white, 0.2);
    border-radius: 5px;
    box-shadow: 0px 5px 20px rgba(black, 0.5);

    //--------------------------------------------------------
    // UI REFRESH MONITORS BUTTON
    //--------------------------------------------------------

    .btn-refresh-monitors,
    .btn-eject-monitors {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0;
      width: rem-calc(22);
      height: rem-calc(20);
      line-height: 0;
      border: 0;
      // border: 1px solid grey;
      font-size: rem-calc(14);
      background: none;
      color: rgba(white, 0.1);

      &:hover {
        color: rgba(white, 0.2);
      }
    }

    .btn-eject-monitors {
      right: rem-calc(22);
    }

    //--------------------------------------------------------
    // UI PANEL ARROW
    //--------------------------------------------------------

    .ui-overlay-panel-arrow {
      position: absolute;
      top: rem-calc(-20);
      left: calc(50% - #{rem-calc(20/2)});
      width: rem-calc(20);
      height: rem-calc(20);
      overflow: hidden;
      // border: 1px solid rgba(white, 0.1);
      // transform: rotate(45deg);
      // background: #202025;

      .ui-overlay-panel-arrow-fill {
        position: absolute;
        top: calc(50% + 3px);
        left: calc(50% - #{rem-calc(20/2)});
        width: rem-calc(20);
        height: rem-calc(20);
        border: 1px solid rgba(white, 0.2);
        transform: rotate(45deg);
        background: #202025;
      }
    }

    //--------------------------------------------------------
    // UI PANEL UL, LI, TEXT, VIDEO
    //--------------------------------------------------------

    ul {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      // gap: 10px;
      margin: 0;
      padding: 0;
      list-style: none;
      color: rgba(white, 0.8);

      li {
        display: flex;
        // flex-basis: 33.33%;
        // flex-direction: column;
        position: relative;
        padding: rem-calc(0);
        // margin-bottom: rem-calc(10);
        // border: 1px dashed yellow;
        // margin: rem-calc(5);

        .text-wrapper {
          // display: none;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 18px;
          padding: rem-calc(0 5);
          background: rgba(black, 0.6);
          // backdrop-filter: blur(10px);
          z-index: 1;
        }

        .text {
          position: absolute;
          width: 96%;
          font-size: rem-calc(12);
          font-weight: 600;
          color: rgba(white, 0.7);
          // border: 1px solid red;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      // li.is-add-screenshare:nth-of-type(3n + 2) {
      //   margin-right: auto;
      //   margin-left: 3.1%;
      //   // border: 1px solid red;
      // }
    }
  }

  //--------------------------------------------------------
  // UI PANEL REMOVE
  //--------------------------------------------------------

  .ui-overlay-panel .btn-remove-media-device {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(20);
    height: rem-calc(20);
    padding: 0;
    border: none;
    border-radius: 100%;
    // border-radius: 2px;
    margin: 3px;
    box-shadow: 1px 1px 5px 1px rgba(black, 0.5);
    font-weight: bold;
    line-height: rem-calc(20);
    text-align: center;
    background: #d4d4d5;
    z-index: 1;
  }

  .ui-overlay-panel li:hover .btn-remove-media-device {
    display: block;
  }

  //--------------------------------------------------------
  // MEDIA DEVICE THUMBNAIL (BUTTON)
  //--------------------------------------------------------

  .btn-transmit-media {
    position: absolute;
    top: 0;
    right: -1px;
    width: rem-calc(20);
    height: rem-calc(20);
    padding: 0;
    border: none;
    background-color: rgba(black, 0.5);
    font-size: rem-calc(12);
    color: rgba(white, 0.5);
    border-radius: 3px;
    margin: 3px;
    // box-shadow: 1px 1px 2px 1px rgba(black, 0.5);
    font-weight: bold;
    line-height: rem-calc(20);
    text-align: center;
    z-index: 20;
    pointer-events: default;
  }

  .btn-transmit-media.is-active {
    color: $danger;
  }

  .btn-transmit-media:hover {
    background-color: rgba(black, 0.6);
  }

  .media-device-thumbnail {
    position: relative;
    border: 1px solid #202025;
    margin: rem-calc(10);
    background: rgba(black, 0.2);
    animation: little-jump 300ms ease 0s alternate 1 forwards;

    @keyframes little-jump {
      from {
        transform: scale(0.9);
      }
      to {
        transform: scale(1);
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-color: rgba(white, 0.5);
    }

    &:hover {
      box-shadow: 0 0 1px 1px rgba(white, 0.5);
    }
  }

  .is-active .media-device-thumbnail {
    box-shadow: 0 0 0px 2px $primary;
  }

  .is-transmitting .media-device-thumbnail {
    box-shadow: 0 0 0px 2px $danger;
  }

  //--------------------------------------------------------
  // MEDIA DEVICE THUMBNAIL (SCREENSHARE)
  //--------------------------------------------------------

  .media-device-add-screenshare {
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(white, 0.05);
    transition: transform 300ms ease;
    animation: none;

    .fa,
    .fa-plus {
      position: absolute;
      top: calc(50% - #{rem-calc(40/2)});
      width: rem-calc(40);
      height: rem-calc(40);
      font-size: rem-calc(40);
      color: rgba(white, 0.2);
      text-align: center;
    }

    &:hover {
      background-color: rgba(white, 0.06);
      box-shadow: none;
      transform: scale(1.05);
    }

    &:active {
      background-color: rgba(white, 0.05);
    }
  }

  //--------------------------------------------------------
  // MEDIA DEVICE MONITORS (STAGE)
  //--------------------------------------------------------

  .media-device-monitors {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px dashed red;
    filter: grayscale(30%) brightness(1.1) contrast(1);
    background: rgba(black, 0.2);
  }

  .media-device-monitors-primary {
    width: 100%;
    height: 100%;
    // border: 2px dashed lime;

    video {
      width: 100%;
      height: 100%;
      // border: 1px dashed white;
    }
  }

  .video-primary.is-transmitting,
  .video-secondary.is-transmitting {
    border: 2px solid $danger;
  }

  .has-secondary-media .media-device-monitors-primary > video {
    height: calc(100% - 16%);
  }

  .media-device-monitors-secondary {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 16%;
    overflow: hidden;
    // border: 2px dashed lime;
    background-color: rgba(white, 0.02);
    border-bottom: 1px solid #101013;
    // box-shadow: 0px 2px 3px 2px rgba(black, 0.2);

    // &:hover video {
    //   box-shadow: 0 0 1px 1px rgba(white, 0.5);
    // }

    video {
      width: auto;
      height: 100%;
      padding: 10px 6px;
      // background: rgba(black, 0.5);
      // border: 2px solid rgba(white, 0.1);
    }
  }

  // .secondary-transition-group {
  //   // padding: 10px 6px;
  // }

  //-------------

  .list-complete-item {
    transition: transform 300ms;
  }

  .list-complete-enter,
  .list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

  .list-complete-leave-active {
    position: absolute;
    opacity: 0;
  }
</style>
