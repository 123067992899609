<template>
  <div
    class="chat-messages-item-avatar-parent"
    :class="{
      'has-loaded': !loading,
    }"
  >
    <!-- BG FILL -->
    <div class="inner">
      <span
        class="btn-tile-bg"
        :style="{ 'background-image': 'url(' + dynamicPath + ')' }"
      >
      </span>
    </div>

    <!-- WARNING -->
    <i class="fa fa-warning animated fadeIn delay-1s"></i>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      path: {
        type: String,
        // required: true,
        default: "No Avatar - Remember to regen Twitch appToken on server",
      },
      isTimedOut: {
        type: Boolean,
        default: null,
      },
      suspicious: {
        type: Boolean,
        default: null,
      },
      userId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        dynamicPath: this.path,
        loading: true,
        customAvatars: {
          // Faizy
          518493928: {
            default: require("./assets/img/avatars/faizy@2x.png"),
          },
          466536274: {
            default: require("./assets/img/avatars/faizy@2x.png"),
          },
          anon: {
            default: require("./assets/img/avatars/anon@2x.jpg"),
          },
        },
      };
    },
    created() {
      const customAvatar = this.customAvatars[this.userId];
      if (customAvatar) {
        this.dynamicPath = customAvatar.default;
      }
      if (this.suspicious) {
        this.dynamicPath = this.customAvatars.anon.default;
      }

      this.setAvatarImage(this.path);
    },
    methods: {
      //--------------------------------------------------------
      // SET AVATAR IMAGE
      // https://stackoverflow.com/questions/47535317/how-to-fade-in-images-when-loaded-with-vue
      // https://css-tricks.com/making-animations-wait/
      //--------------------------------------------------------

      setAvatarImage(path) {
        const img = new Image();
        img.crossOrigin = "anonymous"; // For colorThief.
        img.src = path;

        img.onload = () => (this.loading = false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------
  // CHAT MESSAGE AVATAR
  //--------------------------------------------------------

  .chat-messages-item-avatar-parent {
    position: relative;
    top: rem-calc(2);
    width: rem-calc(44);
    height: rem-calc(44);
    // background: rgba(white, 0.08);
    margin-right: rem-calc(15);
    cursor: pointer;

    .inner {
      border-radius: 100%;
      overflow: hidden;
    }

    .btn-tile-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%; // safari
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      z-index: 1;

      transform: scale(0);
      transition: transform 300ms ease 50ms;
    }
  }

  // IS SUSPICIOUS
  .fa-warning {
    display: none;
    position: absolute;
    bottom: 0;
    left: 1px;
    font-size: rem-calc(10);
    color: $warning;
    z-index: 1;
  }

  .is-suspicious .chat-messages-item-avatar-parent {
    .fa-warning {
      display: block;
    }
  }

  // HAS LOADED
  .has-loaded .btn-tile-bg {
    transform: scale(1);
  }

  // IS FOREIGN
  .is-foreign .chat-messages-item-avatar-parent,
  .chat-messages-filtered .chat-messages-item-avatar-parent {
    opacity: 0.5;
    filter: brightness(60%) grayscale(100%);
  }

  .is-child .chat-messages-item-avatar-parent {
    visibility: hidden;
    height: 0;
  }
</style>
