<script>
  import { mapGetters } from "vuex";
  import AdminPanelGridUnderlay from "../../../AdminUI/AdminPanelGridUnderlay.vue";
  import AdminPanelOBSConnect from "./AdminPanelOBSConnect.vue";
  import AdminPanelOBSControls from "./AdminPanelOBSControls.vue";

  export default {
    components: {
      AdminPanelOBSConnect,
      AdminPanelOBSControls,
      AdminPanelGridUnderlay,
    },
    computed: {
      ...mapGetters({
        getIsWebSocketConnected: "obs/getIsWebSocketConnected",
      }),
    },
  };
</script>

<template>
  <div class="admin-panel-obs-parent">
    <div class="has-scrollbars">
      <div class="inner">
        <AdminPanelGridUnderlay v-if="getIsWebSocketConnected" />

        <AdminPanelOBSControls
          v-if="getIsWebSocketConnected"
          class="mb-4"
        />
      </div>

      <AdminPanelOBSConnect
        v-show="!getIsWebSocketConnected"
        class="obs-connect"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .admin-panel-obs-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // padding: rem-calc(20);
    padding: rem-calc(20 20 0 20);
  }

  .inner {
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .obs-connect {
    z-index: 1;
  }

  .has-scrollbars a {
    display: block;
  }

  .admin-panel-obs-parent,
  .admin-panel-obs-parent * {
    pointer-events: auto;
  }
</style>
