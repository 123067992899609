<template>
  <div class="chat-users-parent">
    <!-- @scroll="onScroll" -->
    <div
      class="chat-users-scroller has-scrollbars has-scrollbars-on-hover"
      ref="chat-messages-scroller"
    >
      <ul class="list-unstyled">
        <li v-for="user in activeUsersSorted" :key="user.uid">
          <!-- CHAT USER -->
          <div class="chat-user" :class="getStatusClasses(user.username, user.lastInteractionAt)">
            <!-- AVATAR -->
            <div class="chat-user-avatar">
              <img :src="user.profileImageURL" :alt="user.displayName" />
            </div>

            <!-- CHAT USER NAME -->
            <div class="chat-user-name w-100">
              <span class="mr-3">{{ user.displayName }}</span>

              <span class="float-right chat-user-data">
                <span class="status-indicator"></span>
                <span class="mr-2"><i class="fa fa-level-up"></i> {{ user.level }}</span>
                <span class="mr-2"><i class="fa fa-envelope"></i> {{ user.totalMessages }}</span>
                <span class="mr-2"><i class="fa fa-money"></i> {{ user.totalPoints }}</span>
                <span class="mr-2"><i class="fa fa-bolt"></i> {{ user.totalCommands }}</span>
              </span>
            </div>
          </div>
        </li>
      </ul>

      <!-- <span class="text-white">{{ activeUsersSorted }}</span> -->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    components: {},
    props: {},
    computed: {
      ...mapGetters({
        getActiveUsers: "chat/getActiveUsers",
      }),
      activeUsersSorted() {
        // return [];
        // return this.getActiveUsers.map((user) => +user.lastInteractionAt?.seconds);
        return this.getActiveUsers.slice().sort((a, b) => {
          const aSecs = a.lastInteractionAt?.seconds;
          const aTime = aSecs ? aSecs * 1000 : 0;
          const bSecs = b.lastInteractionAt?.seconds;
          const bTime = bSecs ? bSecs * 1000 : 0;

          return bTime - aTime;
        });
      },
    },
    created() {
      this.$store.dispatch("chat/bindActiveUsers");
    },
    methods: {
      getStatusClasses(name, timestamp) {
        const AWAY_TIME = 20 * 60 * 1000;
        const AWAY_EXT_TIME = 60 * 60 * 1000;
        const CHATTING_TIME = 1 * 60 * 1000;

        const seconds = timestamp?.seconds * 1000 || 0;
        const date = new Date(seconds);
        const now = new Date();

        const isAwayExtended = now - date > AWAY_EXT_TIME;
        const isAway = !isAwayExtended && now - date > AWAY_TIME;
        const isAvailable = !isAway && timestamp !== false;
        const isChatting = !isAway && now - date > CHATTING_TIME;

        // console.log({ name, isAvailable, isChatting, isAway, isAwayExtended });

        return {
          "is-available": isAvailable,
          "is-chatting": isChatting,
          "is-away": isAway,
          "is-away-extended": isAwayExtended,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chat-users-parent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    // border: 1px dashed rgba(orange, 0.5);
    background: #27272f;
  }

  .chat-users-scroller {
    // padding: rem-calc(15);
    padding-top: rem-calc(10);
  }

  .chat-users-scroller ul li:nth-child(odd) .chat-user {
    background-color: rgba(black, 0.1);
  }

  //--------------------------------------------------------
  // CHAT USER
  //--------------------------------------------------------

  .chat-user {
    display: flex;
    align-items: center;
    padding: rem-calc(10 15);
    color: white;
    font-weight: 500;
    margin-bottom: rem-calc(3);
  }

  .chat-user-data {
    font-size: 10px;
  }

  .status-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: rem-calc(0 5);
    background: red;
    border-radius: 100%;
  }

  .is-available .status-indicator {
    background-color: lime;
  }
  .is-chatting .status-indicator {
    background-color: yellow;
  }
  .is-away .status-indicator {
    background-color: #eee;
  }
  .is-away-extended .status-indicator {
    background-color: red;
  }

  .chat-user-avatar {
    filter: grayscale(100%) brightness(80%);
    margin-right: rem-calc(10);
    // background: red;
    opacity: 0.5;

    img {
      display: block;
      min-width: rem-calc(28);
      width: rem-calc(28);
      border-radius: 100%;
    }
  }

  .chat-user.is-available .chat-user-avatar {
    filter: none;
  }

  .chat-user-name {
    margin: 0;
    max-height: rem-calc(38);
    overflow: hidden;
    font-family: $font-family-sans-serif;
    font-size: rem-calc(15);
    font-weight: 600;
    // color: darken(white, 20%);
    color: grey;
  }
</style>
