<template>
  <div
    class="chat-user-profile-parent"
    :class="{
			// 'is-something': something,
		}"
  >
    <div
      v-if="userData && userData.prunedUserData"
      class="chat-user-profile shadow"
    >
      <p>
        <img
          class="avatar"
          width="60"
          :src="profileImageURL"
          alt=""
        >
      </p>

      <h4 class="text-white mb-2">
        {{ userData.prunedUserData.usernameAlias || userData.prunedUserData.displayName }}
      </h4>

      level {{ userData.prunedUserData.level }} -
      ${{userData.prunedUserData.totalPoints }} -
      {{ userData.prunedUserData.isMember && 'member' }}
      <br>

      <div v-if="userData && userData.prunedUserData && userData.prunedUserData.roles">
        <div><small class="text-muted">ROLES</small></div>
        <div
          v-for="role in userData.prunedUserData.roles"
          :key="role"
          class="btn-role"
        >
          <!-- <button class="btn btn-delete">&times;</button> -->
          {{ role }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    components: {},
    props: {
      userData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        // broadcaster: 'global/getBroadcaster',
      }),
      profileImageURL() {
        return this.userData?.prunedUserData?.profileImageURL || "";
      },
    },
    created() {},
  };
</script>

<style lang="scss" scoped>
  .chat-user-profile-parent {
    padding: rem-calc(15);
  }

  .chat-user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: rem-calc(15);
    background: rgba(black, 0.4);
    border-radius: 10px;
    color: white;
    font-size: rem-calc(14);
  }

  .avatar {
    border-radius: 100%;
  }

  // ROLE BUTTON
  .btn-role {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: rem-calc(4 8);
    margin: 0 2px;
    border-radius: 20px;
    font-size: rem-calc(10);
    font-weight: 600;
    color: darken(white, 50%);
    background-color: rgba(black, 0.3);

    .btn-delete {
      background: aqua;
      color: white;
    }
  }
</style>