<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <div class="scrollbars-inner">
        <AdminPanelGridUnderlay />

        <div class="grid-btns">
          <AdminPanelGridButton
            v-for="(task, index) in hardcodedButtons"
            :icon="task.icon"
            :isActive="activeValues[index]"
            :key="task.uuid"
            :name="task.name"
            :style="{
              position: 'absolute',
              top: task.position[0] + 'px',
              left: task.position[1] + 'px',
            }"
            :title="task.title"
            @click="handleClick(task)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * WARNING: if this fucks up, delete the 'tasks' in Firebase console first - then troubleshoot.
   */
  import { mapGetters } from "vuex";
  import AdminPanelGridButton from "../../../AdminUI/AdminPanelGridButton.vue";
  import AdminPanelGridUnderlay from "../../../AdminUI/AdminPanelGridUnderlay.vue";
  import shortcuts from "./shortcuts";

  export default {
    components: {
      AdminPanelGridButton,
      AdminPanelGridUnderlay,
    },
    data() {
      return {
        hardcodedButtons: shortcuts,
      };
    },
    computed: {
      ...mapGetters({
        getAllTasks: "tasks/getAllTasks",
      }),
      activeValues() {
        return this.hardcodedButtons.map((button) => {
          const task = this.getAllTasks.find((item) => item.uuid === button.uuid);
          return task?.canceled === 0 || task?.canceled === false; // fuck it
          // return task?.canceled !== undefined && task?.canceled !== true; // also works?
        });
      },
    },
    methods: {
      handleClick(task = {}) {
        const newTask = { uuid: task.uuid, data: { arg: task.data?.arg || null } }; // sloppily copied from Debug
        this.$store.dispatch("tasks/runTasksRemotely", [newTask]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20 20 0 20);
    // border: 2px solid yellow;
  }

  .scrollbars-inner {
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  .grid-btns {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // border: 1px dashed red;
    z-index: 1;
  }
</style>
