<template>
  <div class="youtube-playlist-time-display-parent">
    <!-- playerProgress: {{ (playerProgress * 100).toFixed(2) }} / 100 <br /> -->

    <div class="px-2 py-1 mb-0 text h6">
      VD Elapsed: {{ totalElapsedActiveVideoMillisFormatted }}<span class="mx-2"></span><br />
      VD Remain -{{ totalRemainingActiveVideoMillisFormatted }} <br />

      PL Remain: {{ totalPlaylistMillisRemainingFormatted }} <span class="mx-2"></span>
      <br />
      PL Ends at: {{ playlistEndsAt }}<br />
      PL Total: {{ totalPlaylistMillisFormatted }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import momentDurationFormatSetup from "moment-duration-format";

  export default {
    components: {},
    props: {
      playerProgress: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        selectedTabName: "Playlists",
      };
    },
    computed: {
      ...mapGetters({
        getActiveVideo: "youtube/getActiveVideo",
        getActiveVideoIndex: "youtube/getActiveVideoIndex",
        getPlaylist: "youtube/getPlaylist",
      }),

      //-----------------------------------------------------------
      // ACTIVE VIDEO
      //-----------------------------------------------------------

      totalElapsedActiveVideoMillis() {
        const total = this.getActiveVideo?.durationMillis || 0;
        const perc = this.playerProgress * 100;
        const elapsedMillis = (total * perc) / 100;
        return elapsedMillis;
      },
      totalElapsedActiveVideoMillisFormatted() {
        const ms = this.totalElapsedActiveVideoMillis;
        const mo = moment
          .duration(ms)
          .format("d:hh:mm:ss")
          .padStart(4, "0:0");
        return mo;
      },
      totalRemainingActiveVideoMillis() {
        const total = this.getActiveVideo?.durationMillis || 0;
        const elapsed = this.totalElapsedActiveVideoMillis;
        const ms = total - elapsed;
        return ms;
      },
      totalRemainingActiveVideoMillisFormatted() {
        return moment
          .duration(this.totalRemainingActiveVideoMillis)
          .format("d:hh:mm:ss")
          .padStart(4, "0:0");
      },

      //-----------------------------------------------------------
      // PLAYLIST TOTAL
      //-----------------------------------------------------------

      totalPlaylistMillis() {
        return this.getPlaylist.reduce((count, video) => {
          if (!video.flagged && video.duration !== "LIVE" && video.durationMillis) {
            count += video.durationMillis;
          }
          return count;
        }, 0);
      },
      totalPlaylistMillisFormatted() {
        return moment
          .duration(this.totalPlaylistMillis)
          .format("d:hh:mm:ss")
          .padStart(4, "0:0");
      },

      //-----------------------------------------------------------
      // PLAYLIST REMAINING
      //-----------------------------------------------------------

      totalPlaylistMillisRemaining() {
        const futureMillis = this.getPlaylist.reduce((count, video, index) => {
          if (
            !video.flagged &&
            video.duration !== "LIVE" &&
            video.durationMillis &&
            index > this.getActiveVideoIndex
          ) {
            count += video.durationMillis;
          }
          return count;
        }, 0);

        return futureMillis + this.totalRemainingActiveVideoMillis;
      },

      totalPlaylistMillisRemainingFormatted() {
        return moment
          .duration(this.totalPlaylistMillisRemaining)
          .format("d:hh:mm:ss")
          .padStart(4, "0:0");
      },

      playlistEndsAt() {
        const currentTime = moment();
        const futureTime = moment(currentTime).add(
          this.totalPlaylistMillisRemaining,
          "milliseconds"
        );
        return futureTime.format("ddd DD yyyy - h:mm a");
      },
    },

    methods: {},
  };
</script>

<style lang="scss" scoped>
  .youtube-playlist-time-display-parent {
    display: flex;
    flex-direction: column;
    // position: relative;
    // height: 100%;
    // border: 2px solid red;
    // background: red;
    background-color: rgba(black, 0.4);
    margin: rem-calc(10);
    border-radius: 3px;
    color: #777;
    z-index: 10;
  }
</style>
