<template>
  <div class="admin-footer-parent">
    <div class="ml-auto mr-2">
      <DebugMenuIcons class="mr-3" />
      <button
        v-for="item in items"
        class="button"
        :class="{ 'is-active': item.isActive, [item.classes]: true }"
        :key="item.name"
        :title="item.name"
        @click="handleSelect(item)"
      >
        <i :class="item.icon"></i>
      </button>
    </div>

    <Clock />
  </div>
</template>

<script>
  import Clock from "@/components/MitchOS/UI/Clock.vue";
  import DebugMenuIcons from "@/components/MitchOS/Programs/Debug-v1/DebugMenuIcons.vue";

  export default {
    components: {
      Clock,
      DebugMenuIcons,
    },
    data() {
      return {
        items: [
          // {
          //   name: "Console",
          //   icon: "fa fa-wrench",
          // isActive: false,
          //   classes: "",
          // },
          {
            name: "Resize",
            classes: "",
            icon: "fa fa-arrows-h",
            isActive: false,
            isToggleable: true,
          },
          {
            name: "Light",
            classes: "",
            icon: "fa fa-lightbulb-o",
            isActive: false,
            isToggleable: true,
          },
          {
            name: "Popout in 1080p",
            classes: "",
            icon: "fa fa-window-restoreX fa-desktopX fa-external-link",
            isActive: false,
            clickEvent: this.onWindowResize,
          },
          {
            name: "Performance Mode",
            classes: "",
            icon: "fa fa-low-vision",
            isActive: false,
            // isToggleable: true,
          },
          {
            name: "Tasks Clearing",
            classes: "",
            icon: "fa fa-refresh",
            isActive: false,
            // isToggleable: true,
          },
          // {
          //   name: "Volume",
          //   classes: "",
          //   icon: "fa fa-volume-up",
          //   isActive: false,
          // },
        ],
      };
    },
    created() {
      this.$emit("on-selected", this.items);
    },
    methods: {
      handleSelect(item) {
        if (item.clickEvent) item.clickEvent();
        if (item.isToggleable) item.isActive = !item.isActive;
        this.$emit("on-selected", this.items);
      },
      onWindowResize() {
        window.open(
          "/all/",
          "targetWindow",
          "location=0,menubar=0,resizable=0,scrollbars=0,status=0,toolbar=0,width=1920,height=1080"
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-footer-parent {
    display: flex;
    align-items: center;
    height: 100%;
    padding: rem-calc(0 15);
    // height: rem-calc(90);
    // background: #28282f;
    // border-right: 1px solid rgba(black, 0.5);
    // box-shadow: -1px 0 1px yellow;
    // border-right: 2px solid #222;

    .button {
      // display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: rem-calc(35);
      border: 0;
      font-size: rem-calc(16);
      background: none;
      // color: #4a4a59;
      color: rgba(white, 0.3);
      // color: #636366
      z-index: 1;

      &.is-active {
        // color: rgba(white, 0.6);
        color: #d4d4d5;
        // text-shadow: 1px 2px 3px rgba($primary, 1);

        &:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 0px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          // border-radius: 3px;
          // border-left: 3px solid rgba($primary, 0.8);
          // background-color: rgba(black, 0.3);
          z-index: -1;
        }
      }

      &:hover:not(.is-active) {
        color: rgba(white, 0.2);
      }
    }

    .button .fa {
      display: block;
      text-shadow: inset 1px 1px 3px rgba(black, 0.2);
      // margin-bottom: rem-calc(4);
      text-shadow: 1px 1px 1px rgba(black, 0.3);
    }

    .button .text {
      position: relative;
      top: 4px;
      // display: none;
      font-size: rem-calc(10);
      font-weight: 600;
      // color: #777777;
    }
  }
</style>
