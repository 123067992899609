<template>
  <div
    class="admin-panels-console-parent"
    @mouseover="focusTextarea()"
  >
    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <AdminPanelHeaderTabs
        :tabs="tabs"
        :is-darker="true"
        @onSelect="onSelect($event)"
      />
    </AdminPanelHeader>

    <!-- PANEL BODY -->
    <div class="admin-panel-body">
      <AdminPanelConsole v-show="selectedTabName === 'Console'" />
      <AdminPanelActiveTasks v-if="selectedTabName === 'Active Tasks'" />
      <AdminPanelCooldowns v-if="selectedTabName === 'Cooldowns'" />
      <AdminPanelTaskLibrary v-if="selectedTabName === 'Legacy Debug'" />
      <AdminPanelLinks v-if="selectedTabName === 'Links'" />
    </div>

    <!-- PANEL FOOTER -->
    <div
      class="admin-panel-footer"
      v-click-outside="() => focusTextarea(false)"
    >
      <AdminPanelConsoleTextarea
        :is-background-transparent="selectedTabName !== 'Links'"
        :isFocused="isTextareaFocused"
      />
    </div>
  </div>
</template>

<script>
  import ClickOutside from "vue-click-outside";
  import AdminPanelHeader from "../AdminUI/AdminPanelHeader.vue";
  import AdminPanelHeaderTabs from "../AdminUI/AdminPanelHeaderTabs.vue";
  import AdminPanelConsole from "./Default/AdminPanelConsole/AdminPanelConsole.vue";
  import AdminPanelConsoleTextarea from "./Default/AdminPanelConsole/AdminPanelConsoleTextarea.vue";
  import AdminPanelCooldowns from "./Default/AdminPanelCooldowns/AdminPanelCooldowns.vue";
  import AdminPanelLinks from "./Default/AdminPanelLinks.vue";
  import AdminPanelActiveTasks from "./Default/AdminPanelActiveTasks/AdminPanelActiveTasks--draft-2.vue";
  import AdminPanelTaskLibrary from "./Default/AdminPanelTaskLibrary/AdminPanelTaskLibrary.vue";

  export default {
    components: {
      AdminPanelHeader,
      AdminPanelHeaderTabs,
      AdminPanelConsole,
      AdminPanelConsoleTextarea,
      AdminPanelCooldowns,
      AdminPanelLinks,
      AdminPanelActiveTasks,
      AdminPanelTaskLibrary,
    },
    directives: {
      ClickOutside,
    },
    data() {
      return {
        isTextareaFocused: true,
        selectedTabName: "Console",
        tabs: [
          {
            name: "Console",
            icon: "",
            isActive: true,
            classes: "is-darkest-active",
          },
          {
            name: "Active Tasks",
            icon: "",
            isActive: false,
            classes: "is-darkest-active",
          },
          {
            name: "Cooldowns",
            icon: "",
            isActive: false,
            classes: "is-darkest-active",
          },
          {
            name: "Legacy Debug",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Links",
            icon: "",
            isActive: false,
            classes: "has-no-right-border",
          },
          // {
          //   name: "Expand",
          //   icon: "fa fa-chevron-up",
          //   isActive: false,
          //   classes: "ml-auto has-no-right-border has-no-right-border",
          // },
          // {
          //   name: "Minimize",
          //   icon: "fa fa-minus",
          //   isActive: false,
          //   classes: "has-no-right-border has-no-right-border",
          // },
        ],
      };
    },
    methods: {
      focusTextarea(bool) {
        this.isTextareaFocused = bool;
      },
      onSelect(tab) {
        this.tabs.forEach((item) => (item.isActive = false));
        tab.isActive = true;
        this.selectedTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panels-console-parent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    // border: 1px solid yellow;
    pointer-events: none;
  }

  .admin-panel-body {
    position: relative;
    height: 100%;
    pointer-events: auto;
  }

  .admin-panel-footer {
    pointer-events: auto;
  }
</style>
