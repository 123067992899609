<template>
  <div class="debug-lights">
    <button
      v-for="scene in lightScenes"
      :key="scene"
      class="btn btn-primary"
      @click="handleScene(scene)"
    >
      <i class="fa fa-lightbulb-o mr-1" />
      {{ scene }}
    </button>
  </div>
</template>

<script>
  import lightsJSON from "./lights.json";

  export default {
    name: "DebugLights",
    data() {
      return {
        lightScenes: Object.keys(lightsJSON),
      };
    },
    methods: {
      handleScene(sceneName = "") {
        const sceneCode = this.getSceneCode(sceneName);

        navigator.clipboard
          .writeText(sceneCode)
          .then(() => console.log("Copied to clipboard."))
          .catch((err) => console.error("Unable to copy text to clipboard.", err.message));
      },

      /**
       * GET SCENE CODE
       */
      getSceneCode(sceneName = "") {
        const data = lightsJSON[sceneName];
        let outputString = "";

        Object.entries(data)
          .map(([key, value], index, array) => {
            const { hex, brightness, saturation, isExcluded } = value;

            if (isExcluded) {
              return "";
            }
            // const light = encodeURIComponent(key);
            // const light = key.replace(" ", "+");
            const light = key;
            let entryString = `open "neewerlite://toggleLight?light=${light}"`; // \n

            if (hex) {
              const hexFormatted = hex.replace("#", "");
              entryString += ` && open "neewerlite://setLightHSI?light=${light}&RGB=${hexFormatted}&Brightness=${brightness}&saturation=${saturation}"`;
            }

            if (index < array.length - 1) {
              entryString += " && "; // \n
            }

            return entryString;
          })
          .forEach((entry) => {
            outputString += entry;
          });

        // console.log(outputString);
        return outputString;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    background-color: grey;
    font-weight: bold;
    margin: 0 10px 10px 0;
  }
</style>
