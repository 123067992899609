<template>
  <div
    class="chat-messages-item-parent"
    :class="{
      'has-mentions': mentions.length,
      'has-user-mention': hasUserMention,
      'is-action': isAction,
      'is-ban-hovered': isBanHovered,
      'is-buttons-n-bows': dataObject.userstate.username === 'buttons_n_bows',
      'is-child': child,
      'is-filtered': isFiltered,
      'is-foreign': isForeignChannel,
      'is-role-broadcaster': isRoleBroadcaster,
      'is-role-mod': isRoleMod,
      'is-role-sub': isRoleSub,
      'is-role-vip': isRoleVIP,
      'is-selected': dataObject.selected,
      'is-striped': isStriped,
      'is-suspicious': isSuspicious,
      'is-timed-out': isTimedOut,
      'is-unread': dataObject.unread,
    }"
    @dblclick.prevent="onTimeoutUser(30)"
    @mouseover="onHover(true)"
    @mouseleave="onHover(false)"
    @mousedown.prevent=""
  >
    <!-- https://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click -->
    <!-- @dblclick="dataObject.selected = !dataObject.selected" -->

    <!-- CHAT MESSAGE -->
    <div class="chat-message">
      <!-- BTN REMOVE -->
      <button
        v-if="!child"
        class="btn btn-primary btn-remove"
        title="Ban user"
        @click="onBanUser()"
        @mouseover="isBanHovered = true"
        @mouseleave="isBanHovered = false"
      >
        <i class="fa fa-ban"></i>
      </button>

      <!-- AVATAR -->
      <ChatMessagesItemAvatar
        :path="avatar"
        :is-timed-out="isTimedOut"
        :suspicious="isSuspicious"
        :user-id="userId"
        :title="avatarHoverInfo"
      />

      <!-- CHAT MESSAGE BODY -->
      <div class="chat-message-body">
        <!-- (FOREIGN) CHANNEL -->
        <small v-if="isForeignChannel && !child" class="foreign-channel-label text-muted">
          <i class="fa fa-twitch"></i>&nbsp;
          {{ dataObject.channel }}
        </small>

        <!-- HEADER -->
        <!-- :style="!dataObject.isForeignChannel && { color:  dataObject.userstate.color }" -->
        <h4
          v-if="!child"
          class="chat-message-header mb-1"
          :style="
            isAdminScreen && !dataObject.isForeignChannel && { color: dataObject.userstate.color }
          "
        >
          <!-- TEXT -->
          <span class="mr-1">
            {{ displayName }}
          </span>

          <!-- BADGES -->
          <ChatMessagesItemBadges
            v-if="userBadges"
            :channel="dataObject.channel"
            :user-badges="userBadges"
            :user-level="userLevel"
          />
        </h4>

        <!-- BODY -->
        <ChatMessagesItemBody :data-object="dataObject" :hovered="isHovered" />

        <!-- TIMESTAMP -->
        <small v-if="isFiltered" class="chat-message-timestamp">
          <time>{{ createdAt }}</time>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  import { mapGetters } from "vuex";
  import ChatMessagesItemAvatar from "./ChatMessagesItemAvatar.vue";
  import ChatMessagesItemBadges from "./ChatMessagesItemBadges.vue";
  import ChatMessagesItemBody from "./ChatMessagesItemBody.vue";
  import twitchConfig from "../../../../config/twitch";

  export default {
    components: {
      ChatMessagesItemAvatar,
      ChatMessagesItemBadges,
      ChatMessagesItemBody,
    },
    props: {
      child: {
        type: Boolean,
        default: false,
      },
      dataObject: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      isFiltered: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        isAdminScreen: window.location.pathname.includes("/admin"),
        isBanHovered: null,
        isHovered: null,
        isStriped: null,
      };
    },
    computed: {
      ...mapGetters({
        broadcaster: "global/getBroadcaster",
        getUsername: "user/getUsername",
      }),
      accountAgeInDays() {
        const a = moment();
        const b = moment(this.dataObject.userstate.created_at);
        return parseInt(a.diff(b, "days"));
      },
      accountCreated() {
        return moment(this.dataObject.userstate.created_at).fromNow();
      },
      actionClasses() {
        return this.isAction && "animated pulse";
      },
      avatar() {
        return this.dataObject.profileImageURL || this.dataObject.userstate.profile_image_url; // indecisive
      },
      avatarHoverInfo() {
        return `account_age: ${this.accountAgeInDays} \ncreated: ${
          this.accountCreated
        } \nviews: ${this.dataObject.userstate.view_count || "n/a"} \ntype: ${this.dataObject
          .userstate.broadcaster_type || "user"} \ndesc: ${this.dataObject.userstate.description}`;
      },
      createdAt() {
        // https://stackoverflow.com/questions/52247445/how-do-i-convert-a-firestore-date-timestamp-to-a-js-date
        const seconds = this.dataObject.createdAt._seconds * 1000;
        const timeFormatted = moment(seconds).fromNow();
        return timeFormatted;
      },
      displayName() {
        return (
          this.dataObject.prunedUserData?.usernameAlias || this.dataObject.userstate["display-name"]
        );
      },
      hasUserMention() {
        return this.mentions.some((item) => item.toLowerCase() === `@${this.getUsername}`);
      },
      mentions() {
        // Use i flag, which will ignore the case-sensitiveness:
        // https://stackoverflow.com/questions/13554208/javascript-regex-match-any-word-that-starts-with-in-a-string
        return this.dataObject.message.match(/@\w+/gi) || []; // ['@themitchinghour', '@dave']
      },
      isAction() {
        return this.dataObject.userstate["message-type"] === "action";
      },
      isForeignChannel() {
        return this.dataObject.isForeignChannel;
      },
      isRoleBroadcaster() {
        return this.userBadges?.broadcaster;
      },
      isRoleMod() {
        return this.userBadges?.moderator || this.dataObject.userstate.mod;
      },
      isRoleSub() {
        return this.dataObject.userstate.subscriber;
      },
      isRoleVIP() {
        return this.userBadges?.vip;
      },
      isSuspicious() {
        return this.accountAgeInDays <= 2;
      },
      isTimedOut() {
        return this.dataObject.isTimedOut;
      },
      userBadges() {
        return this.dataObject.userstate.badges;
      },
      userId() {
        return this.dataObject.userstate["user-id"] || this.dataObject.userstate.user_id;
      },
      userLevel() {
        return this.dataObject.prunedUserData?.level;
      },
    },
    created() {
      // Cannot be computed, because index changes (deletes, bans etc).
      this.isStriped = this.index % 2 === 0;
    },
    methods: {
      onBanUser() {
        try {
          axios({
            url: twitchConfig.TWITCH_API.banUser,
            method: "POST",
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              userId: this.dataObject.userstate.userId || this.dataObject.userstate["user-id"],
              // moderatorId = ? who cares in v1, fallback to channelID
              // broadcasterId = ? ^ channelID
              reason: "Sold poison milk to school children. 🐦", // max 500 characters
            },
          });
        } catch (err) {
          console.log("Oops! Error:", err);
        }
      },

      onHover(bool) {
        this.isHovered = bool;
        if (bool) this.$emit("hover-username", this.dataObject.userstate.username);
      },

      onTimeoutUser(duration = 0, reason = "Bird behavior. Kaw kaw! 🐦") {
        // Can't timeout foreign or broadcaster.
        if (!this.isForeignChannel && !this.isRoleBroadcaster) {
          // Exceptions for VIPs and mods.
          if (this.isRoleVIP || this.isRoleMod) duration = 10;
          if (this.isSuspicious) duration = 300;

          try {
            axios({
              url: twitchConfig.TWITCH_API.banUser,
              method: "POST",
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                userId: this.dataObject.userstate.userId || this.dataObject.userstate["user-id"],
                // moderatorId = ? who cares in v1, fallback to channelID
                // broadcasterId = ? ^ channelID
                durationSeconds: duration, // (seconds) to ban a user indefinitely omit this field
                reason, // max 500 characters
              },
            });
          } catch (err) {
            console.log("Oops! Error:", err);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chat-messages-item-parent {
    position: relative;
    padding: rem-calc(4 0 4 10);
    color: white;
    overflow: hidden; // do we need this?
    cursor: default;

    &.is-striped {
      background-color: rgba(black, 0.1); // 0.05
      // background: grey;
    }

    &.has-user-mention {
      background-color: rgba(#faa618, 0.07);
      border-left: 3px solid #faa618;
    }

    // For when resuming after pause - the unread msg line.
    &.is-unread {
      &:before {
        content: "NEW";
        position: absolute;
        right: 0;
        bottom: 0px;
        width: 32px;
        border-radius: 2px 2px 2px 0;
        font-size: rem-calc(10);
        font-weight: bold;
        text-align: center;
        background: #f14846;
        overflow: hidden;
        animation: slow-disappear 30s linear 0s alternate 1 forwards;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: calc(100% - 42px);
        height: 1px;
        background-color: #f14846;
        animation: slow-disappear 30s linear 0s alternate 1 forwards;
      }
    }
  }

  @keyframes slow-disappear {
    100% {
      opacity: 0;
    }
  }

  //============================================
  // HOVERS
  //============================================

  .chat-messages-item-parent:not(.has-user-mention):hover {
    // background-color: transparent !important;
    border-left-color: transparent !important;
    background-color: rgba(black, 0.15);
  }

  .chat-messages-item-parent.has-user-mention:hover {
    background-color: rgba(#faa618, 0.1);
  }

  // .chat-message
  .chat-messages-item-parent:hover .chat-message {
    // background-color: rgba(white, 0.09) !important;
  }

  // .chat-message-avatar
  .chat-messages-item-parent:hover .chat-message-avatar {
    opacity: 1;
  }

  //============================================
  // CHAT MESSAGE
  //============================================

  .is-bottom-to-top .chat-messages-item-parent {
    max-height: 0;
    padding: rem-calc(0 0 0 10);

    animation: open-height 1s linear 0s alternate 1 forwards,
      open-padding 300ms ease 0s alternate 1 forwards;

    @keyframes open-height {
      100% {
        max-height: rem-calc(570);
      }
    }

    @keyframes open-padding {
      100% {
        padding: rem-calc(4 0 4 10);
      }
    }
  }

  //--------------------------------------------------------
  // CHAT MESSAGE
  //--------------------------------------------------------

  .chat-message {
    display: flex;
    padding: rem-calc(9 10);
    // border: 1px dashed orange;
    border-radius: 6px;
  }

  .is-child .chat-message {
    padding: rem-calc(0 10);
  }

  .chat-message-body {
    flex: 1;
  }

  .chat-message-timestamp {
    // display: block;
    // text-align: right;
    // background: pink;
    font-size: rem-calc(12);
    color: rgba(white, 0.5);
  }

  .has-mentions .chat-message {
    opacity: 0.4;
  }

  .has-user-mention .chat-message {
    opacity: 1;
  }

  // Todo: replace hardcoding with role, perhaps specialAccess?
  .has-user-mention.is-buttons-n-bows .chat-message {
    background-color: rgba(#ef81b4, 0.4) !important;
  }

  .is-selected .chat-message {
    background-color: rgba(#5bb771, 0.4) !important;
    // box-shadow: inset 0 0 1px 2px rgba(black, 0.3);
  }

  //--------------------------------------------------------
  // FOREIGN CHANNEL LABEL
  //--------------------------------------------------------

  .foreign-channel-label {
    display: block;
    margin-bottom: rem-calc(2);
    font-weight: bold;
  }

  //--------------------------------------------------------
  // CHAT MESSAGE HEADER
  //--------------------------------------------------------

  .chat-message-header {
    margin: 0;
    font-family: $font-family-sans-serif;
    font-size: rem-calc(19);
    font-weight: 600;
    color: #2fcc71; // green

    .badge {
      position: relative;
      top: -2px;
      // filter: brightness(60%);
      // filter: grayscale(100%) invert(1);
      // opacity: 0.5;
    }
  }

  .is-role-sub .chat-message-header {
    color: #e67e23;
  }

  .is-role-mod .chat-message-header,
  .is-role-mod .chat-message-header[style] {
    color: #9b59b6 !important;
  }

  .is-role-vip .chat-message-header,
  .is-role-vip .chat-message-header[style] {
    color: #ea7bc0 !important;
  }

  .is-role-broadcaster .chat-message-header,
  .is-role-broadcaster .chat-message-header[style] {
    color: #e0d55a !important;
  }

  .is-foreign .chat-message-header,
  .is-foreign .chat-message-header[style] {
    color: #28bee3 !important;
  }

  .has-mentions:not(.has-user-mention) .chat-message-header,
  .has-mentions:not(.has-user-mention) .chat-message-header[style] {
    // filter: grayscale(100%);
    color: grey !important;
  }

  .is-ban-hovered .chat-message-header,
  .is-ban-hovered .chat-message-header[style] {
    color: $danger !important;
    text-decoration: line-through;
  }

  //--------------------------------------------------------
  // BTN REMOVE
  //--------------------------------------------------------

  .btn-remove {
    visibility: hidden;
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(5);
    width: rem-calc(16);
    height: rem-calc(16);
    padding: 0;
    border-radius: 2px;
    background-color: rgba($red, 0.5) !important;
    font-size: rem-calc(10);
    line-height: rem-calc(16);
    color: rgba(white, 0.8);
    transition: none;
  }

  // Can't ban foreign or broadcaster.
  .is-role-broadcaster .btn-remove,
  .is-foreign .btn-remove {
    display: none;
  }

  .chat-message:hover .btn-remove {
    visibility: visible;
  }

  .btn-remove:hover,
  .btn-remove:active {
    opacity: 0.9;
    color: rgba(white, 0.5);
  }

  .btn-flag:hover {
    color: white;
  }
</style>
