<template>
  <div class="admin-panel-users-parent">
    <ChatUsers />
  </div>
</template>

<script>
  import ChatUsers from "@/components/MitchOS/Programs/Chat/ChatUsers.vue";

  export default {
    components: {
      ChatUsers,
    },
    created() {},
  };
</script>

<style lang="scss" scoped>
  .admin-panel-users-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 1px dashed green;
  }
</style>
