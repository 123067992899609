<template>
  <div class="youtube-playlist-queue-parent">
    <!-- PLAYLIST CURRENT -->
    <div class="playlist-current">
      <transition enter-active-class="animated fadeIn faster" mode="out-in">
        <!-- CURRENT ITEM -->
        <!-- :key="'currentVideo-'+activeVideoIndex" -->
        <YouTubePlaylistQueueItem
          v-if="activeVideo || getPlaylist[0]"
          :active="true"
          :current="true"
          :data-object="activeVideo || getPlaylist[0]"
          :index="activeVideoIndex"
          :key="'currentVideo-' + (activeVideo.id || getPlaylist[0].id)"
          :paused="playerPause"
          :playing="youTubePlayer"
          @player-progress="$emit('player-progress', $event)"
        >
        </YouTubePlaylistQueueItem>
      </transition>
    </div>

    <!-- DIVIDER -->
    <hr class="divider" />

    <!-- PLAYLIST QUEUE -->
    <div
      class="playlist-queue has-scrollbars"
      ref="playlist-queue"
      @mouseleave="scrollToActiveVideo()"
    >
      <!-- VUE DRAGGABLE -->
      <Draggable
        v-model="playlist"
        animation="0"
        ghost-class="drag-ghost"
        @start="onDragStart()"
        @end="onDragEnd()"
      >
        <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
          <!-- PLAYLIST ITEM -->
          <YouTubePlaylistQueueItem
            v-for="(item, index) in getPlaylist"
            :active="index === activeVideoIndex"
            :data-object="item"
            :index="index"
            :previous="index < activeVideoIndex"
            :key="item.id"
            :ref="item.id"
          >
          </YouTubePlaylistQueueItem>
        </transition-group>
      </Draggable>

      <!-- SCROLL PAD -->
      <div class="scroll-pad"></div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Draggable from "vuedraggable";
  import VueScrollTo from "vue-scrollto";
  import YouTubePlaylistQueueItem from "./YouTubePlaylistQueueItem.vue";

  export default {
    components: {
      Draggable,
      YouTubePlaylistQueueItem,
    },
    props: {
      showing: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        // debounce: false,
        dragging: false,
      };
    },
    computed: {
      ...mapGetters({
        activeVideo: "youtube/getActiveVideo",
        activeVideoIndex: "youtube/getActiveVideoIndex",
        getActiveTask: "tasks/getActiveTask",
        getPlaylist: "youtube/getPlaylist",
      }),
      playlist: {
        get() {
          return this.getPlaylist;
        },
        set(val) {
          this.$store.commit("youtube/SET_PLAYLIST", val);
        },
      },
      activeVideoEl() {
        return this.$refs[this.activeVideo?.id]?.[0]?.$el;
      },
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      playerLock() {
        return !!this.getActiveTask("youTubePlayer", "Lock"); // TODO: make this universal
      },
      playerPause() {
        return !!this.getActiveTask("youTubePlayer", "Pause");
      },
      playerSong() {
        return this.getActiveTask("youTubePlayer", "Song");
      },
      playlistAutoplay() {
        return !!this.getActiveTask("youTubePlaylistSettings", "Autoplay"); // would this prevent autoplay from cleanup?
      },
      playlistFlag() {
        return this.getActiveTask("youTubePlaylist", "Flag");
      },
      playlistNext() {
        return !!this.getActiveTask("youTubePlaylist", "Next");
      },
      playlistPlay() {
        return this.getActiveTask("youTubePlaylist", "Play");
      },
      playlistPlayNext() {
        return this.getActiveTask("youTubePlaylist", "Play Next");
      },
      playlistPrev() {
        return !!this.getActiveTask("youTubePlaylist", "Prev");
      },
      playlistRemove() {
        return this.getActiveTask("youTubePlaylist", "Remove");
      },
      playlistRepeat() {
        return !!this.getActiveTask("youTubePlaylist", "Repeat");
      },
      playlistShuffle() {
        return this.getActiveTask("youTubePlaylist", "Shuffle");
      },
      playlistSurf() {
        return this.getActiveTask("youTubePlaylist", "Surf");
      },
    },
    watch: {
      //------------------------------------------------------------
      // WATCH: PLAYLIST PLAY <index>
      //------------------------------------------------------------

      playlistPlay(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          // Cannot 'select' an already selected video (economic).
          if (newVal.data.arg > -1 && newVal.data.arg !== this.activeVideoIndex) {
            // Set the new video index.
            this.$store.dispatch("youtube/setActiveVideo", newVal.data.arg);

            // If flagged, skip to next until success, else scroll to vid.
            this.getPlaylist[newVal.data.arg].flagged
              ? this.goToAndPlay()
              : this.scrollToActiveVideo();
          }
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYLIST PLAY NEXT <index>
      //------------------------------------------------------------

      playlistPlayNext(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          if (newVal.data.arg) {
            const args = newVal.data.arg.split(" ");
            const fromIndex = (args[0] === undefined && undefined) || parseInt(args[0]) - 1;
            const toIndex = (args[1] === undefined && undefined) || parseInt(args[1]) - 1;

            this.$store.dispatch("youtube/setVideoIndex", {
              fromIndex, // +1 due to UI not beginning with zero.
              toIndex,
            });
          }
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYLIST NEXT
      // Unique case where the watcher issues a server write.
      // Reason: In event of error, 'next' will trigger, but play index will be
      // lost on refresh. Next now trigggers a new write of the selected index.
      // Using runTasks on the event ensures the discarded 'next' isn't recorded;
      // only the new active index.
      //------------------------------------------------------------

      playlistNext(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.goToAndPlay();
        }
      },

      playlistPrev(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.goToAndPlay();
        }
      },

      //------------------------------------------------------------
      // WATCH: FLAG <index,,,>
      //------------------------------------------------------------

      playlistFlag(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          // If '<index>' local flag is detected, the player is signalling data.
          const indicesToFlag = newVal.data.arg.toString().split(",");

          this.$store.dispatch("youtube/setFlag", {
            index: indicesToFlag,
          });
        }
      },

      //------------------------------------------------------------
      // WATCH: REMOVE <index,,,>
      //------------------------------------------------------------

      playlistRemove: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            // Arg must exist as <index> or '*' not '<index>' (default string).
            if (newVal.data.arg !== "<index>") {
              // If wildcard, clear all.
              if (newVal.data.arg === "*") {
                // 1. Hault YouTube program first and unload the component - kill associated 'youTubePlayer' tasks.
                // 2. Kill shuffle and all associated 'youTubePlaylist' tasks.
                this.$store.dispatch("tasks/runTasks", [
                  {
                    uuid: "a050ee28-c5a9-4e8c-a5c0-f1245449f7a3",
                    canceled: true,
                    componentData: { destroyed: true },
                  }, // kill player run
                  {
                    uuid: "e2736e43-e470-43f9-b135-d7adb13773a3",
                    canceled: true,
                    stackable: false,
                  }, // force cancel shuffle
                ]);

                // Clear playlist.
                this.$store.dispatch("youtube/clearPlaylist");
              }

              // Extract item(s) passed from task arg and dispatch array.
              else {
                const indicesToRemove = newVal.data.arg.toString().split(",");
                this.$store.dispatch("youtube/removeFromPlaylist", indicesToRemove);
              }
            }
          }
        },
      },

      //------------------------------------------------------------
      // WATCH: SHUFFLE
      // Use task id to seed a random order array clone.
      //------------------------------------------------------------

      playlistShuffle: {
        immediate: true,
        handler(newVal, oldVal) {
          // idea - if active index is 4, shuffle and place active back in previous spot

          if (newVal !== oldVal) {
            if (!!this.playlistShuffle) {
              // this.$nextTick(() => { // added without tests
              this.$store.dispatch("youtube/shufflePlaylist", {
                seed: this.playlistShuffle.id,
              });
              // })
            } else {
              // Clear the cloned playlist array; falls back to original.
              // this.$store.dispatch('youtube/clearPlaylist', 'playlistShuffled');
              // this.$store.dispatch('youtube/setActiveVideo', {
              // 	index: 0,
              // });
              // this.$store.dispatch('tasks/runTasks', [{
              // 	uuid: 'd378db75-f708-4684-9a7d-7b5fc065bd38', // play <index>
              // 	canceled: true,
              // }]);
            }
          }
        },
      },

      //------------------------------------------------------------
      // WATCH: PLAYER SONG - todo: if no playlist, response is nothing?
      //------------------------------------------------------------

      playerSong(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          const msg =
            "@${user} 📺 '" +
            this.activeVideo.title +
            "' 🔗 https://youtube.com/watch?v=" +
            this.activeVideo.playerVars.id +
            " 👤 Added by " +
            (this.activeVideo.author === "System" ? "Mitch" : this.activeVideo.author) +
            ".";

          console.log("ID man", this.activeVideo);

          // SAY MESSAGE
          // this.$store.dispatch('chatbot/sayMessage', {
          //     color: 'SpringGreen',
          //     message: msg,
          //     user: newVal.author
          // });
        }
      },

      //------------------------------------------------------------
      // WATCH: SURF (USER COMMAND)
      //------------------------------------------------------------

      playlistSurf(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          if (!this.playerLock) {
            // NEXT
            this.$store.dispatch("tasks/runTasks", [
              { uuid: "8d86f70a-21c1-4431-96f5-d2593ac5b07d" },
            ]);

            // SAY MESSAGE
            // this.$store.dispatch('chatbot/sayMessage', {
            //     color: 'SpringGreen',
            //     message: "${user} is channel surfing. 📺🏄",
            //     user: newVal.author
            // });
          } else {
            // SAY MESSAGE
            // this.$store.dispatch('chatbot/sayMessage', {
            //     color: 'Chocolate',
            //     message: "${user} Nup, the TV is locked! 📺🔒",
            //     user: newVal.author
            // });
          }
        }
      },

      //------------------------------------------------------------
      // WATCH: SHOWING
      //------------------------------------------------------------

      showing(newVal, oldVal) {
        if (!!newVal) {
          this.$nextTick(() => this.scrollToActiveVideo(1), 10);
        }
      },
    },

    //------------------------------------------------------------
    // MOUNTED
    //------------------------------------------------------------

    mounted() {
      // Scroll to active video instantly - also override activeIndex init (on refresh).
      this.$nextTick(() => {
        setTimeout(() => this.scrollToActiveVideo(1), 10);
      });

      // Launch player if Autoplay is selected.
      if (!this.youTubePlayer && !!this.playlistAutoplay) {
        this.$store.dispatch("tasks/runTasks", [
          { uuid: "a050ee28-c5a9-4e8c-a5c0-f1245449f7a3", canceled: false },
        ]); // force run player
      }
    },

    //------------------------------------------------------------
    // METHODS
    //------------------------------------------------------------

    methods: {
      //------------------------------------------------------------
      // ON DRAG START
      //------------------------------------------------------------

      onDragStart() {
        this.dragging = true;
      },

      //------------------------------------------------------------
      // ON DRAG END
      //------------------------------------------------------------

      onDragEnd() {
        this.dragging = false;
        this.updatePlaylist();
      },

      //------------------------------------------------------------
      // ON UPDATE PLAYLIST
      // Don't think we need a debounce, since requests could immediately update the playlist.
      // https://www.reddit.com/r/vuejs/comments/a4z62s/how_can_i_use_vuedraggable_and_save_the_order_in/
      //------------------------------------------------------------

      updatePlaylist() {
        this.$store.dispatch("youtube/updatePlaylist", this.playlist);
      },

      //------------------------------------------------------------
      // SCROLL TO ACTIVE VIDEO
      //------------------------------------------------------------

      scrollToActiveVideo(duration = 500) {
        VueScrollTo.scrollTo(this.activeVideoEl, duration, {
          cancelable: false,
          container: this.$refs["playlist-queue"],
          easing: "ease",
          force: true,
          offset: this.activeVideoEl?.clientHeight - 10,
          x: false,
          y: true,
        });
      },

      //------------------------------------------------------------
      // GO TO AND PLAY
      //------------------------------------------------------------

      goToAndPlay() {
        let index = this.activeVideoIndex + 1;

        // Go below zero and loop back around to last item.
        if (this.playlistPrev) {
          index =
            this.activeVideoIndex > 0
              ? this.activeVideoIndex - 1
              : !!this.playlistRepeat
              ? this.getPlaylist.length - 1
              : 0;
        }

        // Set next target index. Either zero or next sequential depending on 'repeat'.
        else if (this.playlistNext) {
          index =
            this.activeVideoIndex < this.getPlaylist.length - 1
              ? this.activeVideoIndex + 1
              : !!this.playlistRepeat
              ? 0
              : this.getPlaylist.length - 1;
        }

        // Cannot 'next' an already selected video through console.
        if (index !== this.activeVideoIndex) {
          // Set the video index.
          this.$store.dispatch("tasks/runTasks", [
            {
              uuid: "d378db75-f708-4684-9a7d-7b5fc065bd38", // play <index>
              data: {
                arg: index,
              },
            },
          ]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-playlist-queue-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  //-----------------------------------------------------------------
  // DIVIDER
  //-----------------------------------------------------------------

  .divider {
    margin: rem-calc(10 15 0 15);
    background-color: rgba(white, 0.2);
  }

  //-----------------------------------------------------------------
  // QUEUE
  //-----------------------------------------------------------------

  .playlist-queue {
    width: 100%;
    height: 100%;
    padding-top: rem-calc(10);
    // border: 1px dashed aqua;
  }

  .playlist-queue .scroll-pad {
    height: 100%;
  }

  //-----------------------------------------------------------------
  // DRAGGING
  //-----------------------------------------------------------------

  .drag-ghost {
    border-top: 2px solid $primary;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }
</style>
