<template>
  <MitchOS
    :default-tasks="defaultTasks"
    class="os-background"
  >
    <Admin />
  </MitchOS>
</template>

<script>
  import Admin from "@/components/MitchOS/Programs/Admin/Admin.vue";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";

  const manifest = composeManifest(
    [
      // './MitchOS/Programs/',
      "./fonts/",
      // './MitchOS/Transitions/' // works
      // './MitchOS/Transitions/assets/audio/'  doesn't work
    ],
    { ignore: [".mp3"] }
  );

  export default {
    name: "AdminView",
    components: {
      Admin,
      MitchOS,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        defaultTasks: [
          { uuid: "76e6f1e7-2d7b-4cc9-adfc-b73690c5e985" }, // Alerts
          { uuid: "559c502f-6dc2-47b4-b086-e27d7d44c68d" }, // MediaPoster
          { uuid: "cb061b62-0fea-4398-aa1d-5f747331a79d" }, // Soundboard
        ],
      };
    },
    /**
     * EMIT - REQUEST PRELOADER
     * Each view has its own set of assets to load
     */
    created() {
      this.$emit("request-preloader", {
        manifest,
      });
    },
  };
</script>

<style lang="scss" scoped>
  .os-background {
    // background: #28282f;
    background-color: #212126;
  }
</style>
