var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"shortkey",rawName:"v-shortkey.push",value:(['shift']),expression:"['shift']",modifiers:{"push":true}}],staticClass:"chat-messages-component",class:{
    'has-manual-scroll': !_vm.autoScroll,
    'is-bottom-to-top': !_vm.topToBottom,
  },on:{"shortkey":_vm.toggleUserMessageFilter,"mouseover":function($event){return _vm.handleHover(true)},"mouseleave":function($event){return _vm.handleHover(false)}}},[(!_vm.autoScroll)?_c('div',{staticClass:"chat-messages-autoscroll"},[_c('button',{staticClass:"btn btn-autoscroll",on:{"click":_vm.restartAutoscroll}},[(_vm.chatMessagesUnread.length)?_c('div',[(!_vm.topToBottom)?_c('i',{staticClass:"fa fa-angle-up mr-1"}):_vm._e(),(_vm.topToBottom)?_c('i',{staticClass:"fa fa-angle-down mr-1"}):_vm._e(),_vm._v(" New Messages "),_c('span',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.chatMessagesUnread.length)+" ")])]):_c('div',[_c('i',{staticClass:"fa fa-pause mr-1"}),_vm._v(" Chat Paused")])])]):_vm._e(),(
      _vm.chatGuide &&
        !_vm.filterUsernameToggle &&
        (_vm.chatMessagesVisible.length || _vm.chatMessagesUnread.length)
    )?_c('div',{staticClass:"chat-messages-guide"}):_vm._e(),_c('div',{ref:"chat-messages-scroller",staticClass:"chat-messages-scroller has-scrollbars has-scrollbars-on-hover",on:{"scroll":_vm.onScroll}},[_c('div',{ref:"chat-messages",staticClass:"chat-messages"},[_vm._l((_vm.chatDataOrdered),function(item,index){return _c('ChatMessagesItem',{key:item.userstate.id,ref:'el-' + item.userstate.id,refInFor:true,staticClass:"chat-messages-item",attrs:{"child":_vm.getChildStatus(item, index, _vm.chatDataOrdered),"data-object":item,"index":_vm.counter},on:{"hover-username":function($event){_vm.filterUsername = $event}}})}),(_vm.filterUsernameToggle && _vm.chatDataFiltered.length)?_c('div',{staticClass:"chat-messages-filtered has-scrollbars has-scrollbars-on-hover"},[(_vm.latestUserData)?_c('ChatUserProfile',{attrs:{"user-data":_vm.latestUserData}}):_vm._e(),_vm._l((_vm.chatDataFiltered),function(item,index){return _c('ChatMessagesItem',{key:'filtered-' + item.userstate.id,staticClass:"chat-messages-item",attrs:{"child":_vm.getChildStatus(item, index, _vm.chatDataFiltered),"data-object":item,"index":index,"is-filtered":true}})})],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }