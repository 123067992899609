<template>
  <div
    class="youtube-playlist-item"
    :class="{
      active: active,
      'has-errors': dataObject.error,
      'is-current': current,
      'is-flagged': flag,
      'is-previous': previous,
    }"
  >
    <!-- BG FILL -->
    <div class="bg-fill"></div>

    <!-- INDEX -->
    <div class="index">
      <!-- INDEX NUMBER (FOR BODY ITEMS) -->
      <span v-if="!flagError && !previous && (!active || !playing)">
        {{ index + 1 }}
      </span>

      <!-- PLAY/PAUSE ICON (FOR CURRENT) -->
      <i
        v-if="active && playing && current"
        class="play-marker"
        :class="paused ? 'fa fa-pause' : 'fa fa-play'"
      >
      </i>

      <!-- PREVIOUS ICON (TICK OR WARNING) -->
      <i
        v-if="!current && (previous || flagError)"
        class="play-marker"
        :class="flagError ? 'fa fa-exclamation-triangle' : 'fa fa-check'"
        :title="flagMeta"
      >
      </i>
    </div>

    <!-- THUMBNAIL -->
    <div class="thumbnail">
      <!-- DURATION TIME -->
      <div class="duration-time" :class="{ 'is-live': duration === 'LIVE' }">
        {{ duration }}
      </div>

      <!-- BTN TILE -->
      <a class="btn-tile" @click.prevent="playVideo">
        <!-- IMAGE -->
        <img src="../assets/img/thumb-sizer--320x180.svg" alt="Thumbnail" />

        <!-- ** YOUTUBE PLAYER ** -->
        <YouTubePlayer
          v-if="youTubePlayer && showYouTubePlayer"
          v-show="!paused"
          class="youtube-player animated fadeIn delay-5s"
          :options="{
            isMuted: true,
          }"
          @player-progress="onPlayerProgress($event)"
        />

        <!-- BTN TILE BG -->
        <span
          class="btn-tile-bg animated fadeIn faster"
          :style="{ 'background-image': 'url(' + dataObject.thumbnail + ')' }"
        >
        </span>

        <!-- DURATION BAR -->
        <div class="duration-bar">
          <div
            class="duration-bar-fill"
            :style="{ transform: 'scaleX(' + progressXScale.toFixed(2) + ')' }"
          ></div>
        </div>
      </a>
    </div>

    <!-- HEADING -->
    <div class="heading">
      <!-- TITLE -->
      <a
        :href="'https://youtube.com/watch?v=' + dataObject.playerVars.id"
        :title="dataObject.title"
        @click.prevent="playVideo"
      >
        <h4 class="title mb-1">
          {{ title }}
        </h4>
      </a>

      <!-- SUBTITLE (SENT BY) -->
      <div class="subtitle text-nowrap">
        <img
          v-if="dataObject.userData.profileImageURL"
          width="13"
          class="avatar"
          :src="dataObject.userData.profileImageURL"
          alt="Avatar"
        />
        <!-- Sent by -->
        <span class="author text-primary">
          {{ dataObject.author }}
        </span>
      </div>
    </div>

    <!-- BTN REMOVE -->
    <button v-show="!current" class="btn btn-primary btn-remove" @click="removeFromPlaylist">
      &times;
    </button>

    <!-- BTN PLAY NEXT -->
    <button
      v-show="!active"
      class="btn btn-primary btn-play-next"
      title="Play Next"
      @click="playVideoNext"
    >
      <i class="fa fa-sort-asc"></i>
    </button>

    <!-- BTN FLAG -->
    <button
      class="btn btn-primary btn-flag"
      :disabled="flagError"
      :title="flagMeta"
      @click="flagVideo"
    >
      <i class="fa fa-flag"></i>
    </button>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import YouTubePlayer from "@/components/MitchOS/Programs/YouTubePlayer/YouTubePlayer.vue";

  export default {
    components: {
      YouTubePlayer,
    },
    data() {
      return {
        progressXScale: 0,
      };
    },
    props: {
      active: {
        type: Boolean,
        required: true,
      },
      current: {
        // Currently playing visual (top).
        type: Boolean,
        default: false,
      },
      dataObject: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      paused: {
        type: Boolean,
        default: false,
      },
      playing: {
        type: Boolean,
        default: false,
      },
      previous: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getActiveTasks: "tasks/getActiveTasks",
      }),
      duration() {
        return this.dataObject.duration;
      },
      flag() {
        return this.dataObject.flagged;
      },
      flagError() {
        return this.flag && this.flag.type === "error";
      },
      flagMeta() {
        return this.flag && this.flag.meta;
      },
      title() {
        const len = 55;
        const str = this.dataObject.title;
        return str.length > len ? str.substring(0, len) + "..." : str;
      },
      showYouTubePlayer() {
        return this.current && window.location.pathname.includes("/admin");
      },
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
    },
    methods: {
      //------------------------------------------------------------
      // FLAG VIDEO
      //------------------------------------------------------------

      flagVideo() {
        this.$store.dispatch("youtube/setFlag", {
          index: this.index,
        });
      },

      //------------------------------------------------------------
      // PLAY VIDEO
      //------------------------------------------------------------

      playVideo() {
        if (!this.flagError) {
          if (!this.current) {
            // PLAY INDEX
            this.$store.dispatch("tasks/runTasksRemotely", [
              {
                uuid: "d378db75-f708-4684-9a7d-7b5fc065bd38",
                data: {
                  arg: this.index,
                },
              },
            ]); // play
          } else if (this.youTubePlayer) {
            // Toggle pause if clicking 'Now Playing' video.
            this.$store.dispatch("tasks/runTasksRemotely", [
              { uuid: "eb814b30-8e89-4cf0-9dc1-18bb26ab975d" },
            ]); // pause
          }
        }
      },

      //------------------------------------------------------------
      // PLAY VIDEO NEXT
      //------------------------------------------------------------

      playVideoNext() {
        this.$store.dispatch("youtube/setVideoIndex", {
          fromIndex: this.index,
        });
      },

      //------------------------------------------------------------
      // REMOVE FROM PLAYLIST
      //------------------------------------------------------------

      removeFromPlaylist() {
        this.$store.dispatch("youtube/removeFromPlaylist", this.index);
      },

      //------------------------------------------------------------
      // ON PLAYER PROGRESS
      //------------------------------------------------------------

      onPlayerProgress(event) {
        this.progressXScale = event;
        this.$emit("player-progress", event);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-playlist-item {
    position: relative; // [LOCKED]
    display: flex; // [LOCKED]
    height: rem-calc(83); // [LOCKED]
    max-height: rem-calc(83); // [LOCKED]
    padding: rem-calc(10 15);
    color: darken(white, 20%); // repeated below
    overflow: hidden;
    user-select: none;
    z-index: 1;
  }

  // Flag hover
  .youtube-playlist-item.is-flagged:not(.is-current):hover {
    box-shadow: inset 0 0 1px 1px rgba($danger, 0.4);
  }

  // Hide active item.
  // .youtube-playlist-item.active:not(.is-current) {
  // height: 0;
  // padding: 0;
  // opacity: 0.3;
  // }

  // All previous items.
  .youtube-playlist-item.is-previous:not(.is-current) {
    opacity: 0.3;
  }

  //--------------------------------------------------------
  // BG FILL
  //--------------------------------------------------------

  .youtube-playlist-item:not(.is-current) .bg-fill {
    position: absolute;
    top: 0;
    left: #{rem-calc(-15)};
    width: calc(100% + #{rem-calc(15)});
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }

  .youtube-playlist-item.is-flagged .bg-fill {
    background-color: rgba($danger, 0.15);
  }

  .youtube-playlist-item:hover > .bg-fill {
    background-color: rgba(white, 0.09);
  }

  .youtube-playlist-item.is-flagged:hover .bg-fill {
    background-color: rgba($danger, 0.2);
  }

  //--------------------------------------------------------
  // INDEX
  //--------------------------------------------------------

  .youtube-playlist-item .index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(45);
    max-width: rem-calc(45);
    margin-left: rem-calc(-10);
    font-family: $headings-font-family;
    font-size: rem-calc(12);
    font-weight: 600;
    // background: #4e75b8;
  }

  .youtube-playlist-item .index .play-marker {
    color: white;
    text-shadow: 0 0px 2px rgba(white, 0.5);
  }

  // Error indicator
  .youtube-playlist-item .index .fa-exclamation-triangle {
    color: $danger;
    text-shadow: none;
  }

  // Tick
  .youtube-playlist-item .index .fa-check {
    color: #9af00a;
  }

  //--------------------------------------------------------
  // THUMBNAIL
  //--------------------------------------------------------

  .youtube-playlist-item .thumbnail {
    position: relative;
  }

  .btn-tile {
    border-radius: 3px;
    // overflow: hidden;
    cursor: pointer;
  }

  .youtube-playlist-item .thumbnail img {
    width: rem-calc(112); // [LOCKED] - precise
  }

  //--------------------------------------------------------
  // THUMBNAIL PLAYER
  //--------------------------------------------------------

  .youtube-playlist-item .youtube-player {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(2);
    z-index: 1;
  }

  //--------------------------------------------------------
  // DURATION TIME
  //--------------------------------------------------------

  .youtube-playlist-item .duration-time {
    position: absolute;
    bottom: 3px;
    right: 3px;
    padding: 1px 4px 0px 4px;
    border-radius: 2px;
    background: rgba(black, 0.8);
    font-size: rem-calc(10);
    font-weight: 600;
    z-index: 2;

    &.is-live {
      color: #cc0000;
      text-shadow: 1px 1px 5px #cc0000;
      // background: rgba(#cc0000, 0.8);
    }
  }

  .youtube-playlist-item .duration-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    // background-color: rgba(white, 0.1);
    z-index: 2;

    .duration-bar-fill {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary; //#ff0000; // youtube red
      transform-origin: left;
      transition: transform 50ms linear;
    }
  }

  //--------------------------------------------------------
  // BUTTON TILE
  //--------------------------------------------------------

  .btn-tile {
    background-color: rgba(white, 0.1);
  }

  .btn-tile:hover .btn-tile-bg {
    transform: none;
  }

  //--------------------------------------------------------
  // HEADING
  //--------------------------------------------------------

  .youtube-playlist-item .heading {
    width: 100%; // [LOCKED]
    padding: rem-calc(0 10 0 15);
    font-weight: 600;
    line-height: 1.2;
  }

  .youtube-playlist-item .heading h4.title {
    max-height: rem-calc(38);
    overflow: hidden;
    // background: orange;
    font-family: $font-family-sans-serif;
    font-size: rem-calc(15);
    font-weight: 600;
    color: darken(white, 20%);
  }

  .youtube-playlist-item:not(.is-current):hover .heading h4.title {
    color: white;
  }

  .youtube-playlist-item .heading .subtitle {
    font-size: rem-calc(12);
    color: grey;
  }

  .youtube-playlist-item .avatar {
    position: relative;
    top: -1px;
    border-radius: 100%;
    margin-right: rem-calc(2);
    opacity: 0.5;
  }

  //--------------------------------------------------------
  // BTN REMOVE
  //--------------------------------------------------------

  .btn-remove,
  .btn-flag,
  .btn-play-next {
    visibility: hidden;
    position: absolute;
    top: rem-calc(5);
    right: rem-calc(5);
    width: rem-calc(16);
    height: rem-calc(16);
    padding: 0;
    border-radius: 2px;
    background-color: rgba($red, 0.5);
    line-height: rem-calc(16);
    color: white;
  }

  .btn-flag {
    top: auto;
    bottom: rem-calc(5);
    background: none;
    color: grey;

    .fa {
      position: relative;
      top: -2px;
      font-size: rem-calc(14);
    }
  }

  .btn-play-next {
    top: rem-calc(36);
    background: none;
    color: grey;
    transition: none;
  }

  .is-flagged .btn-flag {
    visibility: visible;
    color: $danger;
  }

  .youtube-playlist-item:hover .btn-remove,
  .youtube-playlist-item:hover .btn-flag,
  .youtube-playlist-item:hover .btn-play-next {
    visibility: visible;
  }

  .btn-remove:hover {
    opacity: 0.9;
  }

  .btn-flag:hover,
  .btn-play-next:hover {
    color: white;
  }
</style>
