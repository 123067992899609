<template>
  <div class="panel-dashboard-parent">

    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <AdminPanelHeaderTabs
        :tabs="tabs"
        @onSelect="onSelect($event)"
      />
    </AdminPanelHeader>

    <keep-alive>
      <AdminPanelPlaylists
        v-show="selectedTabName === 'Playlists'"
        class="panel-parent"
      />
    </keep-alive>

    <AdminPanelFollowTitles
      v-if="selectedTabName === 'Follow Titles'"
      class="panel-parent"
    />
  </div>
</template>

<script>
  /**
   * NOTES:
   * Vue tabs: https://medium.com/notonlycss/how-to-create-a-tab-component-in-vue-js-2812c75807f9
   * https://codesandbox.io/s/vue-js-tabs-d0zn6?file=/src/App.vue
   */

  import AdminPanelHeader from "../AdminUI/AdminPanelHeader.vue";
  import AdminPanelHeaderTabs from "../AdminUI/AdminPanelHeaderTabs.vue";
  import AdminPanelFollowTitles from "./Default/AdminPanelFollowTitles.vue";
  import AdminPanelPlaylists from "./Default/AdminPanelPlaylists/AdminPanelPlaylists.vue";

  export default {
    components: {
      AdminPanelFollowTitles,
      AdminPanelHeader,
      AdminPanelHeaderTabs,
      AdminPanelPlaylists,
    },
    data() {
      return {
        selectedTabName: "Playlists",
        tabs: [
          {
            name: "Playlists",
            icon: "fa fa-youtube-play",
            isActive: true,
            classes: "",
          },
          // {
          //   name: "Twitch",
          //   icon: "fa fa-twitch",
          //   isActive: false,
          //   classes: "",
          // },
          // {
          //   name: "Posters",
          //   icon: "fa fa-ticket",
          //   isActive: false,
          //   classes: "",
          // },
          // {
          //   name: "Alerts",
          //   icon: "fa fa-warning",
          //   isActive: false,
          //   classes: "",
          // },
          {
            name: "Follow Titles",
            icon: "fa fa-text-width",
            isActive: false,
            classes: "has-no-right-border",
          },
          // {
          //   name: "Hype",
          //   icon: "fa fa-rocket",
          //   isActive: false,
          //   classes: "has-no-right-border",
          // },
        ],
      };
    },
    methods: {
      onSelect(tab) {
        this.tabs.forEach((item) => (item.isActive = false));
        tab.isActive = true;
        this.selectedTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-dashboard-parent {
    position: relative;
    height: 100%;
    // border: 2px dashed orange;
    // background: red;
  }

  .panel-parent {
    // position: relative;
    // top: rem-calc(35);
    height: calc(100% - #{rem-calc(35)});
    // padding-top: 0 !important;
  }
</style>
