<template>
  <div
    class="debug-window-task-parent"
    :title="`command: ${node.commands} \n group: ${node.group} \n value: ${node.data.value} \n id: ${node.id} \n fbId: ${node.fbId} \n uuid: ${node.uuid}`"
    :class="classStyles"
    @click.stop="actionTask"
  >
    <!-- BG FILL -->
    <div class="debug-window-task-bg-fill"></div>

    <!-- FG FILL -->
    <div
      v-if="node.duration && mode === 'Active Tasks'"
      class="debug-window-task-fg-fill"
      :style="FGFillStyles"
    ></div>

    <!-- TASK STATUS -->
    <span
      v-if="mode === 'Active Tasks'"
      class="task-status"
    >
    </span>

    <!-- TASK ICON -->
    <i
      v-if="node.icon"
      :class="'mr-2 ' + node.icon"
    >
    </i>

    <!-- TASK GROUP -->
    <div class="task-group">
      {{ node.group }}
    </div>

    <div
      v-if="node.componentData.path"
      class="task-group text-primary"
    >
      {{ node.componentData.progressPerc }}%
    </div>

    <!-- TASK DATA VALUE -->
    <div class="task-data">
      {{ node.data && node.data.value }}
      <span v-if="node.sleeping">(ZZzzz)</span>
    </div>

    <!-- ID -->
    <!-- <div class="id-data">
			&nbsp;| id: {{ node.fbId }}
		</div> -->

    <!-- TASK DATA ARG -->
    <div class="task-data-arg">
      {{ node.data && node.data.arg }}
    </div>

    <!-- AUTHOR -->
    <div
      class="task-author"
      v-if="node.author && mode === 'Active Tasks'"
    >
      <i class="fa fa-user"></i>&nbsp;
      {{ node.author }}
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    props: {
      mode: {
        // view mode
        type: String,
        required: true,
      },
      node: {
        type: Object,
        required: true,
      },
      canceled: {
        type: [Boolean, Date, Number],
      },
      scene: {
        type: Boolean,
      },
    },
    computed: {
      classStyles() {
        return {
          "is-canceling": this.node.duration > 0 && !this.node.sleeping && !this.node.paused,
          "is-canceled": this.canceled,
          "is-scene": this.scene,
          "is-media-poster":
            this.node.group === "mediaPoster" && this.node.data.value === "Request",
        };
      },
      FGFillStyles() {
        return {
          "animation-duration": this.node.duration + "ms",
        };
      },
    },
    methods: {
      actionTask() {
        if (this.mode === "Tasks Library") {
          this.$store.dispatch("tasks/runTasksRemotely", [
            {
              uuid: this.node.uuid,
              author: "TheMitchingHour",
            },
          ]);
        } else {
          const task = {
            uuid: this.node.uuid,
            author: "TheMitchingHour",
            canceled: true, //this.node.componentData.queued ? false : true,
            // cancelById: true,
            data: this.node.data,
            componentData: {
              destroyed: true,
            },
          };

          // cheap fix?
          this.$store.dispatch("tasks/runTasksRemotely", [task]);

          // this.$store.dispatch('tasks/cancelTasksById', [this.node.id]);

          // this DOESN't work - you need to send a ticket to cancel across computers
          // this.$store.dispatch('tasks/cancelTasks', [this.node]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------
  // TASK
  //--------------------------------------------------------

  .debug-window-task-parent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: rem-calc(5 10);
    border-radius: 2px;
    margin-bottom: 3px;
    color: darken(white, 20%);
    user-select: none;
    overflow: hidden;
    z-index: 1;

    &:hover {
      opacity: 0.9;
    }
  }

  //--------------------------------------------------------
  // BG FILL
  //--------------------------------------------------------

  .debug-window-task-bg-fill,
  .debug-window-task-fg-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.08);
    transform-origin: left;
    z-index: -1;
  }

  .debug-window-task-fg-fill {
    transform: scaleX(0);
  }

  // is scene
  .is-scene.debug-window-task-parent {
    color: white;

    .debug-window-task-bg-fill {
      background: rgba(#41b883, 0.3);
    }
  }

  // scene - about to leave
  .is-scene.debug-window-task-parent.is-canceled > .debug-window-task-bg-fill {
    background: rgba(#41b883, 0.1);
  }

  // media poster
  .is-media-poster.debug-window-task-parent {
    color: white;
    padding: rem-calc(10 10);
    cursor: pointer;
    // font-size: rem-calc(14);

    .debug-window-task-bg-fill {
      background: rgba(#e58a1b, 0.3);
    }
  }

  // mediaposter - fade scenes about to leave
  .is-media-poster.debug-window-task-parent.is-canceled > .debug-window-task-bg-fill {
    background: rgba(darken(#e58a1b, 10%), 0.1);
  }

  .is-media-poster.is-canceling.debug-window-task-parent .debug-window-task-fg-fill {
    background-color: rgba(#e58a1b, 0.2);
  }

  // come back to this - 30.07.19

  .is-canceling.debug-window-task-parent .debug-window-task-fg-fill {
    background-color: lighten(#19191a, 10%); //#331312;
    animation: canceling 100ms ease-out 0ms;
    transition: 500ms ease-out 0s;

    @keyframes canceling {
      0% {
        transform: scaleX(0);
      }
      100% {
        transform: scaleX(1);
      }
    }
  }

  .is-canceled.debug-window-task-parent .debug-window-task-fg-fill {
    transform: scaleX(1);
    animation: none;
  }

  .is-canceled.debug-window-task-parent {
    color: rgba(#b61313, 0.9);

    .debug-window-task-fg-fill {
      background-color: #331312; //rgba(#ea423e, 0.2);
    }
  }

  //--------------------------------------------------------
  // STATUS LIGHT
  //--------------------------------------------------------

  .task-status {
    width: 7px;
    height: 7px;
    margin-right: 10px;
    background: #82ebe4;
    border-radius: 100%;
  }

  .is-canceled .task-status {
    background: red;
  }

  //--------------------------------------------------------
  // TASK GROUP
  //--------------------------------------------------------

  .task-group {
    margin-right: 15px;
    font-weight: bold;
  }

  .task-data {
    opacity: 0.5;
    // font-style: italic;
  }

  .task-data-arg {
    margin-left: 5px;
    opacity: 0.5;
    color: #aefa40;
  }

  .task-author {
    margin-left: auto;
    font-style: italic;
  }

  //--------------------------------------------------------
  //
  //--------------------------------------------------------
  //--------------------------------------------------------
  //
  //--------------------------------------------------------
</style>
