<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <DebugLights />
    </div>
  </div>
</template>

<script>
  // import OBSControl from "../../../OBSControl/OBSControl.vue";
  import DebugLights from "../../../Debug-v1/DebugLights.vue";

  export default {
    components: {
      DebugLights,
      // OBSControl,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20);
  }

  .has-scrollbars a {
    display: block;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  textarea::placeholder {
    color: #0df293;
  }
</style>
