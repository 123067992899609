<template>
  <div
    :class="{ 'is-admin': isAdmin }"
    class="debug-window-parent shadow-lg"
  >
    <!-- TITLEBAR -->
    <DebugWindowTitlebar
      v-if="!isAdmin"
      :title="activeModeName"
      :modes="modes"
      @close-window="$emit('close-window')"
      @update-index="activeMode = $event"
    />

    <!-- BODY -->
    <div class="debug-window-body has-scrollbars">
      <!-- ACTIVE TASKS -->
      <transition-group
        v-if="activeModeName === 'Active Tasks'"
        enter-active-class="animated zoomInLeft faster"
        leave-active-class="squeezeOut"
      >
        <!-- TASK -->
        <DebugWindowTask
          v-for="item in tasksReversed"
          v-show="!item.canceled"
          :key="initMode + '-execute-' + item.group + '-' + item.id"
          :mode="activeModeName"
          :node="item"
          :canceled="item.canceled"
          :scene="item.group == 'scene'"
        />
      </transition-group>

      <!-- ACTIVE TASKS JSON -->
      <pre v-if="activeModeName === 'Active Tasks JSON'">
        tasks: <code v-text="getAllTasks"></code>
      </pre>

      <!-- EXECUTE TASKS -->
      <div v-if="activeModeName === 'Tasks Library'">
        <!-- TASK -->
        <DebugWindowTask
          v-for="(item, index) in tasksLibrary"
          :key="initMode + '-library-' + index"
          :mode="activeModeName"
          :node="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import DebugWindowTask from "./DebugWindowTask.vue";
  import DebugWindowTitlebar from "./DebugWindowTitlebar.vue";

  export default {
    components: {
      DebugWindowTask,
      DebugWindowTitlebar,
    },
    props: {
      initMode: {
        type: [Number, String],
        required: false,
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeMode: 0,
        modes: [
          { name: "Active Tasks" },
          { name: "Active Tasks JSON" },
          { name: "Tasks Library" }, //
        ],
      };
    },
    computed: {
      ...mapGetters({
        getAllTasks: "tasks/getAllTasks",
        tasksLibrary: "tasks/getTasksLibrary",
      }),
      activeModeName() {
        return this.modes[this.activeMode].name;
      },
      tasksReversed() {
        return this.getAllTasks.slice().reverse();
      },
    },
    created() {
      this.activeMode = this.initMode || 0;
    },
  };
</script>

<style lang="scss" scoped>
  .squeezeOut {
    animation: squeeze-out 80ms 2s ease-out;

    @keyframes squeeze-out {
      100% {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  //--------------------------------------------------------
  // DEBUG WINDOW
  //--------------------------------------------------------

  .debug-window-parent {
    position: relative;
  }

  .debug-window-body {
    position: relative;
    // width: 400px;
    // height: 320px;
    // min-width: 400px;
    min-height: 200px;
    height: rem-calc(400);
    padding: 10px;
    // margin: 30px 0 0 0;
    // font-family: 'Source Code Pro';
    font-size: 12px;
    font-weight: 600;
    background: rgba(black, 0.9);
    // resize: both;
    resize: vertical;
  }

  .is-admin .debug-window-body {
    height: 100%;
    background: none;
    resize: none;
  }

  .debug-window-body ul {
    list-style: none;
    padding-left: 0;
  }

  pre {
    width: 300px;
    font-family: "Source Code Pro", "Andale Mono", monospace;
    // font-size: 13px;
    // font-weight: 600;
    // white-space: pre;
    // white-space: pre-wrap;
    color: #28fe14;
  }
</style>
