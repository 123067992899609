<template>
	<div class="youtube-playlist-presets-parent has-scrollbars">

		<!-- LIST MASONRY -->
		<!-- @click="onCreatePlaylist(item.name, item.url)" -->
		<ul class="list-unstyled list-masonry">
			<li v-for="(item, index) in playlistsFiltered">
				<button
					class="btn btn-preset"
					@click="onSelectPlaylist(item.name)"
				>
					<div>
						<h5 class="mb-2">{{ item.name }}</h5>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing.</p> -->
						<p class="font-weight-bold mb-0"><span class="text-primary">!</span>play{{index}}</p>
					</div>

				</button>
			</li>
		</ul>

	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import heroku from "@/config/heroku.js";

	export default {
		data() {
			return {
				loading: null,
				loadingTimeoutInstance: null,

			}
		},
		computed: {
			...mapGetters({
				// getAllTasks: 'tasks/getAllTasks',
				// getActiveTask: 'tasks/getActiveTask',
				// getPlaylist: 'youtube/getPlaylist',
				getPlaylistPresets: 'youtube/getPlaylistPresets'
			}),
			playlistsFiltered() {
				return this.getPlaylistPresets.filter(playlist => playlist.isPublic);
			}
		},
		methods: {
			onSelectPlaylist(name) {
				this.$store.commit("youtube/SET_PLAYLIST_SELECTED", name);
			}

			/*
			async onCreatePlaylist(name, url) {
				// this.loading = true;

				// clearTimeout(this.loadingTimeoutInstance);
				// this.loadingTimeoutInstance = setTimeout(() => this.loading = false, 4000);

				const path = `${heroku.HEROKU_API.createPlaylist}?name=${name}&url=${url}&isRandom=true`;
				const result = await fetch(path, {
					credentials: "include"
				}).then(data => data.json());

				console.log('result:', result)
			},
			*/
		}
	}
</script>

<style lang="scss" scoped>

	.youtube-playlist-presets-parent {
		display: flex;
		flex-direction: column;
		// height: 100%;
		padding: rem-calc(15);
		overflow: auto;
	}

	//-----------------------------------------------------------------
	// BUTTONS
	//-----------------------------------------------------------------

	.btn-preset {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: rem-calc(91);
		padding: rem-calc(15);
		background: rgba(lighten(#363840, 30%), 0.2);
		border-radius: 5px;
		color: white;

		margin-bottom: rem-calc(10);

		p {
			font-size: rem-calc(12);
			line-height: 1;
		}

		&:hover {
			background: rgba(lighten(#363840, 20%), 0.5);
		}
	}

	//-----------------------------------------------------------------
	// LIST MASONRY
	// https://stackoverflow.com/questions/48214281/why-does-grid-gap-cause-an-overflow
	//-----------------------------------------------------------------

	.list-masonry {
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// grid-auto-rows: 80px;
		// grid-gap: rem-calc(10);



		// display: grid;
		// grid-template-columns: 50% 50%;
		// grid-gap: rem-calc(15);
		// color: #444;
		// list-style: none;
		// padding: 0;
		// margin: 0;
		// width: 100%;
	}

</style>