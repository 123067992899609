<template>
  <header class="panel-header-parent">

    <slot />

    <!-- TITLE -->
    <div
      v-if="title"
      class="text-title"
    >
      {{ title }}
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: false,
      },
    },
    data() {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  .panel-header-parent {
    display: flex;
    // height: rem-calc(35);
    // max-height: rem-calc(35);
    border-bottom: 1px solid rgba(black, 0.5);
    background: #212126 linear-gradient(to bottom, #212126 0%, darken(#212126, 1%) 100%);
    // background: darken(#212126, 1%);
  }

  .text-title {
    width: 100%;
    font-size: rem-calc(11.5);
    font-weight: 600;
    color: #d4d4d5;
    text-transform: uppercase;
    line-height: rem-calc(34);
    text-align: center;
  }
</style>
