<script>
  import { mapGetters } from "vuex";
  import obs, { getActiveSceneAndAllSources, sendCommand } from "./obs";
  import obsConfig from "../../../../../../../config/obs";
  import AdminPanelGridButton from "../../../AdminUI/AdminPanelGridButton.vue";

  export default {
    components: {
      AdminPanelGridButton,
    },
    computed: {
      ...mapGetters({
        getInputByName: "obs/getInputByName",
        getSourceByName: "obs/getSourceByName",
        getSourcesBySceneName: "obs/getSourcesBySceneName",
      }),
      characterBorisSources() {
        return this.getSourcesBySceneName(obsConfig.SCENES.CHARACTER_BORIS)?.slice(1) || [];
      },
      characterTerrySources() {
        return this.getSourcesBySceneName(obsConfig.SCENES.CHARACTER_TERRY)?.slice(1) || [];
      },
      isBlurActive() {
        // Experimental: it works in overly elaborate way but slow due to the way
        // OBS plugin handles the filter transitions.
        const blurSource = this.getSourceByName("scn_grp: Boris", "scn: MAIN_CONTROLLER");
        const primaryFilter = blurSource?.filters?.[0];
        const enterFilter = blurSource?.filters?.[1]; // Assuming order in OBS is setup correctly.
        // const leaveFilter = blurSource?.filters?.[2]; // Assuming order in OBS is setup correctly.

        const isEntering =
          (enterFilter?.filterEnabled === true && !primaryFilter?.filterSettings?.radius) ||
          !!primaryFilter?.filterSettings?.radius;

        // const isLeaving =
        //   (leaveFilter?.filterEnabled === true && !!primaryFilter?.filterSettings?.radius) ||
        //   !primaryFilter?.filterSettings?.radius;

        return isEntering;
      },
    },
    created() {},
    methods: {
      /**
       * HANDLE CLICK
       */
      async handleClick() {
        // Populate store when OBS connects, then sources update on listener thereafter.
        const { activeSceneName, sources } = await getActiveSceneAndAllSources();

        this.$store.commit("obs/SET_STATE", { activeSceneName, sources });
      },

      /**
       * HANDLE TOGGLE BLUR FILTER
       * https://github.com/obsproject/obs-websocket/blob/master/docs/generated/protocol.md#setsourcefilterenabled
       */
      async handleToggleBlurFilter() {
        const sourceFilter = await sendCommand("GetSourceFilter", {
          sourceName: "scn_grp: Boris",
          filterName: "Composite Blur",
        });

        const isBlurred = sourceFilter?.filterSettings?.radius; // 'Blur Radius' property in OBS filter effect.
        // Event eg: 'SourceFilterEnableStateChanged'

        sendCommand("SetSourceFilterEnabled", {
          sourceName: "scn_grp: Boris",
          filterName: `Composite Blur ${isBlurred ? "Leave" : "Enter"}`,
          filterEnabled: true,
        });

        // testing...
        // const sourceFilterList = await sendCommand("GetSourceFilterList", {
        //   sourceName: "scn_grp: Boris",
        // });
        // console.log({ sourceFilterList });
      },

      /**
       * HANDLE TOGGLE INPUT MUTE
       */
      handleToggleInputMute(inputName = "") {
        sendCommand("ToggleInputMute", {
          inputName,
        });
      },

      /**
       * HANDLE TOGGLE XBOX PIP
       */
      handleToggleSource(sourceName = "", sceneName = "") {
        const { parentSceneName, id, isActive } = this.getSourceByName(sourceName, sceneName);

        sendCommand("SetSceneItemEnabled", {
          sceneName: parentSceneName,
          sceneItemId: id,
          sceneItemEnabled: !isActive,
        });
      },
    },
  };
</script>

<template>
  <div class="admin-panel-obs-controls-parent">
    <div class="grid-btns">
      <AdminPanelGridButton
        name="Toggle Blur Filter"
        :isActive="isBlurActive"
        :icon="['fa fa-toggle-off', 'fa fa-toggle-on']"
        @click="handleToggleBlurFilter"
      />

      <AdminPanelGridButton
        name="NTG3 Mute"
        :isActive="getInputByName('Audio: Rode NTG3').isMuted"
        icon="fa fa-volume-off"
        @click="handleToggleInputMute('Audio: Rode NTG3')"
      />

      <AdminPanelGridButton
        name="Xbox Mute"
        :isActive="getInputByName('Audio: Xbox').isMuted"
        icon="fa fa-volume-off"
        @click="handleToggleInputMute('Audio: Xbox')"
      />

      <AdminPanelGridButton
        name="Xbox PiP"
        :isActive="getSourceByName('scn_grp: PiP', 'scn_grp: Cameras').isActive"
        @click="handleToggleSource('scn_grp: PiP', 'scn_grp: Cameras')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .admin-panel-obs-controls-parent {
    // border: 1px dashed grey;
  }

  .grid-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // border: 1px dashed red;
    z-index: 1;
  }
</style>
