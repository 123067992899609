var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-messages-item-parent",class:{
    'has-mentions': _vm.mentions.length,
    'has-user-mention': _vm.hasUserMention,
    'is-action': _vm.isAction,
    'is-ban-hovered': _vm.isBanHovered,
    'is-buttons-n-bows': _vm.dataObject.userstate.username === 'buttons_n_bows',
    'is-child': _vm.child,
    'is-filtered': _vm.isFiltered,
    'is-foreign': _vm.isForeignChannel,
    'is-role-broadcaster': _vm.isRoleBroadcaster,
    'is-role-mod': _vm.isRoleMod,
    'is-role-sub': _vm.isRoleSub,
    'is-role-vip': _vm.isRoleVIP,
    'is-selected': _vm.dataObject.selected,
    'is-striped': _vm.isStriped,
    'is-suspicious': _vm.isSuspicious,
    'is-timed-out': _vm.isTimedOut,
    'is-unread': _vm.dataObject.unread,
  },on:{"dblclick":function($event){$event.preventDefault();return _vm.onTimeoutUser(30)},"mouseover":function($event){return _vm.onHover(true)},"mouseleave":function($event){return _vm.onHover(false)},"mousedown":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"chat-message"},[(!_vm.child)?_c('button',{staticClass:"btn btn-primary btn-remove",attrs:{"title":"Ban user"},on:{"click":function($event){return _vm.onBanUser()},"mouseover":function($event){_vm.isBanHovered = true},"mouseleave":function($event){_vm.isBanHovered = false}}},[_c('i',{staticClass:"fa fa-ban"})]):_vm._e(),_c('ChatMessagesItemAvatar',{attrs:{"path":_vm.avatar,"is-timed-out":_vm.isTimedOut,"suspicious":_vm.isSuspicious,"user-id":_vm.userId,"title":_vm.avatarHoverInfo}}),_c('div',{staticClass:"chat-message-body"},[(_vm.isForeignChannel && !_vm.child)?_c('small',{staticClass:"foreign-channel-label text-muted"},[_c('i',{staticClass:"fa fa-twitch"}),_vm._v("  "+_vm._s(_vm.dataObject.channel)+" ")]):_vm._e(),(!_vm.child)?_c('h4',{staticClass:"chat-message-header mb-1",style:(_vm.isAdminScreen && !_vm.dataObject.isForeignChannel && { color: _vm.dataObject.userstate.color })},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.displayName)+" ")]),(_vm.userBadges)?_c('ChatMessagesItemBadges',{attrs:{"channel":_vm.dataObject.channel,"user-badges":_vm.userBadges,"user-level":_vm.userLevel}}):_vm._e()],1):_vm._e(),_c('ChatMessagesItemBody',{attrs:{"data-object":_vm.dataObject,"hovered":_vm.isHovered}}),(_vm.isFiltered)?_c('small',{staticClass:"chat-message-timestamp"},[_c('time',[_vm._v(_vm._s(_vm.createdAt))])]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }