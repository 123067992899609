<template>
  <div class="admin-panel-chat-parent">

    <!-- CHAT STATUS -->
    <AdminPanelChatStatus @on-chatbot-connected="isChatbotConnected = $event" />

    <!-- CHAT INPUT -->
    <div class="chat-textarea animated fadeIn faster">

      <textarea
        :disabled="!isChatbotConnected"
        :placeholder="isChatbotConnected ? 'Send bot message' : 'Messaging unavailable'"
        :style="{ opacity: isChatbotConnected ? 1 : 0.5 }"
        maxlength="500"
        rows="1"
        resize="false"
        v-model="chatMessage"
        @keydown.enter="handleSubmit"
      >
        </textarea>

    </div>

    <!-- CHAT MESSAGES -->
    <ChatMessages
      :chat-guide="true"
      :top-to-bottom="false"
    />

  </div>
</template>

<script>
  import axios from "axios";
  import AdminPanelChatStatus from "./AdminPanelChatStatus.vue";
  import ChatMessages from "@/components/MitchOS/Programs/Chat/ChatMessages.vue";
  import tmiConfig from "../../../../../../../config/tmi";

  export default {
    components: {
      AdminPanelChatStatus,
      ChatMessages,
    },
    data() {
      return {
        isChatbotConnected: null,
        chatMessage: "",
      };
    },
    methods: {
      handleSubmit(e) {
        // https://stackoverflow.com/questions/6014702/how-do-i-detect-shiftenter-and-generate-a-new-line-in-textarea
        if (!e.shiftKey) {
          e.preventDefault();
          this.$log(this.chatMessage);
          this.postMessage(this.chatMessage);
          this.chatMessage = "";
        }
      },
      postMessage(message = "") {
        try {
          axios({
            url: tmiConfig.endpoints.sayMessage,
            method: "POST",
            withCredentials: true,
            headers: {
              "content-type": "application/json",
            },
            data: {
              message,
            },
          });
        } catch (err) {
          console.error(err);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-chat-parent {
    display: flex;
    flex-direction: column;
    position: absolute; // [LOCKED]
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px dotted aqua;
  }

  .chat-textarea {
    margin: rem-calc(0 10 10 10);

    textarea {
      width: 100%;
      min-height: 34px;
      max-height: 200px;
      padding: 4px 10px;
      // border: 0;
      border-color: rgba(black, 0.3);
      border-radius: 5px;
      background-color: rgba(black, 0.3);
      color: white;
      outline: 0;
    }
  }
</style>
