<template>
  <div class="admin-panel-console-parent">
    <!-- <div class="toolbar"></div> -->

    <!-- CONSOLE LOGS -->
    <div class="console-logs">
      <!-- SCROLLER -->
      <div class="console-logs-text has-scrollbars">
        <!-- LOG ITEM -->
        <div
          v-for="log in serverLogsReversed"
          :key="log.id"
          :style="log.type === 'error' && { color: '#d75959' }"
          :title="log.location"
        >
          <time :datetime="log.createdAt" :title="formatDate(log.createdAt)">
            {{ formatTime(log.createdAt) }}
          </time>

          <span :style="{ opacity: 0.4 }">
            [{{ log.type }}]
            <span v-if="log.data" class="data-btn" @click="handleLogToConsole(log)">[data]</span>
          </span>
          {{ log.message }}

          <!-- [<span>{{ log.type }}</span> -
          <time :title="log.createdAt.toDate()" :datetime="log.createdAt">{{
            formatTime(log.createdAt)
          }}</time
          >] {{ log.message }} -->

          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import getCurrentWeekId from "@/utils/getCurrentWeekId.util";

  export default {
    data() {
      return {
        consoleMessage: "",
      };
    },
    computed: {
      ...mapGetters({
        serverLogs: "global/getServerLogs",
      }),
      serverLogsReversed() {
        return this.serverLogs?.logs?.slice().reverse();
      },
    },
    created() {
      const docId = getCurrentWeekId();
      this.$store.dispatch("global/bindServerLogs", docId);
    },
    methods: {
      formatTime(date) {
        return moment(date).format("hh:mm:ss A");
      },
      formatDate(date) {
        return moment(date).format("DD-MM-YY");
      },
      handleLogToConsole(log) {
        console.log(this.formatTime(log.createdAt), log.message, JSON.parse(log.data));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-console-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #18181b;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .admin-panel-console-parent,
  .admin-panel-console-parent * {
    pointer-events: auto;
  }

  // .toolbar {
  //   position: relative;
  //   top: -1px;
  //   width: 100%;
  //   height: rem-calc(6);
  //   background: #18181b; //#28282f;
  // }

  .console-logs {
    position: relative;
    height: 100%;
    // border: 2px solid red;
  }

  .console-logs .has-scrollbars {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .console-logs-text,
  .console-textarea textarea,
  .console-textarea .fa-angle-right {
    padding: rem-calc(15 15 0 15);
    font-family: "Courier New", Courier, monospace;
    font-size: rem-calc(11);
    font-weight: 600;
    color: #0df293;
  }

  .data-btn {
    display: inline-block;
    border: 1px solid #50b5e1;
    color: #50b5e1;
    border-radius: 2px;
    background: rgba(#50b5e1, 0.3);

    &:hover {
      opacity: 0.5;
    }
  }
</style>
