<script>
  // import { mapGetters } from "vuex";
  import { Splitpanes, Pane } from "splitpanes";
  import AdminFooter from "./AdminFooter/AdminFooter.vue";
  import AdminHeader from "./AdminHeader/AdminHeader.vue";
  import AdminNav from "./AdminNav.vue";
  import AdminPanelsChat from "./AdminPanels/AdminPanelsChat.vue";
  import AdminPanelsConsole from "./AdminPanels/AdminPanelsConsole.vue";
  import AdminPanelMonitor from "./AdminPanels/Default/AdminPanelMonitor/AdminPanelMonitor.vue";
  import AdminPanelsPlaylists from "./AdminPanels/AdminPanelsPlaylists.vue";
  import AdminPanelsQuickTasks from "./AdminPanels/AdminPanelsQuickTasks.vue";
  import AdminPanelsSequences from "./AdminPanels/AdminPanelsSequences.vue";

  export default {
    components: {
      AdminFooter,
      AdminHeader,
      AdminNav,
      AdminPanelsChat,
      AdminPanelsConsole,
      AdminPanelsQuickTasks,
      AdminPanelMonitor,
      AdminPanelsPlaylists,
      AdminPanelsSequences,
      Pane,
      Splitpanes,
    },
    data() {
      return {
        testSize: 80,
        selectedNavItem: null,
        activeFooterItems: null,
      };
    },
    computed: {
      dynamicClasses() {
        const item = this.selectedNavItem;
        const objs = this.activeFooterItems;
        return {
          "is-nav-dashboard": item?.name === "Dash",
          "is-nav-game": item?.name === "Game",
          "is-nav-toggled-off": item?.isToggled === false,
          "is-admin-resized": objs?.find((o) => o.name === "Resize" && o.isActive),
          "is-admin-lit": objs?.find((o) => o.name === "Light" && o.isActive),
        };
      },
    },
    // computed: {
    //   ...mapGetters({
    //     getActiveTask: "tasks/getActiveTask",
    //   }),
    // },
    created() {
      this.$store.dispatch("twitch/fetchTwitchData");
    },
    methods: {},
  };
</script>

<template>
  <div
    class="admin-parent"
    :class="dynamicClasses"
  >
    <!-- ADMIN LAYOUT -->
    <div class="admin-layout">
      <!-- LAYOUT HEADER -->
      <header class="admin-layout-header">
        <AdminHeader />
      </header>

      <!-- LAYOUT BODY -->
      <div class="admin-layout-body">
        <!-- LAYOUT BODY LEFT NAV -->
        <div class="admin-layout-body-left-nav">
          <AdminNav @on-selected="selectedNavItem = $event" />
        </div>

        <!-- LAYOUT BODY LEFT SIDEBAR -->
        <div class="admin-layout-body-left-sidebar">
          <AdminPanelsPlaylists />
        </div>

        <!-- LAYOUT CENTER -->
        <div class="admin-layout-body-center">
          <Splitpanes horizontal="horizontal">
            <Pane :style="{ maxHeight: testSize, minHeight: '34px' }">
              <AdminPanelMonitor />
            </Pane>

            <Pane :style="{ maxHeight: testSize }">
              <AdminPanelsSequences class="is-hoisted-up" />
            </Pane>
            <Pane>
              <AdminPanelsQuickTasks class="is-hoisted-up" />
            </Pane>
            <Pane>
              <AdminPanelsConsole class="is-hoisted-up" />
            </Pane>
          </Splitpanes>
        </div>

        <!-- LAYOUT BODY RIGHT SIDEBAR -->
        <div class="admin-layout-body-right-sidebar">
          <AdminPanelsChat />
        </div>
      </div>

      <!-- LAYOUT FOOTER -->
      <footer class="admin-layout-footer">
        <AdminFooter @on-selected="activeFooterItems = $event" />
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  //-----------------------------------------------------------------
  // PARENT
  //-----------------------------------------------------------------

  .admin-parent {
    height: 100%;
    // transition: background-color 300ms ease;

    // border: 2px dashed lawngreen;
  }

  .admin-parent.is-admin-lit {
    background-color: lighten(yellow, 45%);
    // background: linear-gradient(
    //   90deg,
    //   lighten(yellow, 45%) 50%,
    //   lighten(yellow, 45%) 50%,
    //   rgba(#28282f, 0.5) 100%
    // );
  }

  .admin-parent ::v-deep * {
    cursor: default !important;
  }

  //-----------------------------------------------------------------
  // LAYOUT
  //-----------------------------------------------------------------

  .admin-layout {
    position: relative; // [LOCKED]
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: rem-calc(1650);
    // max-width: rem-calc(2400);
    // max-width: rem-calc(2000);

    height: 100%;
    box-shadow: 0px 0px 0px 1px rgba(black, 0.4);
    margin: 0 auto;
    overflow: hidden;
    // border: 2px dashed lawngreen;
  }

  .is-nav-game .admin-layout {
    max-width: rem-calc(2400);
  }

  .is-admin-resized .admin-layout {
    max-width: none;
  }

  //-----------------------------------------------------------------
  // HEADER
  //-----------------------------------------------------------------

  .admin-layout-header {
    height: rem-calc(65);
    min-height: rem-calc(65);
    background-color: #111;
  }

  //-----------------------------------------------------------------
  // BODY
  //-----------------------------------------------------------------

  .admin-layout-body {
    display: flex;
    flex-grow: 1; // [LOCKED]
    position: relative; // [LOCKED]
    width: 100%; // [LOCKED]
    // border: 2px solid red;
    background-color: #212126;
  }

  //-----------------------------------------------------------------
  // NAV
  //-----------------------------------------------------------------

  .admin-layout-body-left-nav {
    width: rem-calc(60);
    min-width: rem-calc(60);
    z-index: 20;
  }

  .admin-layout-body-left-nav:hover + .admin-layout-body-left-sidebar {
    transform: translateX(0);
  }

  //-----------------------------------------------------------------
  // SIDEBAR
  //-----------------------------------------------------------------

  .admin-layout-body-left-sidebar,
  .admin-layout-body-right-sidebar {
    width: rem-calc(400);
    min-width: rem-calc(400);
    background: #28282f;
    // border: 1px dashed yellow;
    will-change: transform;
  }

  .admin-layout-body-left-sidebar {
    border-right: 1px solid rgba(black, 0.5);
  }

  .admin-layout-body-right-sidebar {
    border-left: 1px solid rgba(black, 0.5);
  }

  //==============================================
  // IS NAV GAME
  //==============================================

  .is-nav-game .admin-layout-body-left-sidebar {
    position: absolute;
    left: rem-calc(60);
    height: 100%;
    background: rgba(#28282f, 0.8);
    backdrop-filter: blur(30px);
    overflow: hidden;
    transform: translateX(calc(-100% - #{rem-calc(60)}));
    transition: transform 200ms ease;
    z-index: 10;
  }

  .admin-layout-body-left-sidebar:hover {
    transform: translateX(0);
  }

  .is-nav-game .admin-layout-body-right-sidebarX {
    position: absolute;
    // top: rem-calc(200);
    // right: -400px;
    right: 0;
    // left: calc((50% + #{rem-calc(60/2)}) - #{rem-calc(400/2)});
    // right: 0;
    // height: calc(100% - #{rem-calc(805)});
    height: 100%;
    background: rgba(#28282f, 0.3);
    // background: none;
    // backdrop-filter: blur(1px);
    border: 0;
    overflow: hidden;
    // opacity: 0.7;
    // transform: translateX(calc(-100% - #{rem-calc(60)}));
    // transition: transform 200ms ease;
    // z-index: 10;

    ::v-deep .chat-message-body {
      // opacity: 0.3;
      // color: rgba(white, 0.2);
      // text-shadow: 0 2px 3px rgba(black, 0.3);
    }

    ::v-deep .chat-messages-guide {
      // border: 0;
      // border-color: rgba(white, 0.1);
    }
  }

  //-----------------------------------------------------------------
  // CENTER
  //-----------------------------------------------------------------

  .admin-layout-body-center {
    width: 100%; // [LOCKED]
    overflow: hidden; // [LOCKED]
    // border: 2px solid aqua;
  }

  //-----------------------------------------------------------------
  // SPLIT PANES
  // https://codepen.io/liquidvisual/pen/XWaEjLR
  //-----------------------------------------------------------------

  .splitpanes {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ::v-deep .splitpanes--horizontal > .splitpanes__splitter {
    min-height: 35px; // 34
    cursor: ns-resize !important;
    background: rgba(black, 0.5);
  }

  .is-hoisted-up {
    position: relative;
    top: -34px;
    height: calc(100% + 34px);
  }

  //-----------------------------------------------------------------
  // FOOTER
  //-----------------------------------------------------------------

  .admin-layout-footer {
    width: 100%;
    height: rem-calc(40);
    min-height: rem-calc(40);
    border-top: 1px solid rgba(white, 0.1);
    box-shadow: 0px -1px 0px rgba(black, 0.5);
    background-color: #28282f;
    z-index: 1;
    // opacity: 0.5;
  }
</style>
