<template>
  <time
    class="system-clock"
    v-text="dateString"
    :datetime="Date.now()"
  >
  </time>
</template>

<script>
  // https://jsfiddle.net/m7e2xcf0/1/
  import moment from "moment";

  export default {
    data() {
      return {
        animationFrame: null,
        dateTime: Date.now(),
      };
    },
    created() {
      this.updateDateTime();
    },
    beforeDestroy() {
      cancelAnimationFrame(this.animationFrame);
    },
    computed: {
      dateString() {
        return moment(this.dateTime).format("ddd h:mm a");
      },
    },
    methods: {
      updateDateTime() {
        this.dateTime = new Date();
        this.animationFrame = requestAnimationFrame(this.updateDateTime);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .system-clock {
    font-size: rem-calc(13.5);
    font-weight: 600;
    color: rgba(white, 0.5);
  }
</style>
