<template>
  <div
    class="admin-panel-console-textarea"
    :class="{ 'is-background-transparent': isBackgroundTransparent }"
  >
    <!-- ICON -->
    <i class="fa fa-angle-right"></i>

    <!-- TEXTAREA -->
    <textarea
      autofocus
      ref="console-textarea"
      class="has-scrollbars"
      maxlength="500"
      placeholder=""
      resize="false"
      rows="1"
      v-model="consoleMessage"
      @keydown.enter="handleEnter"
    >
    </textarea>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import getCurrentWeekId from "@/utils/getCurrentWeekId.util";
  import heroku from "@/config/heroku";

  export default {
    props: {
      isBackgroundTransparent: {
        type: Boolean,
        default: false,
      },
      isFocused: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        consoleMessage: "", // '!rq https://www.youtube.com/watch?v=mgEixhE3Oms'
      };
    },
    computed: {
      ...mapGetters({
        tasksLibrary: "tasks/getTasksLibrary",
      }),
      commands() {
        return this.tasksLibrary.filter((item) => item.commands.length);
      },
    },
    watch: {
      isFocused(newVal) {
        if (newVal) {
          this.focusTextarea();
        }
      },
    },
    mounted() {
      const target = this.$refs["console-textarea"];

      target.addEventListener("keydown", (event) => {
        if (event.metaKey && event.code === "KeyK") {
          const id = getCurrentWeekId();
          this.$store.dispatch("global/removeLogs", id);
        }
      });
    },
    methods: {
      focusTextarea() {
        this.$refs["console-textarea"].focus();
      },
      async handleEnter(e) {
        e.preventDefault();
        // https://forum.vuejs.org/t/how-to-prevent-enter-event-when-ctrl-enter-is-triggered/79518/2
        // if (e.ctrlKey) return console.log("New line", e);

        this.$log({ message: e.target.value });

        await this.handleConsoleCommand(this.consoleMessage);

        // this.matchCommandX(); // duplicate of code from chatbot
        return false;
      },

      matchCommandX() {
        const userCmd = this.consoleMessage.split(" ")[0];

        // Just replicated the logic from chatbot
        const commandObj = {
          name: userCmd,
          user: {
            username: "System",
            roles: {
              broadcaster: true,
              administrator: true,
              moderator: true,
              subscriber: true,
              vip: true,
            },
          },
        };
        // const arg = this.consoleMessage.split(' ')[1];
        const arg =
          this.consoleMessage.indexOf(" ") !== -1
            ? this.consoleMessage.slice(this.consoleMessage.indexOf(" ")).trim()
            : "";

        // THIS IS REPEATED IN CHATBOT.jS - MERGE SOMEHOW
        const task = this.tasksLibrary
          .filter((item) => item.commands.length)
          .find((task) =>
            task.commands.some((item) => {
              // For commands with a role suffix. Eg ['!m:administrator', '!a:vip']
              const csplit = item.toLowerCase().split(":");
              const command = csplit[0];
              const role = csplit[1];
              const con =
                command === (commandObj.name && commandObj.name.toLowerCase()) &&
                (!role ||
                  (role &&
                    (commandObj.user.roles[role] ||
                      commandObj.user.roles.broadcaster ||
                      commandObj.user.roles.administrator ||
                      commandObj.user.roles.moderator)));
              return con;
            })
          );

        commandObj.arg = arg;

        // AUTHORIZE IT
        if (task) this.authorizeCommand(task, commandObj);

        // Clear console.
        this.consoleMessage = "";
      },

      //************************************************************
      // 05. AUTHORIZE COMMAND (from chatbot)
      //************************************************************

      authorizeCommand(taskObj, commandObj) {
        // PREPARE TASKS
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: taskObj.uuid,
            author: commandObj.user.username,
            data: {
              arg: commandObj.arg, // Remember: arg is just a dynamic string. Separate spaces with split.
            },
            // silent: false,
          },
        ]);
      },

      //------------------------------------------------------------
      // HANDLE CONSOLE COMMAND
      //------------------------------------------------------------

      async handleConsoleCommand(message = "") {
        this.consoleMessage = "";

        try {
          const res = await axios({
            url: heroku.HEROKU_API.handleConsoleCommand,
            method: "POST",
            withCredentials: true,
            headers: {
              "content-type": "application/json",
            },
            data: {
              message,
            },
          });

          console.log(res);
        } catch (err) {
          console.error("Oops! error with handleConsoleCommand:", err);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-console-textarea,
  .admin-panel-console-textarea * {
    pointer-events: auto;
  }

  .admin-panel-console-textarea textarea,
  .admin-panel-console-textarea .fa-angle-right {
    padding: rem-calc(15 15 0 15);
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    font-size: rem-calc(13);
    color: #0df293;
  }

  .admin-panel-console-textarea {
    display: flex; // [LOCKED]

    .fa-angle-right {
      position: relative;
      top: -1px;
      display: flex;
      padding: rem-calc(0 0 0 15);
      font-family: "FontAwesome";
      font-size: rem-calc(18);
      align-items: center;
    }

    textarea {
      width: 100%;
      padding: rem-calc(10 15);
      padding-left: rem-calc(8);
      border: 0;
      // border: 1px solid red;
      background-color: transparent;
      font-size: rem-calc(11);
      white-space: nowrap;
      outline: 0;
      resize: none;
    }
  }

  .admin-panel-console-textarea.is-background-transparent {
    background-color: #18181b;
  }

  // textarea::placeholder {
  //   color: #0df293;
  // }
</style>
