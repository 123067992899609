<template>
  <div class="chat-messages-item-badges-parent">

    <!-- USER LEVEL BADGE -->
    <span
      v-if="userLevel"
      class="badge badge-user-level mr-1"
    >
      {{ userLevel }}
    </span>

    <!-- TWITCH BADGES -->
    <img
      v-for="item in badgeSet"
      class="badge mr-1"
      width="18"
      :alt="item.title"
      :key="item.url"
      :src="item.url"
      :title="item.desc"
    >
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      channel: {
        type: String,
        required: true,
      },
      userBadges: {
        type: Object,
        required: true,
      },
      userLevel: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        badgeSet: [],
      };
    },
    computed: {
      ...mapGetters({
        twitchBadgeCache: "twitch/getChatBadges",
      }),
    },
    created() {
      this.setBadges();
    },
    methods: {
      /**
       * SET BADGES
       * https://discuss.dev.twitch.tv/t/how-to-get-emotes-badges-object/18916
       */
      setBadges() {
        const chan = this.channel.replace(/^#/, ""); // hashless

        const globalAndChannelBadges = {
          ...this.twitchBadgeCache.global,
          ...(this.twitchBadgeCache[chan] || {}),
        };

        this.badgeSet = Object.keys(this.userBadges).reduce((acc, item) => {
          const version = this.userBadges[item];
          const badgeSet = globalAndChannelBadges[item];

          if (badgeSet && version in badgeSet.versions) {
            acc.push({
              url: badgeSet.versions[version].image_url_2x, // url_1x, 2x, 4x
              desc: item,
              item,
            });
          }
          return acc;
        }, []);
        // console.log("badgeArr", this.badgeSet);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chat-messages-item-badges-parent {
    display: inline;
    // background: aqua;
    // display: inline-flex;
    // flex-wrap: no-wrap;
    // justify-content: flex-start;
    // align-content: center;
  }

  .badge {
    position: relative;
    top: -2px;
    // filter: brightness(60%);
    // filter: grayscale(100%) invert(1);
    // opacity: 0.5;
  }

  .badge-user-level {
    top: 2px;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 100%; //2px;
    // border: 1px solid darken(white, 40%);
    box-shadow: 0 0 1px 1px darken(white, 20%);
    background: rgba(black, 0.3);
    font-family: "Front Page Neue";
    font-size: rem-calc(13);
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: darken(white, 20%);
    overflow: hidden;
  }

  .has-mentions:not(.has-user-mention) .badge,
  .is-foreign .badge {
    filter: brightness(60%) grayscale(100%);
    // filter: grayscale(100%) invert(1);
    opacity: 0.5;
  }
</style>
