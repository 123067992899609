export default [
  {
    icon: "fa fa-television",
    name: "TV-Clear",
    position: [0, 0],
    title: "Makes TV transparent",
    uuid: "3f828ebf-b109-4074-bcc0-23086a3872cd",
  },
  {
    icon: "fa fa-television",
    name: "TV-LOGO",
    position: [0, 75],
    title: "-",
    uuid: "cdd86256-a166-4b33-a66a-70f01bdcfcfa",
  },
  {
    icon: "fa fa-music",
    name: "Bravo",
    title: "-",
    uuid: "f8718bcf-3fa9-49fa-a0dc-da44d3ab87a1",
    position: [75, 0],
  },
  {
    icon: "fa fa-music",
    name: "Cheer",
    title: "-",
    uuid: "6b9b4120-8edd-485f-9dad-bb1324169664",
    position: [75, 75],
  },
  {
    icon: "fa fa-music",
    name: "WE-GOT ONE",
    title: "-",
    uuid: "d427b71c-3097-4d96-9a05-bc6e1d961ca8",
    position: [75, 150],
  },
  {
    icon: "fa fa-music",
    name: "Ka-ching",
    title: "-",
    uuid: "ae6e7c29-9fd6-41a8-8425-c0d483348d3e",
    position: [75, 225],
  },

  {
    icon: "fa fa-comment-o",
    name: "Chat Opaque",
    position: [75, 450],
    title: "",
    uuid: "99219979-ff89-48a8-b026-71157c858fe4",
  },
  {
    icon: "",
    name: "Lobby Show Video",
    position: [75, 525],
    title: "",
    uuid: "c93cb734-7a6e-447b-809f-52a3c937ee12",
  },
  {
    icon: "fa fa-youtube-play",
    name: "YouTube Blur",
    position: [75, 600],
    title: "",
    uuid: "24910df5-4fa2-4de3-b22d-c6ed7d9b12dc",
  },
  {
    icon: "fa fa-youtube-play",
    name: "YouTube Full",
    position: [75, 675],
    title: "",
    uuid: "541d6fd1-327f-485f-b8bd-757504aef24c",
  },

  {
    data: { arg: "loficardboardcowboy" },
    icon: "fa fa-twitch",
    name: "Twitch 24/7",
    position: [150, 675],
    title: "",
    uuid: "1db30a61-bea4-4613-a574-c52ba009bb64",
  },
  {
    data: { arg: "loficardboardcowboy" },
    icon: "fa fa-twitch",
    name: "Twitch Hide",
    position: [150, 600],
    title: "",
    uuid: "e5fa3c11-b8bc-4164-8ffd-d989c20c5a05",
  },

  {
    icon: "fa fa-music",
    name: "Phone",
    title: "-",
    uuid: "cfe4bfb5-7406-4c43-8ce2-52992f8844ca",
    position: [150, 0],
  },
  {
    icon: "fa fa-music",
    name: "Moto",
    title: "-",
    uuid: "99c002f6-3f43-46a1-8b22-bf967a57120a",
    position: [150, 75],
  },
  {
    icon: "fa fa-music",
    name: "Nokia",
    title: "-",
    uuid: "d883090c-674b-4e63-9d69-ae83db93ae3a",
    position: [150, 150],
  },
  {
    icon: "fa fa-step-forward",
    name: "Media Poster",
    position: [0, 450],
    title: "Skip",
    uuid: "7113de8a-0360-4368-8eac-021174330922",
  },
  {
    icon: "fa fa-pause",
    name: "Media Poster",
    position: [0, 525],
    title: "Pause",
    uuid: "c388392f-1931-4481-8c7e-99a0755b642a",
  },
  {
    icon: "fa fa-eye-slash",
    name: "Media Poster",
    position: [0, 600],
    title: "Hide",
    uuid: "156ccfdd-d055-4b7b-b695-c917641ddfde",
  },
  {
    icon: "fa fa-close",
    name: "Media Poster",
    position: [0, 675],
    title: "Clear All",
    uuid: "fe598845-ffe2-49a4-a3d6-44a0d0ffd646",
  },
];
