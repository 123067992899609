<template>
  <div
    class="admin-panels-console-parent"
    @mouseover="focusTextarea()"
  >
    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <AdminPanelHeaderTabs
        :tabs="tabs"
        :is-darker="true"
        @onSelect="onSelect($event)"
      />
    </AdminPanelHeader>

    <!-- PANEL BODY -->
    <div class="admin-panel-body">
      <AdminPanelSequences
        v-show="selectedTabName === 'Sequences'"
        :is-primary-connect="true"
      />
      <AdminPanelOBS v-show="selectedTabName === 'OBS'" />
      <AdminPanelLights v-show="selectedTabName === 'Lights'" />
    </div>
  </div>
</template>

<script>
  import ClickOutside from "vue-click-outside";
  import AdminPanelHeader from "../AdminUI/AdminPanelHeader.vue";
  import AdminPanelHeaderTabs from "../AdminUI/AdminPanelHeaderTabs.vue";
  import AdminPanelOBS from "./Default/AdminPanelOBS/AdminPanelOBS.vue";
  import AdminPanelLights from "./Default/AdminPanelLights.vue";
  import AdminPanelSequences from "./Default/AdminPanelSequences/AdminPanelSequences.vue";

  export default {
    components: {
      AdminPanelHeader,
      AdminPanelHeaderTabs,
      AdminPanelLights,
      AdminPanelOBS,
      AdminPanelSequences,
    },
    directives: {
      ClickOutside,
    },
    data() {
      return {
        isTextareaFocused: true,
        selectedTabName: "Sequences",
        tabs: [
          {
            name: "Sequences",
            icon: "",
            isActive: true,
            classes: "",
          },
          {
            name: "OBS",
            icon: "",
            isActive: false,
            classes: "",
          },
          {
            name: "Lights",
            icon: "",
            isActive: false,
            classes: "has-no-right-border",
          },
        ],
      };
    },
    methods: {
      focusTextarea(bool) {
        this.isTextareaFocused = bool;
      },
      onSelect(tab) {
        this.tabs.forEach((item) => (item.isActive = false));
        tab.isActive = true;
        this.selectedTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panels-console-parent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    // border: 1px solid yellow;
    overflow: hidden;
    pointer-events: none;
  }

  .admin-panel-body {
    position: relative;
    height: 100%;
    pointer-events: auto;
  }

  .admin-panel-footer {
    pointer-events: auto;
  }
</style>
