<template>
  <div class="grid-btn-ghosts-underlay">
    <div
      v-for="i in 200"
      :key="i"
      :ref="'gridBtnRef-' + i"
      class="grid-btn-ghost"
      @click="handleClick"
    >
      <span class="grid-btn-number">{{ i }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      handleClick(event) {
        const top = event.target.offsetTop;
        const left = event.target.offsetLeft;
        console.log("top:", top, "left:", left);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .grid-btn-ghosts-underlay {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    width: rem-calc(760);
    height: 100%;
    pointer-events: none;
    // border: 1px dashed lime;
    overflow: hidden;
    z-index: -1;
  }

  .grid-btn-ghost {
    position: relative;
    width: rem-calc(65);
    height: rem-calc(65);
    min-width: rem-calc(65);
    min-height: rem-calc(65);
    border-radius: rem-calc(10);
    background-color: rgba(black, 0.08);
    overflow: hidden;
  }

  .grid-btn-number {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: rem-calc(12);
    color: rgba(white, 0.2);
    user-select: none;
    opacity: 0;
  }

  .grid-btn-ghost:hover .grid-btn-number {
    // opacity: 1;
  }
</style>
