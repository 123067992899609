<template>
  <div class="debug-window-titlebar-parent">
    <!-- TEXT -->
    <span class="text">
      {{ title }}
    </span>

    <!-- TITLEBAR BUTTONS -->
    <!-- <div class="debug-window-titlebar-btns"> -->

    <!-- CLOSE BTN -->
    <!-- <button
				class="debug-window-titlebar-btn btn-close"
				@click.stop="$emit('close-window')"
			>
				&times;
			</button>
		</div> -->
  </div>
</template>

<script>
  export default {
    props: {
      modes: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        index: 1,
      };
    },
    methods: {
      cycle() {
        // https://stackoverflow.com/questions/10451893/javascript-create-simple-dynamic-array
        let arr = [];
        this.modes.forEach((item, index) => arr.push(index)); // let arr = [0, 1, 2];
        const idx = arr[this.index++ % arr.length];
        this.$emit("update-index", idx);
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------
  // WINDOW HEADER
  //--------------------------------------------------------

  .debug-window-titlebar-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(30);
    background: rgba(black, 0.4);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ccc;
  }

  .debug-window-titlebar-btns {
    position: absolute;
    right: 0;
  }

  .debug-window-titlebar-btn {
    padding: 0 10px;
    border: 0;
    box-shadow: none;
    outline: none;
    background: none;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    color: #ccc;
    cursor: default;

    .fa {
      position: relative;
      top: -2px;
      font-size: rem-calc(17);
    }

    &:hover {
      background: rgba(black, 0.2);
    }
  }
</style>
