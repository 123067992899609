<template>
  <div
    class="chat-messages-component"
    :class="{
      'has-manual-scroll': !autoScroll,
      'is-bottom-to-top': !topToBottom,
    }"
    v-shortkey.push="['shift']"
    @shortkey="toggleUserMessageFilter"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
  >
    <!-- AUTOSCROLL BTN -->
    <div v-if="!autoScroll" class="chat-messages-autoscroll">
      <!-- @mouseover="restartAutoscroll" -->
      <button class="btn btn-autoscroll" @click="restartAutoscroll">
        <div v-if="chatMessagesUnread.length">
          <i v-if="!topToBottom" class="fa fa-angle-up mr-1"></i>
          <i v-if="topToBottom" class="fa fa-angle-down mr-1"></i>

          New Messages
          <span class="badge">
            {{ chatMessagesUnread.length }}
          </span>
        </div>
        <div v-else><i class="fa fa-pause mr-1"></i> Chat Paused</div>
      </button>
    </div>

    <!-- CHAT GUIDE -->
    <div
      v-if="
        chatGuide &&
          !filterUsernameToggle &&
          (chatMessagesVisible.length || chatMessagesUnread.length)
      "
      class="chat-messages-guide"
    ></div>

    <!-- CHAT MESSAGES SCROLLER -->
    <div
      class="chat-messages-scroller has-scrollbars has-scrollbars-on-hover"
      ref="chat-messages-scroller"
      @scroll="onScroll"
    >
      <!-- CHAT MESSAGES :style="{ visibility: filterUsernameToggle ? 'hidden' : 'visible' }" -->
      <div class="chat-messages" ref="chat-messages">
        <!-- CHAT MESSAGES ITEM -->
        <ChatMessagesItem
          v-for="(item, index) in chatDataOrdered"
          :child="getChildStatus(item, index, chatDataOrdered)"
          :data-object="item"
          :index="counter"
          :key="item.userstate.id"
          :ref="'el-' + item.userstate.id"
          class="chat-messages-item"
          @hover-username="filterUsername = $event"
        />

        <!-- CHAT MESSAGES FILTERED -->
        <div
          v-if="filterUsernameToggle && chatDataFiltered.length"
          class="chat-messages-filtered has-scrollbars has-scrollbars-on-hover"
        >
          <ChatUserProfile v-if="latestUserData" :user-data="latestUserData" />

          <ChatMessagesItem
            v-for="(item, index) in chatDataFiltered"
            :child="getChildStatus(item, index, chatDataFiltered)"
            :data-object="item"
            :index="index"
            :is-filtered="true"
            :key="'filtered-' + item.userstate.id"
            class="chat-messages-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import VueScrollTo from "vue-scrollto";
  import ChatMessagesItem from "./ChatMessagesItem.vue";
  // import twitchConfig from "@/config/twitch.js";
  import ChatUserProfile from "./ChatUserProfile.vue";

  // import PerfectScrollbar from 'vue2-perfect-scrollbar'
  // import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

  export default {
    components: {
      ChatMessagesItem,
      // PerfectScrollbar
      ChatUserProfile,
    },
    props: {
      chatGuide: {
        type: Boolean,
        default: false,
      },
      topToBottom: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        autoScroll: true,
        chatMessagesVisible: [],
        chatMessagesUnread: [],
        counter: 0, // stripe counter

        filterUsername: null,
        filterUsernameToggle: null,

        isHovered: false,

        lastScrollY: null,
        maxMessageCount: 100,
      };
    },
    computed: {
      ...mapGetters({
        broadcaster: "global/getBroadcaster",
        getActiveTask: "tasks/getActiveTask",
      }),
      chatDataOrdered() {
        const messages = this.topToBottom
          ? this.chatMessagesVisible
          : this.chatMessagesVisible.slice().reverse();

        return messages;
      },
      chatDataFiltered() {
        return this.chatDataOrdered.filter(
          (item) => item.userstate.username === this.filterUsername
        );
      },
      chatDiscreetMode() {
        return !!this.getActiveTask("chat", "Discreet Mode");
      },
      latestUserData() {
        return this.chatDataFiltered[0];
      },
    },
    created() {
      this.$store.dispatch("twitch/fetchGlobalChatBadges");
    },
    sockets: {
      /**
       * BAN USER - Removes those messages.
       */
      banUser(data) {
        this.chatMessagesVisible = this.chatMessagesVisible.filter(
          (item) => !(item.channel === data.channel && item.userstate.username === data.username)
        );
        // this.resetAutoscroll();
      },

      /**
       * TWITCH CHANNEL BADGES
       */
      twitchChannelBadges(data) {
        this.$store.dispatch("twitch/setChatBadges", {
          channel: data.channel,
          data: data.data,
        });
      },

      //--------------------------------------------------------
      // CONNECT
      //--------------------------------------------------------

      connect() {
        console.log("Socket connected :::::::::");
      },

      //--------------------------------------------------------
      // CLEAR CHAT
      //--------------------------------------------------------

      clearChat(data) {
        if (data) {
          this.chatMessagesVisible = [];
          this.chatMessagesUnread = [];
        }
      },

      //--------------------------------------------------------
      // NEW CHAT MESSAGE
      //--------------------------------------------------------

      newChatMessage(data) {
        console.log("chatsocket:", data);

        if (this.autoScroll) {
          this.chatMessagesVisible.push(data);
        } else {
          this.chatMessagesUnread.push(data);
        }

        this.counter++;

        // Only keep a maximum amount of messages in memeory for performance.
        if (this.chatMessagesVisible.length > this.maxMessageCount) {
          const excess = this.chatMessagesVisible.length - this.maxMessageCount;

          // console.log(`Deleting ${excess} excess messages.`);
          this.chatMessagesVisible.splice(0, excess);
        }

        // https://stackoverflow.com/questions/40730116/scroll-to-bottom-of-div-with-vue-js
        this.$nextTick(() => {
          if (this.autoScroll) {
            const target = this.$refs["el-" + data.userstate.id][0].$el;
            target.scrollIntoView({ behavior: "smooth" });
            // scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      },

      //--------------------------------------------------------
      // NEW CHAT USER SOUND - Plays generic sound (not user's sound)
      // Should this be done through socket?
      //--------------------------------------------------------

      newChatUserSound(data) {
        // const username = data.username;
        if (!this.chatDiscreetMode) {
          this.$playSound("icq-message", { interrupt: true, volume: 0.4 });
        }
      },

      //--------------------------------------------------------
      // TIMEOUT USER - Obfuscates those messages.
      //--------------------------------------------------------

      timeoutUser(data) {
        this.chatMessagesVisible
          .filter(
            (item) => item.channel === data.channel && item.userstate.username === data.username
          )
          .forEach((item) => (item.isTimedOut = true));
      },
    },
    methods: {
      //--------------------------------------------------------
      // GET CHILD STATUS
      //--------------------------------------------------------

      getChildStatus(target, index, arr) {
        return !!(
          arr[index - 1] && arr[index - 1].userstate.username === target.userstate.username
        );
      },

      //--------------------------------------------------------
      // ON TOGGLE USER MESSAGE FILTER
      // Only works on the admin view for technical reasons.
      //--------------------------------------------------------

      toggleUserMessageFilter() {
        if (this.isHovered && !this.topToBottom) {
          this.filterUsernameToggle = !this.filterUsernameToggle;
        }
      },

      //--------------------------------------------------------
      // ON SCROLL
      //--------------------------------------------------------

      onScroll(e) {
        const scrollContainer = this.$refs["chat-messages-scroller"];
        const scrollY = e.target.scrollTop;

        const scrollingAgainst = this.topToBottom
          ? this.lastScrollY > scrollY
          : this.lastScrollY < scrollY;

        if (scrollingAgainst) {
          this.autoScroll = false;
        }

        this.lastScrollY = scrollY;

        // need logic for reverse
        const buffer = 30;
        const dest = this.topToBottom
          ? scrollY + e.target.offsetHeight >= e.target.scrollHeight - buffer
          : scrollY <= 0;

        if (dest) {
          // move this into function
          this.autoScroll = true;

          // Mark last unread message to create line.
          if (this.chatMessagesUnread[this.chatMessagesUnread.length - 1]) {
            // Reset all unreads from visible.
            this.chatMessagesVisible.forEach((item) => (item.unread = false));
            // Set the last remaining unread.
            this.chatMessagesUnread[0].unread = true;
          }

          // push unreads into visible now we're auto again
          this.chatMessagesVisible = this.chatMessagesVisible.concat(this.chatMessagesUnread);
          this.chatMessagesUnread = [];

          this.$nextTick(() => {
            scrollContainer.scrollTop = (this.topToBottom && scrollContainer.scrollHeight) || 0;
          });
        }
      },

      //--------------------------------------------------------
      // RESET AUTOSCROLL
      //--------------------------------------------------------

      // resetAutoscroll() {
      // 	this.lastScrollY = null;
      // 	this.autoScroll = true;
      // },

      //--------------------------------------------------------
      // RESTART AUTOSCROLL
      //--------------------------------------------------------

      restartAutoscroll() {
        const scrollContainer = this.$refs["chat-messages-scroller"];

        this.$nextTick(() => {
          // scrollContainer.scrollTo(0,0);
          // scrollContainer.scrollTop = this.topToBottom && scrollContainer.scrollHeight || 0;
          VueScrollTo.scrollTo(scrollContainer, 300, {
            cancelable: false,
            container: scrollContainer,
            easing: "ease",
            force: true,
            offset: this.topToBottom ? scrollContainer.scrollHeight : 0,
            x: false,
            y: true,
          });
        });
      },

      //--------------------------------------------------------
      // HANDLE HOVER
      //--------------------------------------------------------

      handleHover(bool) {
        if (bool) {
          this.isHovered = true;
          // console.log(this.latestUserData);
          return;
        }
        this.isHovered = false;
        this.restartAutoscroll();
      },
    },
  };
</script>

<style lang="scss" scoped>
  // https://stackoverflow.com/questions/18614301/keep-overflow-div-scrolled-to-bottom-unless-user-scrolls-up
  .chat-messages-component {
    display: flex;
    height: 100%;
    transform: scale(1);

    overflow: hidden;
  }

  .has-manual-scroll {
    // box-shadow: inset 0 0 3px 2px #ff8733;
  }

  //-----------------------------------------------------------------
  // CHAT GUIDE
  //-----------------------------------------------------------------

  .chat-messages-guide {
    position: absolute;
    width: 100%;
    height: rem-calc(578);
    border: 2px solid $primary;

    pointer-events: none;
    transition: opacity 300ms ease;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + 2px);
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100vh - 578px); // magic number, fix this up
      background: rgba(black, 0.3);
      backdrop-filter: grayscale(0.3);
      z-index: 2;
    }
  }

  .has-manual-scroll .chat-messages-guide {
    opacity: 0;
  }

  //--------------------------------------------------------
  // CHAT MESSAGES SCROLLER
  //--------------------------------------------------------

  .chat-messages-scroller {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px dashed red;
  }

  //--------------------------------------------------------
  // AUTOSCROLL
  //--------------------------------------------------------

  .chat-messages-autoscroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
  }

  .is-bottom-to-top .chat-messages-autoscroll {
    top: 0;
    bottom: auto;
  }

  .btn-autoscroll {
    width: calc(100% - #{rem-calc(16)});
    padding: rem-calc(10 0);
    font-weight: 600;
    background: #9b59b6;
    // background: rgba(black, 0.9);
    // color: #5ab672;
    color: white;
    // -webkit-backdrop-filter: blur(5px);
    // backdrop-filter: blur(5px);

    &:hover {
      opacity: 0.9;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }

  //--------------------------------------------------------
  // CHAT MESSAGES FILTERED
  //--------------------------------------------------------

  .chat-messages-filtered {
    // position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px solid #61fd76;
    background: rgba(lighten(#41424d, 5%), 0.9);
    backdrop-filter: blur(3px);
    z-index: 10;
  }

  .chat-messages-filtered .chat-messages-item {
    animation: none;
    max-height: none;
    padding: rem-calc(4 0 4 10); // cheap
  }
</style>
