const host = process.env.VUE_APP_SERVER_ORIGIN;

const HEROKU_API = {
  createPlaylist: `${host}/api/v1/firebase/firestore/playlists/create-playlist`,
  getTaskLibrary: `${host}/api/v1/tasks/getTaskLibrary`,
  handleConsoleCommand: `${host}/api/v1/firebase/handle-console-command`,
  ping: `${host}/ping`,
  runChatbot: `${host}/api/v1/tmi/chatbot`,
  setBroadcast: `${host}/api/v1/admin/set-broadcast`,
};

export default {
  HEROKU_API,
};
