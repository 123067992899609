<template>
  <header class="admin-menubar-parent">

    <!-- DEBUG ICONS -->
    <!-- <DebugMenuIcons /> -->

    <!-- <h4 class="text-white px-4 mb-0">Ghoulish Games</h4> -->

    <!-- <select class="scene-switcher">
            <option>Chamber of Chat</option>
            <option>Ghoulish Games</option>
            <option>Tales from the Couch</option>
            <option>Code Mode</option>
        </select> -->

    <button class="button">{{ viewerCount }} <span class="d-block">Viewers</span></button>
    <button class="button">0 <span class="d-block">Lurkers</span></button>
    <button class="button">{{ followerCount }} <span class="d-block">Followers</span></button>
    <AdminHeaderBroadcastButton class="btn-broadcast" />
    <button class="button">0 <span class="d-block">Subs</span></button>
    <button class="button">$0 <span class="d-block">Donos</span></button>
    <button class="button">0 <span class="d-block">Users</span></button>

    <div class="end-items">
      <button class="avatar-btn ml-auto">
        <img
          :src="userData.profileImageURL"
          alt="Avatar"
        />
        <div class="user-status is-online"></div>
      </button>
    </div>

  </header>
</template>

<script>
  // https://jsfiddle.net/m7e2xcf0/1/
  import { mapGetters } from "vuex";
  import DebugMenuIcons from "@/components/MitchOS/Programs/Debug-v1/DebugMenuIcons.vue";
  import AdminHeaderBroadcastButton from "./AdminHeaderBroadcastButton.vue";

  export default {
    components: {
      AdminHeaderBroadcastButton,
      DebugMenuIcons,
    },
    computed: {
      ...mapGetters({
        viewerCount: "twitch/getViewerCount",
        // subscriberCount: 'twitch/getSubscriberCount',
        followerCount: "twitch/getFollowerCount",
        userData: "user/getUserData",
      }),
    },
  };
</script>

<style lang="scss" scoped>
  //---------------------------------------------------------
  // MENUBAR
  //---------------------------------------------------------

  .admin-menubar-parent {
    position: relative;
    display: flex;
    // border-bottom: 2px solid black;
    align-items: center;
    justify-content: center;
    // height: rem-calc(50);
    height: 100%;
    // background-color: aqua;
    // to account for nav bar - eyeball centering
    padding-left: rem-calc(60);
    // padding-left: rem-calc(60);
    // background: #151519; //#1e1e23; //#18181b; //lighten(black, 5%);
    // background-color: #17181a;
    // background: #17181a;
    // background: #191a1e;
    // background: linear-gradient(to bottom, #232531 0%, #1c202d 100%);
    // background: #1e2125;
    user-select: none;
    // border-bottom: 1px solid black;
    z-index: 3;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0e0e10;
    background: transparent;
    border: 0;
    padding: rem-calc(8 0);
    // padding: rem-calc(8 0);
    min-width: rem-calc(75);
    // background-color: aqua;
    // margin: rem-calc(0 5);
    border-radius: 5px;
    color: white;
    line-height: 1.2;
    font-size: rem-calc(17);
    font-weight: 600;
    // color: $primary;
    color: #e1e1e1;

    .d-block {
      color: #777;
      font-size: rem-calc(12);
      // line-height: 0.5;
    }
  }

  .btn-broadcast {
    min-width: rem-calc(110);
    // padding-left: rem-calc(15);
    // padding-right: rem-calc(15);
    margin: 0;
  }

  .scene-switcher {
    border: 0;
    background: none;
    font-family: "Avenir Next";
    font-size: rem-calc(18);
    font-weight: 600;
    text-align: center;
    color: white;
  }

  .admin-menubar {
    font-size: rem-calc(18);
    font-weight: 600;
    color: lighten(#cccccc, 10%); //darken(white, 5%);
    background: rgba(black, 0.2);
    padding: rem-calc(5 13);
    border-radius: 0 0 5px 5px;
  }

  .end-items {
    position: absolute;
    margin: rem-calc(0 10);
    right: 0;
    // background: red;
  }

  .avatar-btn {
    padding: 0;
    border: 0;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: rem-calc(38);
      border-radius: 100%;
    }

    .user-status {
      position: absolute;
      bottom: -2px;
      right: -1px;
      width: 12px;
      height: 12px;
      background: #adadb8;
      border-radius: 100%;
      border: 2px solid #111111;

      &.is-online {
        background: #00f493;
      }

      &.is-away {
        background: #ffd37a;
      }
    }
  }
</style>