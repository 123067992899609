<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <div class="scrollbars-inner">
        <AdminPanelGridUnderlay />

        <div class="grid-btns">
          <AdminPanelGridButton
            v-for="(task, index) in theTasks"
            :icon="task.icon"
            :isActive="task.canceled !== 0"
            :key="index"
            :name="task.user || task.group"
            :title="task.user || task.group"
            @click="handleClick(task)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import AdminPanelGridButton from "../../../AdminUI/AdminPanelGridButton.vue";
  import AdminPanelGridUnderlay from "../../../AdminUI/AdminPanelGridUnderlay.vue";

  export default {
    components: {
      AdminPanelGridButton,
      AdminPanelGridUnderlay,
    },
    computed: {
      ...mapGetters({
        getActiveTaskSounds: "tasks/getActiveTaskSounds",
        getTasksLibrary: "tasks/getTasksLibrary",
      }),
      theTasks() {
        const allSounds = this.getTasksLibrary.filter((task) => task.type === "sound");

        // Explicitly set `canceled:false` since blind toggles will init with `0` (Firestore restriction).
        // Firestore can't do where != true if init value was 'null' -- has this changed?
        const activeSounds = this.getActiveTaskSounds.map((task) => ({
          ...task,
          canceled: false,
        }));

        const combinedPrograms = [...allSounds, ...activeSounds];

        const uniqueGroups = combinedPrograms.reduce((acc, obj) => {
          if (!acc[obj.data.value] || obj.canceled === false) {
            acc[obj.data.value] = obj;
          }
          return acc;
        }, {});

        return Object.values(uniqueGroups);
      },
    },
    methods: {
      handleClick(task = {}) {
        this.$store.dispatch("tasks/runTasksRemotely", [{ uuid: task.uuid }]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20 20 0 20);
  }

  .has-scrollbars a {
    display: block;
  }

  .scrollbars-inner {
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  .grid-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // border: 1px dashed red;
    z-index: 1;
  }
</style>
