<template>
  <div class="admin-panels-chat-parent">

    <!-- PANEL HEADER -->
    <AdminPanelHeader>
      <AdminPanelHeaderTabs
        :tabs="tabs"
        @onSelect="onSelect($event)"
      />
    </AdminPanelHeader>

    <!-- PANEL BODY -->
    <div class="admin-panel-body">

      <!-- CHAT -->
      <AdminPanelChat />

      <!-- USERS -->
      <keep-alive>
        <AdminPanelUsers v-if="selectedTabName === 'Users'" />
      </keep-alive>

    </div>
  </div>
</template>

<script>
  import AdminPanelChat from "./Default/AdminPanelChat/AdminPanelChat.vue";
  import AdminPanelHeader from "../AdminUI/AdminPanelHeader.vue";
  import AdminPanelHeaderTabs from "../AdminUI/AdminPanelHeaderTabs.vue";
  import AdminPanelUsers from "./Default/AdminPanelUsers.vue";

  export default {
    components: {
      AdminPanelChat,
      AdminPanelHeader,
      AdminPanelHeaderTabs,
      AdminPanelUsers,
    },
    data() {
      return {
        selectedTabName: "Chat",
        tabs: [
          {
            name: "Chat",
            icon: "fa fa-comment",
            isActive: true,
            classes: "",
          },
          // {
          //   name: "Pins",
          //   icon: "fa fa-thumb-tack",
          //   isActive: false,
          //   classes: "",
          // },
          {
            name: "Users",
            icon: "fa fa-users",
            isActive: false,
            classes: "has-no-right-border",
          },
          // {
          //   name: "Voice Chat",
          //   icon: "fa fa-phone",
          //   isActive: false,
          //   classes: "has-no-right-border",
          // },
          // {
          //   name: "Hype",
          //   icon: "fa fa-rocket",
          //   isActive: false,
          //   classes: "has-no-right-border",
          // },
          // {
          //   name: "Minimize",
          //   icon: "fa fa-minus",
          //   isActive: false,
          //   classes: "ml-auto has-no-right-border",
          // },
        ],
      };
    },
    methods: {
      onSelect(tab) {
        this.tabs.forEach((item) => (item.isActive = false));
        tab.isActive = true;
        this.selectedTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panels-chat-parent {
    position: relative; // [LOCKED]
    display: flex;
    flex-direction: column;
    height: 100%;
    // border: 2px solid orange;
  }

  .admin-panel-body {
    position: relative;
    height: 100%;
    // border: 2px dashed red;
  }
</style>
