<template>
  <div class="panel-parent">
    <div class="panel-inner">

      <!-- <h5 class="text-white">Follow Titles</h5> -->

      <!-- CHAT INPUT -->
      <!-- <div class="chat-textarea">
        <textarea
          maxlength="500"
          placeholder=""
          rows="1"
        >
        </textarea>
      </div> -->

      <!-- QUEUE ITEMS -->
      <div class="queue-items has-scrollbars">

        <!-- QUEUE ITEM -->
        <div
          v-for="(title, index) in getFollowTitles"
          :key="index"
          class="queue-item"
        >
          <!-- HEADING -->
          <div class="heading">

            <!-- TITLE -->
            <a
              href=""
              title=""
              @click.prevent=""
            >
              <h4 class="title mb-1">
                {{ title }}
              </h4>
            </a>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  // Come back to later - ended up being more complicated than expected.

  export default {
    data() {
      return {
        tabs: [
          {
            name: "Follow Titles",
            icon: "",
            isActive: true,
            classes: "",
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        getFollowTitles: "global/getFollowTitles2",
      }),
    },
    created() {
      this.$store.dispatch("global/bindFollowTitles");
    },
  };
</script>

<style lang="scss" scoped>
  .panel-parent {
    // pointer-events: none;
    // border: 2px dashed aqua;
  }

  .panel-inner {
    position: relative;
    padding: rem-calc(15);
    // border: 2px dashed orange;
    height: 100%;
  }

  .queue-items {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // border: 2px dashed pink;
  }

  .queue-item {
    // border: 1px solid grey;
    padding: rem-calc(5 0);

    &:nth-child(odd) {
      background: rgba(black, 0.1);
    }
  }

  //--------------------------------------------------------
  // BUTTON TILE
  //--------------------------------------------------------

  .btn-tile {
    background-color: rgba(white, 0.1);
  }

  .btn-tile:hover .btn-tile-bg {
    transform: none;
  }

  //--------------------------------------------------------
  // HEADING
  //--------------------------------------------------------

  .queue-item .heading {
    width: 100%; // [LOCKED]
    padding: rem-calc(0 10 0 15);
    font-weight: 600;
    line-height: 1.2;
  }

  .queue-item .heading h4.title {
    max-height: rem-calc(38);
    overflow: hidden;
    // background: orange;
    font-family: $font-family-sans-serif;
    font-size: rem-calc(15);
    font-weight: 600;
    color: darken(white, 20%);
  }

  .queue-item:not(.is-current):hover .heading h4.title {
    color: white;
  }

  .queue-item .heading .subtitle {
    font-size: rem-calc(12);
    color: grey;
  }

  .queue-item .avatar {
    position: relative;
    top: -1px;
    border-radius: 100%;
    margin-right: rem-calc(2);
    opacity: 0.5;
  }

  // TURN THIS INTO A UI COMPONENT

  .chat-textarea {
    // margin: 10px;

    textarea {
      width: 100%;
      min-height: 34px;
      padding: 4px 10px;
      // border: 0;
      border-color: rgba(black, 0.3);
      // border-radius: 5px;
      background-color: rgba(black, 0.3);
      color: white;
      outline: 0;
    }
  }
</style>
