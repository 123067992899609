/**
 * NOTES:
 *
 * https://stackoverflow.com/questions/35163365/regex-for-mentions
 * /\B@\w+/g
 */

/**
 * PARSE EXTRAS
 *
 * @param {string} str - The message body.
 * @param {array} roles - User's roles.
 * @param {array} tags - User's tags.
 * @returns Final HTML element to render in chat.
 */
export default function parseExtras(str = "", userData = {}) {
  const wrapper = document.createElement("span");
  const words = str.split(" ");
  const firstWord = words[0];

  // Apply effects to individual words.
  words.forEach((word) => {
    // Wrap each word with a span.
    const spanEl = document.createElement("span");
    spanEl.innerText = word;

    // IF @MENTION - class it.
    if (word.match(/(@\w+)/gi)) {
      spanEl.classList.add("mention");
    }

    // IF !COMMAND - class it.
    if (word.match(/(!\w+)/gi)) {
      spanEl.classList.add("command", "animated", "rubberBand", "why-anim-not-working");
    }

    // IF LINK - anchor it.
    if (word.match(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi)) {
      const anchor = document.createElement("a");
      anchor.setAttribute("target", "_blank");
      anchor.setAttribute("title", word);
      anchor.setAttribute("href", word);
      anchor.innerText = "link";
      anchor.src = word;
      spanEl.innerText = "";
      spanEl.appendChild(anchor);
    }

    wrapper.appendChild(spanEl);
    wrapper.appendChild(document.createTextNode(" "));
  });

  /**
   * Apply effects to lines.
   * Remember: ignored or foreign channel users will not have userData
   */

  const isBroadcaster = userData?.roles?.includes("broadcaster");
  const hasMarkdownLicense = userData?.tags?.includes("Markdowner");

  if (isBroadcaster || hasMarkdownLicense) {
    const h1 = parseMarkdown({ firstWord, wrapper, match: "#", el: "h1" });
    const h2 = parseMarkdown({ firstWord, wrapper, match: "##", el: "h2" });
    const h3 = parseMarkdown({ firstWord, wrapper, match: "###", el: "h3" });
    const h4 = parseMarkdown({ firstWord, wrapper, match: "####", el: "h4" });
    const h5 = parseMarkdown({ firstWord, wrapper, match: "#####", el: "h5" });
    const h6 = parseMarkdown({ firstWord, wrapper, match: "######", el: "h6" });

    const marquee = parseMarkdown({ firstWord, wrapper, match: "<<<", el: "marquee" });
    const marqueeAlt = parseMarkdown({ firstWord, wrapper, match: ">>>", el: "marquee" });

    if (h1) return h1;
    if (h2) return h2;
    if (h3) return h3;
    if (h4) return h4;
    if (h5) return h5;
    if (h6) return h6;
    if (marquee || marqueeAlt) return marquee || marqueeAlt;
  }

  return wrapper;
}

// IF ### - wrap - H3 it.
function parseMarkdown({ firstWord = "", wrapper = null, match = "", el = "" }) {
  if (firstWord === match) {
    wrapper.removeChild(wrapper.childNodes[0]);
    const newEl = document.createElement(el);
    newEl.classList.add("mb-0");
    newEl.appendChild(wrapper);
    return newEl;
  }
  return false;
}
