import { render, staticRenderFns } from "./TwitchPlayer.vue?vue&type=template&id=0224a68c&scoped=true&"
import script from "./TwitchPlayer.vue?vue&type=script&lang=js&"
export * from "./TwitchPlayer.vue?vue&type=script&lang=js&"
import style0 from "./TwitchPlayer.vue?vue&type=style&index=0&id=0224a68c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0224a68c",
  null
  
)

export default component.exports