<template>
  <div class="panel-playlists-parent">

    <!-- Could place tabs here -->

    <div class="youtube-playlist-wrapper">
      <YouTubePlaylist
        v-show="selectedTabName === 'Playlists'"
        class="youtube-playlist"
        :options="{ showPlayer: true }"
      />
    </div>

  </div>
</template>

<script>
  import YouTubePlaylist from "@/components/MitchOS/Programs/YouTubePlayer/YouTubePlaylist/YouTubePlaylist.vue";

  export default {
    components: {
      YouTubePlaylist,
    },
    data() {
      return {
        selectedTabName: "Playlists",
      };
    },
  };
</script>

<style lang="scss" scoped>
  .panel-playlists-parent {
    display: flex;
    flex-direction: column;
    // position: relative;
    // height: 100%;
    // border: 2px solid red;
    // background: red;
  }

  .youtube-playlist-wrapper {
    position: relative;
    height: 100%;
    // border: 2px dashed yellow;
  }

  // experimental only
  ::v-deep .btn-tile-bg {
    filter: grayscale(90%);
    opacity: 0.6 !important;
  }

  ::v-deep .subtitle .author {
    color: rgba(white, 0.2) !important;
  }

  ::v-deep .youtube-playlist-item .title {
    color: rgba(white, 0.4) !important;
  }
</style>
