import axios from "axios";
import heroku from "../config/heroku";

/**
 * GET TASK LIBRARY
 */
export default async function getTaskLibrary() {
  try {
    const { data } = await axios({
      url: heroku.HEROKU_API.getTaskLibrary,
      method: "GET",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
      },
    });

    return [data, null];
  } catch (err) {
    return [null, err];
  }
}
